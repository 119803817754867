import { Component, EventEmitter, Output } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-upload-resums',
  templateUrl: './upload-resums.component.html',
  styleUrls: ['./upload-resums.component.scss'],
})
export class UploadResumsComponent {
  selectedFiles: File[] = [];
  jobTitle: string = '';
  @Output() filesSelected = new EventEmitter<File[]>();

  constructor(private notification: NzNotificationService) {}

  // Handle file selection
  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const files = Array.from(input.files);
      const validFiles = files.filter(
        (file) =>
          (file.type === 'application/pdf' ||
            /\.(doc|docx|pages)$/i.test(file.name)) &&
          file.size <= 10 * 1024 * 1024 // Check file size <= 10MB
      );
      const invalidFiles = files.filter(
        (file) =>
          !(
            (
              (file.type === 'application/pdf' ||
                /\.(doc|docx|pages)$/i.test(file.name)) &&
              file.size <= 10 * 1024 * 1024
            ) // Check file size <= 10MB
          )
      );

      if (invalidFiles.length > 0) {
        this.notification.create(
          'error',
          'Error',
          'Only PDF, Word, or Pages files are allowed, and file size must be less than 10MB!'
        );
      }

      if (validFiles.length > 0) {
        const totalFiles = this.selectedFiles.length + validFiles.length;
        if (totalFiles > 50) {
          this.notification.create(
            'error',
            'Error',
            `You can only upload up to 50 files. `
          );
        } else {
          this.selectedFiles.push(...validFiles);
          this.processFiles(validFiles);
          this.filesSelected.emit(this.selectedFiles);
        }
      }
    }
  }

  onFileDropped(files: FileList): void {
    if (files.length > 0) {
      const fileArray = Array.from(files);
      const validFiles = fileArray.filter(
        (file) =>
          (file.type === 'application/pdf' ||
            /\.(doc|docx|pages)$/i.test(file.name)) &&
          file.size <= 10 * 1024 * 1024 // Check file size <= 10MB
      );
      const invalidFiles = fileArray.filter(
        (file) =>
          !(
            (
              (file.type === 'application/pdf' ||
                /\.(doc|docx|pages)$/i.test(file.name)) &&
              file.size <= 10 * 1024 * 1024
            ) // Check file size <= 10MB
          )
      );

      if (invalidFiles.length > 0) {
        this.notification.create(
          'error',
          'Error',
          'Only PDF, Word, or Pages files are allowed, and file size must be less than 10MB!'
        );
      }

      if (validFiles.length > 0) {
        const totalFiles = this.selectedFiles.length + validFiles.length;
        if (totalFiles > 50) {
          this.notification.create(
            'error',
            'Error',
            `You can only upload up to 50 files. `
          );
        } else {
          this.selectedFiles.push(...validFiles);
          this.processFiles(validFiles);
          this.filesSelected.emit(this.selectedFiles);
        }
      }
    }
  }

  processFiles(files: File[]): void {
    files.forEach((file) => console.log('File accepted:', file));
  }

  removeFile(index: number): void {
    if (index >= 0 && index < this.selectedFiles.length) {
      this.selectedFiles.splice(index, 1);
      this.filesSelected.emit(this.selectedFiles);
    }
  }

  preventDefault(event: Event): void {
    event.preventDefault();
  }
}
