<div class="fullscreen bg-indigo">
    <div class="white-box">
        <h1 class="font-heading">Welcome to CLARA</h1>
        <p class="color-black">Please confirm that the following is you: <b>{{firstName}} {{lastName}}</b></p>
        <form nz-form [formGroup]="validateForm" (ngSubmit)="submitForm()">
            <div class="color-black-container">
                <nz-form-item>
                    <nz-form-control nzErrorTip="Please confirm your identity!">
                        <label nz-checkbox formControlName="its_me">
                            <span>Yes, this is me.</span>
                        </label>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <label nz-checkbox formControlName="accept">
                        <span>By clicking Create Account, You agree to the <a class="link"
                                routerLink="/terms-and-conditions" target="_blank"
                                (click)="$event.stopPropagation()">Terms
                                and
                                Conditions</a> and <a class="link" routerLink="/privacy-policy" target="_blank"
                                (click)="$event.stopPropagation()">Privacy
                                Policy</a> of
                            CLARA.</span>
                    </label>
                </nz-form-item>

                <div class="radio-group">
                    <label class="radio-container">
                        <input type="radio" value="under_13" formControlName="age" />
                        <span class="radio-custom"></span>
                        I am at least 13 years of age under the supervision of the parent or
                        legal guardian.
                    </label>
                    <label class="radio-container">
                        <input type="radio" value="above_18" formControlName="age" />
                        <span class="radio-custom"></span>
                        I am 18 years or older.
                    </label>

                </div>
                <nz-form-item>
                    <div class="text-center">
                        <p>Opt to use personal information in accordance with our <a class="link"
                                routerLink="/opt-data-policy" target="_blank"
                                (click)="$event.stopPropagation()">opt-data-policy</a> for
                            a
                            candidate summary.</p>
                        <nz-radio-group formControlName="opt_in">
                            <label nz-radio-button nzValue="Opt In">Opt In</label>
                            <label nz-radio-button nzValue="Opt Out">Opt Out</label>
                        </nz-radio-group>
                    </div>
                </nz-form-item>
            </div>
            <nz-form-item>
                <button nz-button nzBlock nzSize="large" [nzType]="'primary'" nzShape="round"
                    [disabled]="!validateForm.get('its_me')?.value || !validateForm.get('accept')?.value || !validateForm.valid">
                    <span *ngIf="false" nz-icon nzType="loading" nzTheme="outline"></span>
                    Go to Follow Up Questions
                </button>
            </nz-form-item>

            <div class="text-center">
                Reach out to <a href="mailto:info@getclara.io">info&#64;getclara.io</a> if you need assistance.
            </div>
        </form>

    </div>


</div>