import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SjmManagerService } from 'src/app/Managers/sjm-manager.service';

@Component({
  selector: 'app-apply-job',
  templateUrl: './apply-job.component.html',
  styleUrl: './apply-job.component.scss',
})
export class ApplyJobComponent {
  jobObjId: string = '';
  constructor(
    private activatedRoute: ActivatedRoute,
    public sjmMgr: SjmManagerService
  ) {}
  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(async (params) => {
      this.jobObjId = params['job_obj_id'];
      await this.sjmMgr.getJobInformation(this.jobObjId);
    });
  }
}
