import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  AbstractControl,
  FormGroup,
  UntypedFormBuilder,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { UsersManagerService } from 'src/app/Managers/users-manager.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent {
  token: string = '';
  passwordVisible: boolean = false;
  passwordConfirmVisible: boolean = false;
  formResetPassword!: FormGroup;
  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    public usersServiceManger: UsersManagerService,
    private notification: NzNotificationService
  ) {}

  resetPassword(): void {
    if (this.formResetPassword.valid) {
      let new_password = this.formResetPassword.get('new_password')?.value;
      let data = {
        token: this.token,
        password: new_password,
      };
      this.usersServiceManger.resetPassword(data);
    } else {
      Object.values(this.formResetPassword.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  ngOnInit(): void {
    this.formResetPassword = this.fb.group({
      new_password: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.pattern(/^(?=.*[!@#$%^&*])/),
        ],
      ],
    });

    this.route.queryParams.subscribe((params) => {
      const token = params['token'];
      this.token = token;
    });
  }
}
