<div class="page bg-white">
    <!-- Side menu -->
    <app-side-menu></app-side-menu>
    <!-- main -->
    <main class="main">
        <nz-spin [nzSpinning]="this.candidateMgr.isLoadingProfile">
            <nz-breadcrumb>
                <nz-breadcrumb-item>
                    <a routerLink="/"><span nz-icon nzType="home"></span></a>
                </nz-breadcrumb-item>
                <nz-breadcrumb-item
                    *ngIf="user.role === 'Recruiter' || userRole === 'Partner Admin' || userRole === 'Account Owner'"><a
                        routerLink="/candidates">Candidates</a></nz-breadcrumb-item>
                <nz-breadcrumb-item>Candidate Details</nz-breadcrumb-item>
            </nz-breadcrumb>
            <div *ngIf="candidateMgr.candidateProfile">
                <div class="sub-tagline">
                    <h1 class="font-heading">{{candidateMgr.candidateProfile.initials}}</h1>
                </div>
                <div nz-row [nzGutter]="16">
                    <!-- <div nz-col [nzSpan]="12" *ngIf="candidateMgr.candidateProfile.website_addresses.length > 0">
                        <div class="border-box font-small">
                            <span class="mr-36">Website</span>
                            <span class="mr-36"
                                *ngFor="let website of candidateMgr.candidateProfile.website_addresses">{{website.value}}</span>
                        </div>
                    </div> -->
                    <div nz-col [nzSpan]="12" *ngIf="candidateMgr.candidateProfile.remote_id">
                        <div class="border-box font-small">
                            <span class="mr-36">CLARA ID</span>
                            <span>{{candidateMgr.candidateProfile.remote_id}}</span>
                        </div>
                    </div>

                </div>
                <div nz-row [nzGutter]="16">
                    <div nz-col [nzSpan]="12" *ngIf="candidateMgr.candidateProfile.first_name">
                        <div class="border-box box-shadow">
                            <h4>General Information</h4>
                            <div class="info-item font-md">
                                <span class="mr-36">First Name</span>
                                <b>{{candidateMgr.candidateProfile.first_name }}</b>
                            </div>
                            <div class="info-item font-md">
                                <span class="mr-36">Last Name</span>
                                <b>{{candidateMgr.candidateProfile.last_name }}</b>
                            </div>

                            <div class="info-item font-md">
                                <span class="mr-36">Location</span>
                                <b lass="mr-36"
                                    *ngFor="let add of candidateMgr.candidateProfile.addresses">{{add.value}}</b>
                            </div>
                        </div>
                        <div class="border-box box-shadow">
                            <h4>Contact Details</h4>
                            <div class="info-item font-md">
                                <span class="mr-36">Phone Number:</span>
                                <b lass="mr-36"
                                    *ngFor="let phone of candidateMgr.candidateProfile.phone_numbers">{{phone.value}}</b>
                            </div>
                            <div class="info-item font-md">
                                <span class="mr-36">Email:</span>
                                <div>
                                    <b class="d-block"
                                        *ngFor="let email of candidateMgr.candidateProfile.email_addresses">{{email.value}}
                                    </b><br>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div nz-col [nzSpan]="12"
                        *ngIf="candidateMgr.candidateProfile.applications && candidateMgr.candidateProfile.applications.length > 0">
                        <div class="border-box box-shadow">
                            <h4>Applications</h4>
                            <div class="candidate-score"
                                *ngFor="let item of candidateMgr.candidateProfile.applications">
                                <ng-container *ngIf="item.score != null">
                                    <div nz-row [nzGutter]="16" nzJustify="space-between">
                                        <div nz-col [nzSpan]="12">
                                            <h4>{{item.job.title}} <span class="font-light">{{item.applied_at}}</span>
                                            </h4>
                                        </div>
                                        <div nz-col [nzSpan]="12" class="text-right jos-status">
                                            <nz-tag class="ico-badge" *ngIf="item.job.status === 'closed'">
                                                <nz-badge nzColor="#a8a7ac"></nz-badge>
                                                Closed
                                            </nz-tag>
                                            <span class="font-small" *ngIf="item.job.remote_id">ID
                                                {{item.job.remote_id}}</span>
                                        </div>
                                    </div>

                                    <div class="progress"
                                        (click)="goToJobPage(item.job._id,item.job.status,item.job.ats,item._id)">
                                        <div class="progress-value"
                                            [ngClass]="item.job.status === 'open' ? 'theme' : 'gray'"
                                            [ngStyle]="{'width': item.score}">
                                        </div>
                                        <div class="progress-label"><span class="font-lg">
                                                {{item.job.status === 'open' ? item.score : 'Not Available'}}
                                            </span><br><span class="font-light">Clara
                                                Score</span>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="item.score === null">
                                    <div nz-row [nzGutter]="16" nzJustify="space-between">
                                        <div nz-col [nzSpan]="12">
                                            <h4>{{item.job.title}} <span class="font-light">{{item.applied_at
                                                    }}</span>
                                            </h4>
                                        </div>
                                        <div nz-col [nzSpan]="12" class="text-right">
                                            <span class="font-small" *ngIf="item.job.remote_id">ID
                                                {{item.job.remote_id}}</span>
                                        </div>
                                    </div>

                                    <div class="progress"
                                        (click)="goToJobPage(item.job._id,item.job.status,item.job.ats,item._id)">
                                        <div class="progress-value no-score">
                                        </div>
                                        <div class="progress-label"><span class="font-lg font-color-light">
                                                Not Available <span class="ico-sm" nz-popover
                                                    [nzPopoverContent]="contentTemplate" nzPopoverPlacement="topLeft"
                                                    nz-icon nzType="info-circle" nzTheme="outline"></span>
                                            </span><br><span class="font-light">Clara
                                                Score</span>
                                        </div>
                                        <ng-template #contentTemplate>
                                            <div class="info-popover">
                                                Applications for jobs before CLARA was adopted are not processed.
                                            </div>
                                        </ng-template>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>

                </div>

                <!-- <div class="border-box box-shadow">
                    <h4>Comments</h4>
                    <app-comments-candidates [candidatesId]="candidateId"></app-comments-candidates>
                </div> -->
            </div>
        </nz-spin>
    </main>

</div>