<div>
    <div class="gray-text-box">
        <h4 class="font-heading">Best Practices</h4>
        <ol>
            <li>Keep it concise: Limit the number of questions to 2-3 to streamline the candidate's
                response time and ensure they focus
                on the most relevant aspects of the job.</li>
            <li>
                Focus on key competencies: Craft questions that directly assess the candidate's
                skills,
                experience, and qualities
                crucial for success in the role, aligning with the job requirements and company
                culture.
            </li>
            <li *ngIf="!isExpanded">
                Use the most simple question type to get the information you seek. Open-ended,
                experience-based and scenario-based questions are great for gaining a
                comprehensive...
            </li>
            <ng-container *ngIf="isExpanded">
                <li>
                    Use the most simple question type to get the information you seek. Open-ended,
                    experience-based and scenario-based questions are great for gaining a
                    comprehensive understanding of the candidate's abilities, problem-solving
                    skills,
                    and cultural fit, but they take longer to answer.
                </li>
                <li>Consider using a rating (1-10), percentage splits, or options like “not at all”
                    to
                    very likely” to help the candidates
                    be able to respond more quickly as well as to give you the ability to compare
                    responses more fairly and consistently.</li>
                <li>Some successful sample questions from other partners include:</li>
                <li>When you consider sets of complementary traits, we are all a unique balance. For
                    example, when thinking about being
                    operational and strategic you might be 60% operational + 40% strategic, or maybe
                    75%
                    strategic + 25% operational.</li>
                <li>How would you describe yourself when it comes to each of the following three
                    complementary sets of traits? (Be sure that
                    they add up to 100)
                    strategic and operational
                    consistent and flexible
                    structured and dynamic</li>
                <li>On a scale of 1 - 10, with 1 being not at all familiar and 10 being extremely
                    familiar, how would you rate your
                    familiarity with Google Workspace tools?</li>
                <li>
                    Focus on key competencies: Craft questions that directly assess the candidate's
                    skills, experience, and qualities
                    crucial for success in the role, aligning with the job requirements and company
                    culture.
                    Use the most simple question type to get the information you seek.
                    Open-ended, experience-based and scenario-based questions are great for gaining
                    a
                    comprehensive understanding of the
                    candidate's abilities, problem-solving skills, and cultural fit, but they take
                    longer to answer.
                    Consider using a rating (1-10), percentage splits, or options like “not at all”
                    to
                    very likely” to help the candidates
                    be able to respond more quickly as well as to give you the ability to compare
                    responses more fairly and consistently.
                    Some successful sample questions from other partners include:
                    When you consider sets of complementary traits, we are all a unique balance. 
                    For
                    example, when thinking about being
                    operational and strategic you might be 60% operational + 40% strategic, or maybe
                    75%
                    strategic + 25% operational.
                    How would you describe yourself when it comes to each of the following three
                    complementary sets of traits? (Be sure that
                    they add up to 100)
                    strategic and operational
                    consistent and flexible
                    structured and dynamic
                    On a scale of 1 - 10, with 1 being not at all familiar and 10 being extremely
                    familiar, how would you rate your
                    familiarity with Google Workspace tools?
                    Think of an instance from your professional or personal life where you
                    successfully
                    mediated between two team people who
                    were experiencing a challenge in getting along.  Please briefly share 1-2
                    techniques
                    you used to alleviate the
                    situation.
                    On a scale of 1 - 10, with 1 being not at all effective and 10 being extremely
                    effective, how would your last supervisor
                    rate you on your ability to work with people across the range of experience from
                    entry-level to senior management?</li>
                <li>On a scale of 1 - 10, with 1 being not at all effective and 10 being extremely
                    effective, how would your last supervisor
                    rate you on your ability to work with people across the range of experience from
                    entry-level to senior management?</li>
            </ng-container>
        </ol>
        <div class="button-container">
            <button nz-button nzType="text" class="btn-dark" (click)="toggleReadMore()">{{
                isExpanded ?
                'Read Less' :
                'Read More' }}</button>
        </div>
    </div>
    <p class="font-medium">Add custom questions to ask Candidates.</p>
    <div *ngIf="!showEditable" class="button-container mb-24">
        <button (click)="toggleEditable()" nz-button nzType="default" nzSize="large" class="btn-gray"
            nzShape="round"><span nz-icon nzType="plus" nzTheme="outline"></span>Add New Follow-Up
            Question</button>
    </div>
    <form [formGroup]="form" class="flex-modal">
        <div class="editable-wrap" *ngIf="showEditable" formArrayName="followUpQuestions">
            <div class="editable-item" *ngFor="let question of followUpQuestions.controls; let i = index"
                [formGroupName]="i">
                <div class="holder"><span nz-icon nzType="holder" nzTheme="outline"></span></div>
                <div class="editable-body">
                    <p>Follow Up Question:</p>
                    <div nz-row [nzGutter]="16">
                        <div nz-col [nzSpan]="22">
                            <nz-form-control>
                                <textarea formControlName="question" nz-input rows="3" nzSize="large"
                                    placeholder="Enter question."></textarea>
                            </nz-form-control>
                        </div>
                        <div nz-col [nzSpan]="2">
                            <button class="remove-button" (click)="removeFollowUpQuestion(i)">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z"
                                        fill="#1C1A20" fill-opacity=".7" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="add-button">
                <button (click)="addFollowUpQuestion()" nz-button nzType="text"><span nz-icon nzType="plus"
                        nzTheme="outline"></span>
                    Add
                    New Follow-Up Question</button>
            </div>
        </div>
    </form>
    <ng-template #customSuffixIcon>
        <span nz-icon nzType="caret-down" nzTheme="outline"></span>
    </ng-template>
</div>