import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
@Component({
  selector: 'app-confirm-identity',
  templateUrl: './confirm-identity.component.html',
  styleUrl: './confirm-identity.component.scss',
})
export class ConfirmIdentityComponent {
  validateForm!: UntypedFormGroup;
  token: string = '';
  firstName: string = '';
  lastName: string = '';
  email: string = '';
  applicationId: string = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private fb: UntypedFormBuilder
  ) {}

  submitForm(): void {
    if (this.validateForm.valid) {
      const age = this.validateForm.controls['age'].value;
      console.log(this.validateForm.value);

      this.router.navigate(['/follow-up-questions'], {
        queryParams: {
          token: this.token,
          first_name: this.firstName,
          last_name: this.lastName,
          email: this.email,
          application_id: this.applicationId,
          age: age,
        },
      });
    } else {
      Object.values(this.validateForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
        console.log('error');
      });
    }
  }

  gotoFollowUfQuestions(): void {
    this.router.navigate(['/follow-up-questions'], {
      queryParams: {
        token: this.token,
        first_name: this.firstName,
        last_name: this.lastName,
        email: this.email,
        application_id: this.applicationId,
      },
    });
  }
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.token = params['token'];
      this.firstName = params['first_name'];
      this.lastName = params['last_name'];
      this.email = params['email'];
      this.applicationId = params['application_id'];
    });
    this.validateForm = this.fb.group({
      its_me: [false, Validators.required],
      accept: [false, Validators.required],
      age: ['', Validators.required],
      opt_in: ['Opt In', Validators.required],
    });
  }
}
