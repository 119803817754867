<div class="page bg-white">
    <!-- Side menu -->
    <app-side-menu></app-side-menu>
    <!-- main -->
    <main class="main">
        <nz-spin [nzSpinning]="userMgr.isLoading">
            <nz-breadcrumb>
                <nz-breadcrumb-item>
                    <a routerLink="/"><span nz-icon nzType="home"></span></a>
                </nz-breadcrumb-item>
                <nz-breadcrumb-item>My Profile</nz-breadcrumb-item>
            </nz-breadcrumb>
            <div *ngIf="userMgr.myProfileCandidate">
                <div class="sub-tagline">
                    <h1 class="font-heading">
                        {{ userMgr.myProfileCandidate.profile_info?.first_name?.[0] }}
                        {{ userMgr.myProfileCandidate.profile_info?.last_name?.[0] }}
                    </h1>
                </div>
                <div nz-row [nzGutter]="16">
                    <div nz-col [nzSpan]="12" *ngIf="userMgr.myProfileCandidate?.profile_info?.website_addresses">
                        <div class="border-box font-small">
                            <span class="mr-36">Website</span>
                            <span class="mr-36"
                                *ngFor="let website of userMgr.myProfileCandidate?.profile_info?.website_addresses">{{website.value}}</span>
                        </div>
                    </div>
                    <div nz-col [nzSpan]="12">
                        <div class="border-box font-small">
                            <span class="mr-36">CLARA ID</span>
                            <span>{{user.id}}</span>
                        </div>
                    </div>

                </div>
                <div nz-row [nzGutter]="16">
                    <div nz-col [nzSpan]="12">
                        <div class="border-box box-shadow">
                            <h4>General Information</h4>
                            <div class="info-item font-md">
                                <span class="mr-36">First Name</span>
                                <b>{{userMgr.myProfileCandidate.profile_info?.first_name }}</b>
                            </div>
                            <div class="info-item font-md">
                                <span class="mr-36">Last Name</span>
                                <b>{{userMgr.myProfileCandidate.profile_info?.last_name }}</b>
                            </div>

                            <div class="info-item font-md" *ngIf="userMgr.myProfileCandidate?.profile_info?.addresses">
                                <span class="mr-36">Location</span>
                                <b class="mr-36"
                                    *ngFor="let add of userMgr.myProfileCandidate?.profile_info?.addresses">{{add.value}}</b>
                            </div>
                        </div>

                    </div>

                    <div nz-col [nzSpan]="12"
                        *ngIf="userMgr.myProfileCandidate.applications && userMgr.myProfileCandidate.applications.length > 0">
                        <div class="border-box box-shadow">
                            <h4>Applications</h4>
                            <div class="candidate-score" *ngFor="let item of userMgr.myProfileCandidate?.applications">
                                <ng-container *ngIf="item.profile?.fit_score != null">
                                    <div nz-row [nzGutter]="16" nzJustify="space-between">
                                        <div nz-col [nzSpan]="12">
                                            <h4>{{item.job_title}} <span class="font-light">{{item.applied_at}}</span>
                                            </h4>
                                        </div>
                                        <div nz-col [nzSpan]="12" class="text-right jos-status">
                                            <nz-tag class="ico-badge" *ngIf="item.job_status === 'closed'">
                                                <nz-badge nzColor="#a8a7ac"></nz-badge>
                                                Closed
                                            </nz-tag>
                                            <span *ngIf="item.remote_id" class="font-small">ID {{item.remote_id}}</span>
                                        </div>
                                    </div>

                                    <div class="progress">
                                        <div class="progress-value"
                                            [ngClass]="item.job_status === 'open' ? 'theme' : 'gray'"
                                            [ngStyle]="{'width': item.profile?.fit_score}">
                                        </div>
                                        <div class="progress-label">
                                            <span class="font-lg">
                                                <span
                                                    *ngIf="item.job_status === 'open'">{{item.profile?.fit_score}}</span>
                                                <span *ngIf="item.job_status === 'closed'">Not Available</span>
                                            </span><br><span class="font-light">Clara
                                                Score</span>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="item.profile?.fit_score === null">
                                    <div nz-row [nzGutter]="16" nzJustify="space-between">
                                        <div nz-col [nzSpan]="12">
                                            <h4>{{item.job_title}} <span class="font-light">{{item.applied_at | date
                                                    }}</span>
                                            </h4>
                                        </div>
                                        <div nz-col [nzSpan]="12" class="text-right">
                                            <span *ngIf="item.remote_id" class="font-small">ID {{item.remote_id}}</span>
                                        </div>
                                    </div>

                                    <div class="progress">
                                        <div class="progress-value no-score">
                                        </div>
                                        <div class="progress-label"><span class="font-lg font-color-light">
                                                Not Available <span class="ico-sm" nz-popover
                                                    [nzPopoverContent]="contentTemplate" nzPopoverPlacement="topLeft"
                                                    nz-icon nzType="info-circle" nzTheme="outline"></span>
                                            </span><br><span class="font-light">Clara
                                                Score</span>
                                        </div>
                                        <ng-template #contentTemplate>
                                            <div class="info-popover">
                                                Applications for jobs before CLARA was adopted are not processed.
                                            </div>
                                        </ng-template>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </nz-spin>
    </main>

</div>