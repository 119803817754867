import { Injectable } from '@angular/core';
import { users } from '../models/users';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { AssignJobService } from '../Services/assign-job.service';

@Injectable({
  providedIn: 'root',
})
export class AssignJobManagerService {
  public isLoading: boolean = false;
  public isAssigningUser: boolean = false;
  usersHR: users[] = [];
  assignUserList: users[] = [];
  public jobObjectId: string = '';
  usersRecruiter: users[] = [];
  constructor(
    public asignJobServices: AssignJobService,
    private notification: NzNotificationService
  ) {}
  public getHR(role: string): Promise<void> {
    return new Promise<void>((resolve) => {
      this.isLoading = true;
      this.asignJobServices.getUsers(role).subscribe((c: any) => {
        this.usersHR = c.response.results;
        this.isLoading = false;
        resolve();
      });
    });
  }
  public getRecruiter(role: string): Promise<void> {
    return new Promise<void>((resolve) => {
      this.isLoading = true;
      this.asignJobServices.getUsers(role).subscribe((c: any) => {
        this.usersRecruiter = c.response.results;
        this.isLoading = false;
        resolve();
      });
    });
  }
  public getAssignJobs(jobObjectId: string): Promise<void> {
    return new Promise<void>((resolve) => {
      this.isLoading = true;
      this.asignJobServices.getAssignJobs(jobObjectId).subscribe((c: any) => {
        this.assignUserList = c.response;
        const updateUser = (users: any[], role: string) => {
          c.response
            .filter((assignJob: any) => assignJob.user_role === role)
            .forEach((assignJob: any) => {
              const user = users.find((u) => u.id === assignJob.user_id);
              if (user) user.isAssignedJob = true;
            });
        };

        updateUser(this.usersHR, 'Hiring Manager');
        updateUser(this.usersRecruiter, 'Recruiter');
        this.isLoading = false;
        resolve();
      });
    });
  }

  public assignJob(formData: any): Promise<void> {
    return new Promise<void>((resolve) => {
      this.isAssigningUser = true;
      this.asignJobServices.assignJob(formData).subscribe({
        next: async (res: any) => {
          this.notification.create(
            'success',
            'Job Assignment Complete!',
            'Job has been assigned to user successfully!'
          );
          await this.getHR('Hiring Manager');
          await this.getRecruiter('Recruiter');
          this.getAssignJobs(this.jobObjectId);
          this.isAssigningUser = false;
          resolve();
        },
        error: (error: any) => {
          this.notification.create('error', 'Error', error.error.error);
          this.isAssigningUser = false;
        },
      });
    });
  }
}
