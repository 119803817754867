import { Component } from '@angular/core';
import { ImpersonationManagerService } from 'src/app/Managers/impersonation-manager.service';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-impersonation',
  templateUrl: './impersonation.component.html',
  styleUrl: './impersonation.component.scss',
})
export class ImpersonationComponent {
  selectedUser = null;
  user: any;
  form!: FormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    public imprMgr: ImpersonationManagerService
  ) {}
  loadMorePartners(): void {
    if (this.imprMgr.nextPageUrl) {
      this.imprMgr.getPartnersMore(++this.imprMgr.partnerPage);
    }
  }
  partnerChange(value: number): void {
    this.imprMgr.getPartnersusers(value);
  }
  submitForm(): void {
    const userId = this.form.get('username')?.value;
    const reason = this.form.get('reason')?.value;
    this.imprMgr.startImpersonation(userId, reason);
  }
  exitImpersonation(): void {
    this.imprMgr.exitImpersonation();
  }
  isUsernameDisabled() {
    return this.imprMgr.partnersUsers.length === 0;
  }
  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('user') || '{}');
    const userRole = this.user.role?.trim();
    this.form = this.fb.group({
      partner_name: ['', Validators.required],
      username: ['', Validators.required],
      reason: ['', Validators.required],
    });
    this.imprMgr.getPartners(1);

    if (
      userRole === 'Admin' ||
      userRole === 'Partner Admin' ||
      userRole === 'Account Owner '
    ) {
      this.imprMgr.currentImpersonation();
    }
  }
}
