<div class="all-jobs">
    <div class="heading">
        <p>{{user.role === 'Recruiter' ? 'My Jobs Overview' : 'All Jobs Overview' }} </p>
    </div>
    <div class="all-jobs-flex">
        <div class="job-item height-148">
            <div class="job-counts">
                <div class="d-flex justify-content-center align-items-baseline">
                    <span class="value">{{data && data.all.current_count}}</span>
                    <span class="percentage">{{data && data.all.percentage}}%</span>
                </div>
                <span class="job-badge"
                    [ngClass]="{'down-trend': (data && data.all.current_count - data && data.all.last_count) < 0}">
                    <span class="job-icon" *ngIf="data && data.all.current_count - data && data.all.last_count !== 0">
                        <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M1.24375 8.5L0.5 7.75625L6.40625 1.85H1.2V0.800003H8.2V7.8H7.15V2.59375L1.24375 8.5Z" />
                        </svg>

                    </span>
                    <span>{{data && data.all.current_count - data && data.all.last_count}}</span>
                </span>
                <span class="job-title">All Applications</span>
            </div>
        </div>
        <div class="job-item height-148">
            <div class="job-counts">
                <div class="d-flex justify-content-center align-items-baseline">
                    <span class="value">{{data && data.not_qualified.current_count}}</span>
                    <span class="percentage">{{data && data.not_qualified.percentage}}%</span>
                </div>

                <span class="job-badge"
                    [ngClass]="{'down-trend': (data && data.not_qualified.current_count - data && data.not_qualified.last_count) < 0}">
                    <span class="job-icon"
                        *ngIf="data && data.not_qualified.current_count - data && data.not_qualified.last_count !== 0">
                        <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M1.24375 8.5L0.5 7.75625L6.40625 1.85H1.2V0.800003H8.2V7.8H7.15V2.59375L1.24375 8.5Z" />
                        </svg>

                    </span>
                    <span>{{data && data.not_qualified.current_count - data && data.not_qualified.last_count}}</span>
                </span>
                <span class="job-title">Not Qualified</span>
            </div>
        </div>
        <div class="job-item height-148">
            <div class="job-counts">
                <div class="d-flex justify-content-center align-items-baseline">
                    <span class="value">{{data && data.pre_qualified.current_count}}</span>
                    <span class="percentage">{{data && data.pre_qualified.percentage}}%</span>
                </div>
                <span class="job-badge"
                    [ngClass]="{'down-trend': (data && data.pre_qualified.current_count - data && data.pre_qualified.last_count) < 0}">
                    <span class="job-icon"
                        *ngIf="data && data.pre_qualified.current_count - data && data.pre_qualified.last_count !== 0">
                        <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M1.24375 8.5L0.5 7.75625L6.40625 1.85H1.2V0.800003H8.2V7.8H7.15V2.59375L1.24375 8.5Z" />
                        </svg>

                    </span>
                    <span>{{data && data.pre_qualified.current_count - data && data.pre_qualified.last_count}}</span>
                </span>
                <span class="job-title">Pre Qualified</span>
            </div>
        </div>
        <div class="job-item height-148">
            <div class="job-counts">
                <div class="d-flex justify-content-center align-items-baseline">
                    <span class="value">{{data && data.qualified.current_count}}</span>
                    <span class="percentage">{{data && data.qualified.percentage}}%</span>
                </div>
                <span class="job-badge"
                    [ngClass]="{'down-trend': (data && data.qualified.current_count - data && data.qualified.last_count) < 0}">

                    <span class="job-icon"
                        *ngIf="data && data.qualified.current_count - data && data.qualified.last_count !== 0">
                        <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M1.24375 8.5L0.5 7.75625L6.40625 1.85H1.2V0.800003H8.2V7.8H7.15V2.59375L1.24375 8.5Z" />
                        </svg>

                    </span>
                    <span>{{data && data.qualified.current_count - data && data.qualified.last_count}}</span>
                </span>
                <span class="job-title">Qualified</span>
            </div>
        </div>

        <div class="job-item height-148">
            <div class="job-counts">
                <div class="d-flex justify-content-center align-items-baseline">
                    <span class="value">{{data && data.final_decision.current_count}}</span>
                    <span class="percentage">
                        &lt;{{data && data.final_decision.percentage}}%</span>
                </div>
                <span class="job-badge"
                    [ngClass]="{'down-trend': (data && data.final_decision.current_count - data && data.final_decision.last_count) < 0}">
                    <span class="job-icon"
                        *ngIf="data && data.final_decision.current_count - data && data.final_decision.last_count !== 0">
                        <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M1.24375 8.5L0.5 7.75625L6.40625 1.85H1.2V0.800003H8.2V7.8H7.15V2.59375L1.24375 8.5Z" />
                        </svg>

                    </span>
                    <span>{{data && data.final_decision.current_count - data && data.final_decision.last_count}}</span>
                </span>
                <span class="job-title">Final Decision</span>
            </div>
        </div>
    </div>
</div>