import { Component, ChangeDetectorRef } from '@angular/core';
import { JobsManagerService } from 'src/app/Managers/jobs-manager.service';
import { ApplicationManagerService } from 'src/app/Managers/applications-manager.service';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { interval, Subscription } from 'rxjs';
@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss'],
})
export class JobsComponent {
  searchFormActive!: UntypedFormGroup;
  searchFormClosed!: UntypedFormGroup;
  currentStep = 0;
  showFullDetails: boolean[] = [];
  checked = false;
  loading = false;
  indeterminate = false;
  selectedImportJobs: readonly any[] = [];
  listOfCurrentPageData: readonly any[] = [];
  setOfCheckedId = new Set<any>();
  isVisibleJobCollapse = [true, false];
  user: any;
  dataActive: any;
  subscription!: Subscription;

  constructor(
    private router: Router,
    public jobsMgr: JobsManagerService,
    public applicationMgr: ApplicationManagerService,
    private fb: UntypedFormBuilder,
    private cdRef: ChangeDetectorRef
  ) {}

  selectRow(job: any) {
    const jobObjectId = job?._id;
    const atsName = job?.ats;
    if (jobObjectId) {
      this.router.navigate(['jobs/candidates/', jobObjectId], {
        queryParams: { atsName },
      });
    }
  }

  // Active jobs start ===========
  async handlePageIndexChangeActive(pageIndex: number): Promise<void> {
    this.subscription?.unsubscribe();
    this.jobsMgr.activePage = pageIndex;
    await this.jobsMgr.getActiveJobs(
      this.jobsMgr.activePage,
      this.jobsMgr.partnerIDActive,
      this.jobsMgr.searchActive,
      this.jobsMgr.statusActive
    );
    this.startCheckingCounts();
  }
  onSearchInputActive(): void {
    this.submitSearchActive();
  }
  onSearchInputClosed(): void {
    this.submitSearchClosed();
  }
  submitSearchActive(): void {
    this.subscription?.unsubscribe();
    this.jobsMgr.searchActive = this.searchFormActive.value.searchActive;
    this.jobsMgr.getActiveJobs(
      1,
      this.jobsMgr.partnerIDActive,
      this.jobsMgr.searchActive,
      this.jobsMgr.statusActive
    );
  }
  // Active jobs closed ===========

  // Closed jobs start ===========
  handlePageIndexChangeClosed(pageIndex: number): void {
    this.jobsMgr.closedPage = pageIndex;
    this.jobsMgr.getClosedJobs(
      this.jobsMgr.closedPage,
      this.jobsMgr.partnerIDClosed,
      this.jobsMgr.searchClosed,
      this.jobsMgr.statusClosed
    );
  }
  submitSearchClosed(): void {
    this.jobsMgr.searchClosed = this.searchFormClosed.value.searchClosed;
    this.jobsMgr.getClosedJobs(
      1,
      this.jobsMgr.partnerIDClosed,
      this.jobsMgr.searchClosed,
      this.jobsMgr.statusClosed
    );
  }
  // Closed jobs closed ===========
  goToUploadNewJob() {
    this.router.navigate(['jobs/upload-new-job']);
  }
  // Import jobs

  importJobsModal() {
    this.jobsMgr.isVisibleImportJob = true;
    this.jobsMgr.getImportJobs('open');
  }
  toggleCollapse(index: number): void {
    this.isVisibleJobCollapse[index] = !this.isVisibleJobCollapse[index];
    if (index === 1 && this.isVisibleJobCollapse[index]) {
      this.jobsMgr.getImportJobsClosed('closed');
    }
  }
  handleOkImportJob(): void {
    this.currentStep = 1;
    this.jobsMgr.isVisibleImportJob = false;
  }

  handleCancelImportJob(): void {
    this.currentStep = 0;
    this.jobsMgr.importJobsData = [];
    this.jobsMgr.isVisibleImportJob = false;
  }

  async nextStep(): Promise<void> {
    if (this.currentStep < 1) {
      this.currentStep++;
      const requestData = this.jobsMgr.importJobsData.filter((data) =>
        this.setOfCheckedId.has(data.remote_id)
      );

      this.selectedImportJobs = requestData;
    } else {
      const jobsRequest = this.selectedImportJobs.map((job) => ({
        job_id: job.remote_id,
        ats_name: job.ats,
        integration_id: job.integration_id,
      }));
      let data = {
        jobs: jobsRequest,
      };
      await this.jobsMgr.importJobs(data);
      await this.jobsMgr.getActiveJobs(this.jobsMgr.activePage, '', '', 'open');
      this.startCheckingCounts();
    }
  }

  prevStep(): void {
    if (this.currentStep > 0) {
      this.currentStep--;
    }
  }
  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }

  onCurrentPageDataChange(listOfCurrentPageData: any): void {
    this.listOfCurrentPageData = listOfCurrentPageData;
    this.refreshCheckedStatus();
  }

  refreshCheckedStatus(): void {
    const listOfEnabledData = this.listOfCurrentPageData.filter(
      ({ disabled }) => !disabled
    );
    this.checked = listOfEnabledData.every(({ id }) =>
      this.setOfCheckedId.has(id)
    );
    this.indeterminate =
      listOfEnabledData.some(({ id }) => this.setOfCheckedId.has(id)) &&
      !this.checked;
  }

  onItemChecked(id: any, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }
  toggleFullDetails(index: number) {
    this.showFullDetails[index] = !this.showFullDetails[index];
  }
  parseDate(dateString: string): Date {
    const parts = dateString.split('-');
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1; // Months are zero-indexed
    const year = parseInt(parts[2], 10);
    return new Date(year, month, day);
  }
  replaceEscapeSequences(html: any): any {
    if (html == null) {
      return '';
    }
    return html
      .replace(/\\n/g, '')
      .replace(/\n/g, '')
      .replace(/\\f/g, '')
      .replace(/\f/g, '')
      .replace(/\\t/g, '&emsp;')
      .replace(/\t/g, '&emsp;')
      .replace(/\\u00a0/g, '&nbsp;')
      .replace(/\\u0026/g, '&amp;');
  }

  async processCandidates(id: string) {
    await this.jobsMgr.candidatesProcessing(id);
    await this.jobsMgr.getActiveJobs(this.jobsMgr.activePage, '', '', 'open');
    this.startCheckingCounts();
  }
  isRecent(dateString: string): boolean {
    const postingDate = new Date(dateString);
    const today = new Date();
    const sevenDaysAgo = new Date(today);
    sevenDaysAgo.setDate(today.getDate() - 7);
    return postingDate >= sevenDaysAgo;
  }

  startCheckingCounts() {
    const checkInterval = 5000;
    this.subscription = interval(checkInterval).subscribe(async () => {
      for (const job of this.jobsMgr.jobsActiveData) {
        if (job.received_application_count > job.processed_application_count) {
          await this.applicationMgr.jobApplicationCounts(job._id);
          const {
            processed_application_count,
            received_application_count,
            process_status,
          } = this.applicationMgr.currentCounts;
          if (processed_application_count === received_application_count) {
            this.jobsMgr.getActiveJobs(this.jobsMgr.activePage, '', '', 'open');
          }

          this.cdRef.detectChanges();
        }
      }
    });
  }

  async ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user') || '{}');
    this.searchFormClosed = this.fb.group({
      searchClosed: [''],
    });
    this.searchFormActive = this.fb.group({
      searchActive: [''],
    });
    await this.jobsMgr.getActiveJobs(1, '', '', 'open');
    this.startCheckingCounts();
    this.jobsMgr.getClosedJobs(1, '', '', 'closed');
  }
  ngOnDestroy() {
    this.jobsMgr.activePage = 1;
    this.subscription?.unsubscribe();
  }
}
