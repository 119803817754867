<div class="fullscreen bg-gradient">
    <div class="login-wrap">
        <h1 class="font-heading">CLARA</h1>
        <p>Are you sure you want to unsubscribe?</p>
        <!-- <div class="alert-login" *ngIf="userMgr.isUnsubscribed">
            You have been successfully unsubscribed from CLARA.
        </div> -->

        <div class="button-wrap" *ngIf="!userMgr.isUnsubscribed">
            <button nz-button nzType="primary" nzSize="large" class="mr-20" (click)="yes()"><span
                    *ngIf="userMgr.isUnsubscribing" nz-icon nzType="loading" nzTheme="outline"></span> Yes</button>
            <button nz-button nzType="default" nzSize="large" (click)="no()">No</button>
        </div>
    </div>
</div>