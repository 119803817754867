<div class="candidate-home">
    <div class="heading">
        <h2>Active Job Applications</h2>
    </div>
    <div nz-row [nzGutter]="16" *ngIf="dashboardMgr.candidateApplications.length > 0">
        <div nz-col [nzSpan]="12" *ngFor="let data of dashboardMgr.candidateApplications">
            <div class="card">
                <div class="card-header">{{data.job_title}}</div>
                <div class="card-body">
                    <div nz-row [nzGutter]="16" nzJustify="space-between">
                        <div nz-col [nzSpan]="12">
                            <span>ID {{data.dein_candidate_id}}</span><br>
                            <label class="label-badge">{{data.job_status ==='open' ? 'Active' : 'Inactive'}}</label>
                        </div>
                        <div nz-col [nzSpan]="12" class="text-right">
                            <span>Applied: {{data.applied_at | date}}</span><br>
                        </div>
                    </div>
                    <div class="candidate-alert">
                        <nz-alert class="" nzType="info" nzMessage="Follow up questions are ready!"
                            nzDescription="We have some follow up questions for your application to {{data.job_title}} Answering these questions will help to assess your fit for the position."
                            nzShowIcon></nz-alert>
                    </div>

                </div>
                <div class="card-footer">
                    <button (click)="gotoFollowUpQuestions(data['_id'])" nz-button nzBlock nzSize="large"
                        [nzType]="'primary'" class="mb-10" nzShape="round">
                        Go to Follow Up Questions
                    </button>
                </div>
            </div>
        </div>

    </div>
</div>