<div class="page">
    <!-- Side menu -->
    <app-side-menu></app-side-menu>
    <!-- main -->
    <main class="main">
        <nz-breadcrumb>
            <nz-breadcrumb-item>
                <a routerLink="/"><span nz-icon nzType="home"></span></a>
            </nz-breadcrumb-item>
            <nz-breadcrumb-item><a class="breadcrumb-menu-item"
                    (click)="toggleSubmenu()">Settings</a></nz-breadcrumb-item>
            <nz-breadcrumb-item>Subscription Settings</nz-breadcrumb-item>
        </nz-breadcrumb>

        <div nz-row [nzGutter]="16">
            <div nz-col>
                <div class="heading-flex">
                    <h1>Subscription Settings</h1>
                </div>
                <nz-spin [nzSpinning]="userMgr.isLoadingSubscription">
                    <div class="white-box"
                        *ngIf="userMgr.isCustomerChecked && userMgr.planDetails && userMgr.isStripeCustomer">
                        <div class="header-box">
                            <div class="d-flex align-middle">
                                <div>Subscription plan:</div>
                                <div class="badge-info ml-8">
                                    {{userMgr.planDetails.name }}</div>
                            </div>
                            <div class="d-flex align-middle">
                                <nz-tag class="ico-badge">
                                    <nz-badge [nzColor]="true ? '#5822d3' : '#CCCCCC'"></nz-badge>
                                    {{ userMgr.planDetails.status |
                                    titlecase }}
                                </nz-tag>
                                <span class="ico-sm ml-4" nz-tooltip
                                    nzTooltipTitle="Your account will be updated at the end of your payment cycle. You can use your account normally in the interim."
                                    nz-icon nzType="info-circle" [nzTooltipPlacement]="['topLeft', 'leftTop']"
                                    nzTheme="outline"></span>
                            </div>
                        </div>
                        <div class="body-box">
                            <p>Plan details:</p>
                            <ul class="packages-list" *ngIf="userMgr.planDetails.marketing_features.length > 0">
                                <li *ngFor="let package of userMgr.planDetails.marketing_features">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M17.385 6.497a1.5 1.5 0 1 1 2.23 2.006l-9 10a1.5 1.5 0 0 1-2.176.058l-4-4a1.5 1.5 0 1 1 2.122-2.122l2.882 2.882 7.942-8.824z"
                                            fill="#0FD795" />
                                    </svg>
                                    <span>{{package.name}}</span>
                                </li>

                            </ul>

                            <a *ngIf="userMgr.planDetails.customer_portal_url"
                                [href]="userMgr.planDetails.customer_portal_url" target="_blank"> <button nz-button
                                    nzType="default" nzShape="round">Account Billing <span nz-icon nzType="arrow-right"
                                        nzTheme="outline"></span></button></a>
                        </div>
                        <div class="utilization" *ngIf="userMgr.planDetails.threshold !== null">
                            <span>Plan utilization</span>
                            <div class="processing-flex">
                                <div class="progress-bar">
                                    <div class="progress-fill"
                                        [ngStyle]="{'width': userMgr.planDetails.threshold  + '%'}"></div>
                                </div>
                                <div class="progress-value-count">{{userMgr.planDetails.threshold}}%</div>
                            </div>
                        </div>
                        <div class="footer-box" *ngIf="userMgr.planDetails.customer_portal_url">
                            <a [href]="userMgr.planDetails.customer_portal_url" target="_blank"><button nz-button
                                    nzType="primary" nzShape="round" class="mb-8">Edit Plan</button></a>
                            <a [href]="userMgr.planDetails.customer_portal_url" target="_blank"><button nz-button
                                    nzType="text" nzShape="round">Cancel Plan</button></a>
                        </div>
                    </div>
                    <div class="white-box" *ngIf="userMgr.isCustomerChecked && !userMgr.isStripeCustomer">
                        <div class="header-box">
                            <div class="d-flex align-middle">
                                <div>Subscription plan:</div>
                                <div class="badge-info ml-8">
                                    Custom</div>
                            </div>
                            <div class="d-flex align-middle">
                                <nz-tag class="ico-badge">
                                    <nz-badge [nzColor]="true ? '#5822d3' : '#CCCCCC'"></nz-badge>
                                    Active
                                </nz-tag>

                            </div>
                        </div>
                        <div class="body-box">
                            <p>Plan details:</p>
                            <ul class="packages-list">
                                <li>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M17.385 6.497a1.5 1.5 0 1 1 2.23 2.006l-9 10a1.5 1.5 0 0 1-2.176.058l-4-4a1.5 1.5 0 1 1 2.122-2.122l2.882 2.882 7.942-8.824z"
                                            fill="#0FD795" />
                                    </svg>
                                    <span>Unlimited recruiter seats</span>
                                </li>
                                <li>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M17.385 6.497a1.5 1.5 0 1 1 2.23 2.006l-9 10a1.5 1.5 0 0 1-2.176.058l-4-4a1.5 1.5 0 1 1 2.122-2.122l2.882 2.882 7.942-8.824z"
                                            fill="#0FD795" />
                                    </svg>
                                    <span>Unlimited hiring manager seats</span>
                                </li>
                                <li>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M17.385 6.497a1.5 1.5 0 1 1 2.23 2.006l-9 10a1.5 1.5 0 0 1-2.176.058l-4-4a1.5 1.5 0 1 1 2.122-2.122l2.882 2.882 7.942-8.824z"
                                            fill="#0FD795" />
                                    </svg>
                                    <span>50,000 application limit</span>
                                </li>
                                <li>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M17.385 6.497a1.5 1.5 0 1 1 2.23 2.006l-9 10a1.5 1.5 0 0 1-2.176.058l-4-4a1.5 1.5 0 1 1 2.122-2.122l2.882 2.882 7.942-8.824z"
                                            fill="#0FD795" />
                                    </svg>
                                    <span>Supplemental packages</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nz-spin>
            </div>
        </div>
    </main>
</div>