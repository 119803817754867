import { Component } from '@angular/core';
import { CandidatesManagerService } from 'src/app/Managers/candidates-manager.service';
import { Router } from '@angular/router';

import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
@Component({
  selector: 'app-candidates',
  templateUrl: './candidates.component.html',
  styleUrl: './candidates.component.scss',
})
export class CandidatesComponent {
  currentTab: string = 'bookmarked';
  searchFormCandidate!: UntypedFormGroup;
  searchFormBookmark!: UntypedFormGroup;
  constructor(
    public candidateMgr: CandidatesManagerService,
    private fb: UntypedFormBuilder,
    private router: Router
  ) {}

  loadMoreCandidate(): void {
    this.candidateMgr.page++;
    this.candidateMgr.getCandidates(this.candidateMgr.page, '');
  }
  loadMoreBookmarked(): void {
    this.candidateMgr.pageBookmarks++;
    this.candidateMgr.getBookmarksCandidates(
      this.candidateMgr.pageBookmarks,
      ''
    );
  }
  submitSearchCandidates(): void {
    this.candidateMgr.searchCandidates = this.searchFormCandidate.value.search;
    this.candidateMgr.getCandidatesSearch(
      1,
      this.candidateMgr.searchCandidates
    );
  }
  onSearchInputCandidates(): void {
    this.submitSearchCandidates();
  }
  submitSearchBookmark(): void {
    this.candidateMgr.searchBookmarks = this.searchFormBookmark.value.search;
    this.candidateMgr.getBookmarksCandidatesSearch(
      1,
      this.candidateMgr.searchBookmarks
    );
  }
  onSearchInputBookmark(): void {
    this.submitSearchBookmark();
  }
  selectedCandidate(user: any) {
    this.candidateMgr.selectedCandidate = user;
  }
  goToCandidatePage(id: any) {
    id && this.router.navigate(['candidates', id]);
  }
  goToTab(tab: string): void {
    this.currentTab = tab;
    this.candidateMgr.page = 1;
    this.candidateMgr.pageBookmarks = 1;
    this.candidateMgr.getCandidatesSearch(1, '');
    this.candidateMgr.getBookmarksCandidatesSearch(1, '');
  }
  bookmarkCandidate(id: string, isBookmarked: boolean): void {
    if (isBookmarked === true) {
      this.candidateMgr.removeBookmarkCandidate(id);
    } else {
      this.candidateMgr.addBookmarkCandidate(id);
    }
  }
  removeBookmarked(id: string, isBookmarked: boolean): void {
    this.candidateMgr.removeBookmarked(id);
  }
  ngOnInit(): void {
    this.candidateMgr.page = 1;
    this.candidateMgr.pageBookmarks = 1;
    this.candidateMgr.getCandidatesSearch(1, '');
    this.candidateMgr.getBookmarksCandidatesSearch(1, '');
    this.searchFormCandidate = this.fb.group({
      search: [''],
    });
    this.searchFormBookmark = this.fb.group({
      search: [''],
    });
  }
  ngOnDestroy() {
    this.candidateMgr.page = 1;
    this.candidateMgr.pageBookmarks = 1;
  }
}
