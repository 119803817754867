import { Component } from '@angular/core';
import { UsersManagerService } from 'src/app/Managers/users-manager.service';

@Component({
  selector: 'app-candidate-my-profile',
  templateUrl: './candidate-my-profile.component.html',
  styleUrl: './candidate-my-profile.component.scss',
})
export class CandidateMyProfileComponent {
  constructor(public userMgr: UsersManagerService) {}
  user: any;
  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('user') || '{}');
    this.userMgr.getMyCandidateProfile();
  }
}
