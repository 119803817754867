import { Component } from '@angular/core';
import { IntegrationsManagerService } from 'src/app/Managers/integrations-manager.service';
import { AuthenticationService } from 'src/app/Services/authentication.service';

@Component({
  selector: 'app-job-processing',
  templateUrl: './job-processing.component.html',
  styleUrl: './job-processing.component.scss',
})
export class JobProcessingComponent {
  constructor(
    public integrationsMgr: IntegrationsManagerService,
    public auth: AuthenticationService
  ) {}
  toggleSubmenu() {
    const bodyTag = document.body;
    bodyTag.classList.toggle('is-submenu');
    this.auth.isSubmenu = !this.auth.isSubmenu;
  }
  changeJobProcessingSettings(item: any): void {
    const formData: any = {
      auto_job_processing: item,
    };

    this.integrationsMgr.updateJobProcessingSettings(formData);
  }
  ngOnInit(): void {
    this.integrationsMgr.getJobProcessingSettings();
  }
}
