<div class="add-comment">
    <form nz-form [formGroup]="form" nzLayout="vertical" (keydown.enter)="onEnterKey($event)" (ngSubmit)="addComment()">
        <nz-form-control nzErrorTip="">

            <textarea nz-input placeholder="Add New Comment" formControlName="comment"></textarea>

        </nz-form-control>
    </form>

    <div class="error" *ngIf="getFormControl('comment')?.dirty && getFormControl('comment')?.hasError('maxlength')">
        Maximum character limit exceeded. Please shorten your input!</div>

</div>
<div class="comments">
    <nz-spin [nzSpinning]="this.commentMgr.isLoading">
        <ul>
            <li *ngFor="let item of commentMgr.commentsApplications">
                <div class="comment-header">
                    <div class="status new"></div>
                    <div class="user">{{item.posted_by.first_name}} {{item.posted_by.last_name}}</div>
                    <div class="date">{{item.created_date | dateAgo}}</div>
                </div>
                <div class="comment-body">
                    {{item.comment}}
                </div>
            </li>
        </ul>
    </nz-spin>
</div>