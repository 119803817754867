import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class CommentsService {
  constructor(private http: HttpClient) {}

  getComments(jobObjectId: string) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.get(
      environment.apiURL + `deincore/partner/jobs/${jobObjectId}/comment/`,
      {
        headers: header,
      }
    );
  }

  addComment(comment: any, jobObjectId: string) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.post(
      environment.apiURL + `deincore/partner/jobs/${jobObjectId}/comment/`,
      comment,
      {
        headers: header,
      }
    );
  }
  getCommentsApplications(jobObjectId: string) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.get(
      environment.apiURL +
        `deincore/partner/applications/${jobObjectId}/comment/`,
      {
        headers: header,
      }
    );
  }
  addCommentApplications(comment: any, jobObjectId: string) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.post(
      environment.apiURL +
        `deincore/partner/applications/${jobObjectId}/comment/`,
      comment,
      {
        headers: header,
      }
    );
  }
  getCommentsCandidates(jobObjectId: string) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.get(
      environment.apiURL + `deincore/partner/candidate/${jobObjectId}/comment/`,
      {
        headers: header,
      }
    );
  }
  addCommentCandidates(comment: any, jobObjectId: string) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.post(
      environment.apiURL + `deincore/partner/candidate/${jobObjectId}/comment/`,
      comment,
      {
        headers: header,
      }
    );
  }
}
