import { Component } from '@angular/core';
import { SjmManagerService } from 'src/app/Managers/sjm-manager.service';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';

@Component({
  selector: 'app-application-materials',
  templateUrl: './application-materials.component.html',
  styleUrl: './application-materials.component.scss',
})
export class ApplicationMaterialsComponent {
  form!: FormGroup;
  checked: boolean = true;
  isDisabled: boolean = true;
  constructor(private fb: FormBuilder, public sjmMgr: SjmManagerService) {}
  initMaterials(): FormGroup[] {
    return this.sjmMgr.jobDetailSJMData.materials.map((material) =>
      this.fb.group({
        is_display: [material.is_display],
        is_mandatory: [material.is_mandatory],
        material_name: [material.material_name],
      })
    );
  }

  initLinks(): FormGroup[] {
    return this.sjmMgr.jobDetailSJMData.links.map((link) =>
      this.fb.group({
        is_display: [link.is_display],
        is_mandatory: [link.is_mandatory],
        link_name: [link.link_name],
      })
    );
  }
  createMaterial(material: {
    is_display: any;
    material_name: any;
    is_mandatory: any;
  }): FormGroup {
    return this.fb.group({
      is_display: [material.is_display],
      material_name: [material.material_name, Validators.required],
      is_mandatory: [material.is_mandatory],
      is_custom: [true],
    });
  }

  createLink(link: {
    is_display: any;
    link_name: any;
    is_mandatory: any;
  }): FormGroup {
    return this.fb.group({
      is_display: [link.is_display],
      link_name: [link.link_name, Validators.required],
      is_mandatory: [link.is_mandatory],
      is_custom: [true],
    });
  }

  get materials(): FormArray {
    return this.form.get('materials') as FormArray;
  }

  get links(): FormArray {
    return this.form.get('links') as FormArray;
  }

  getMaterialFormGroup(index: number): FormGroup {
    return this.materials.at(index) as FormGroup;
  }

  getLinkFormGroup(index: number): FormGroup {
    return this.links.at(index) as FormGroup;
  }

  addCustomMaterial(): void {
    this.materials.push(
      this.createMaterial({
        is_display: true,
        material_name: '',
        is_mandatory: false,
      })
    );
  }

  removeCustomMaterial(index: number): void {
    this.materials.removeAt(index);
  }

  addLink(): void {
    this.links.push(
      this.createLink({ is_display: true, link_name: '', is_mandatory: false })
    );
  }

  removeLink(index: number): void {
    this.links.removeAt(index);
  }

  submitForm(): void {
    if (this.form.valid) {
      console.log(this.form.value);
    }
  }
  async ngOnInit() {
    await this.sjmMgr.getMaterialAndLinks();
    this.form = this.fb.group({
      materials: this.fb.array(this.initMaterials()),
      links: this.fb.array(this.initLinks()),
    });
  }
}
