<div class="page">
    <div class="contianer">
        <div class="page-header">
            <div>
                <h1>Terms of Service Clara</h1>
                <p>Effective: February 26, 2024</p>
            </div>


        </div>
    </div>
    <div class="page-body">
        <div class="contianer">
            <p>Hello and welcome to DEI Network’s “CLARA”! CLARA is an artificial intelligence-powered forum and website
                (together, the
                “Platform”) facilitating connections among individuals seeking employment or information related to
                employment or a job
                search (“Applicants”) and organizations that are seeking to provide information regarding job openings
                or to
                search for
                potential employees (“Employers”). Applicants, Employers, and other users of the Platform are referred
                to
                collectively
                as “Users.”</p>

            <p>These Terms of Service (these “Terms”) constitute a binding agreement between you (“you” or “your”) and
                DEI
                Network,
                Inc. (“DEI Network,” “we,” or “us”). These Terms, including the Privacy Notice, are deemed accepted by
                you
                each time
                that you access or use the DEI Network Platform, including via our website or any mobile application,
                and
                any related
                functionality or services that we make available (the “Platform Services”). By accessing or using the
                Platform Services,
                you agree to comply with these Terms. If you do not accept the Terms stated here, do not use the
                Platform
                Services.</p>

            <p>These terms may have changed since your last visit. We reserve the right to revise these Terms in our
                sole
                discretion at
                any time and without prior notice to you other than by posting the revised Terms on our website or via
                the
                Platform.
                Revisions to the Terms are effective upon posting. The Terms will be identified as of the most recent
                date
                of revision.
                Your continued use of our Platform Services after a revised version of these Terms has been posted on
                our
                website
                constitutes your binding acceptance of the revised Terms.</p>

            <p>IF YOU DO NOT AGREE TO THESE TERMS, YOU MAY NOT ACCESS OR OTHERWISE USE THE DEI NETWORK PLATFORM
                SERVICES. BY
                USING THE
                DEI NETWORK PLATFORM SERVICES, INCLUDING OUR WEBSITE OR MOBILE APPLICATIONS, YOU REPRESENT TO US THAT
                YOU
                ARE AT LEAST
                EIGHTEEN (18) YEARS OF AGE AND THAT YOU CAN FORM LEGALLY BINDING CONTRACTS UNDER APPLICABLE LAW, OR THAT
                YOU
                ARE AT
                LEAST THIRTEEN (13) YEARS OF AGE AND ARE USING OUR SERVICES UNDER THE SUPERVISION OF A PARENT OR LEGAL
                GUARDIAN WHO
                AGREES TO BE BOUND BY THESE TERMS. IF YOU ARE A PARENT OR LEGAL GUARDIAN OF A USER UNDER THE AGE OF
                EIGHTEEN
                (18), YOU
                AGREE TO BE FULLY RESPONSIBLE FOR THE ACTS OR OMISSIONS OF SUCH USER IN CONNECTION WITH THE DEI NETWORK
                PLATFORM
                SERVICES.</p>

            <p>PLEASE READ THESE TERMS CAREFULLY, AS THEY CONTAIN AN AGREEMENT TO ARBITRATE, WHICH REQUIRES THAT YOU AND
                COMPANY
                ARBITRATE CERTAIN CLAIMS BY BINDING, INDIVIDUAL ARBITRATION INSTEAD OF GOING TO COURT, AND LIMITS CLASS
                ACTION CLAIMS,
                UNLESS YOU OPT OUT OF THE AGREEMENT TO ARBITRATE AS DESCRIBED IN SECTION 10(D) OF THESE TERMS.</p>

            <p><b>1. Account Information; Communications.</b></p>
            <p>(a) <b>Login Credentials.</b> In order to use the features available on the Platform Services, you may be
                required to create an
                account by providing certain information (each, an “Account”). We may ask you to complete a registration
                form and create
                a username and password, or we may permit you to log in through a third-party application (your
                username,
                password for
                us, or for any third-party application, your “Login Credentials”). Any loss or compromise of the
                foregoing
                information
                and/or your personal information may result in unauthorized access to your account by third parties. You
                are
                responsible
                for protecting your Login Credentials from unauthorized use, and you are responsible for all activity
                that
                occurs on
                your Account (including without limitation any financial obligations). You agree to notify us
                immediately if
                you believe
                that your Login Credentials have been or may be used without your permission so that appropriate action
                can
                be taken. We
                are not responsible for losses or damage caused by your failure to safeguard your Login Credentials.</p>

            <p> <b>(b) Account Information.</b> During your registration, you will give truthful information about
                yourself
                (such as name,
                email address, physical address, phone number, and payment account information) (collectively, together
                with
                the
                additional information referenced in Section 1(a), your “Account Information”). You represent, warrant,
                and
                covenant to
                us that your Account Information is and will remain accurate and up-to-date, and you understand that you
                are
                responsible
                for ensuring that your Account Information is accurate and for keeping your Account Information up to
                date.
                We may
                contact you to verify your Account Information and may require you to provide additional information for
                purposes of
                fraud prevention and verifying your Account Information. We may suspend you from our Platform Services
                if
                you do not
                provide such information within a reasonable period.</p>
            <p> <b>(c) Acknowledgement for You to Receive Communications.</b> You hereby agree: (i) to receive
                communications, including
                emails, text messages, push notifications, mail, and telephone calls, that are related to our Platform
                Services; that
                any communications from us may also include marketing materials from us or from third parties; and that
                any
                notices,
                agreements, disclosures or other communications that we send to you electronically are deemed to satisfy
                any
                legal
                communication requirements. You may opt-out from receiving our communications by emailing
                support&#64;DEIN-info.com or
                selecting to unsubscribe as may be provided in the applicable correspondence. In addition, if you create
                an
                Account, we
                may allow you to opt in to receive certain communications regarding certain Account activity (any such
                settings,
                “Notification Settings”). You may change any Notification Settings through your Account at any time.</p>
            <p> 2. Our Platform Services; Fees. Our Platform Services provide a marketplace for connecting Applicants to
                Employers. The
                Platform Services may enable Users to connect and interact with other Users through community features,
                chat
                functions,
                and social media.</p>
            <p> <b>(a) Communications and Content on the Platform Services.</b> Our Platform Services may allow you and
                other Users to upload,
                download, view, or use certain designs, text, graphics, images, video, data, information, logos, icons,
                software, audio
                files, or other content (collectively, “Content”). You understand that we are not responsible for any
                such
                Content,
                including without limitation the content of your communications through the Platform Services or any
                other
                messaging,
                audio or video-conferencing, or file-sharing tools that we may from time to time make available on our
                Platform
                Services.</p>

            <p> <b>(b) Fees.</b> We do not currently anticipate charging fees for your use of our Platform Services,
                other
                than fees we may
                charge to Employers based on successful placement of Applicants. However, we reserve the right to do so
                in
                the future.
                We will provide notice on our Platform Services before we charge such fees. If you continue to use our
                Platform Services
                after we provide such notice, you agree to pay the fees described on our Platform Services.</p>

            <p> <b> (c) Payments.</b> Any required cash or credit payment processing for the Platform Services will be
                provided by such
                third-party payment processor as we may utilize from time to time (the “Payment Processor”). DEI Network
                does not
                collect or store your credit card information. You can find out more about both our privacy practices in
                our
                Privacy
                Policy. By providing a credit card or other payment method accepted by DEI Network, you represent and
                warrant that you
                are authorized to use the designated payment method. If the payment method you provide cannot be
                verified,
                is invalid,
                or is otherwise not acceptable, your account may be suspended or canceled. You must resolve any problem
                we
                or our
                Payment Processor encounter in order to proceed with your use of your account. By providing your payment
                information,
                you agree to pay us (and authorize our Payment Processor to charge you) the prices as displayed to you
                at
                the time you
                create your Account and as may be modified from time to time as described in this Agreement, as well as
                any
                other fees
                you expressly choose to incur in connection with your purchase. Your use of the Platform Services may be
                suspended if we
                are unable to charge your payment instrument for any reason or if your account is otherwise past due.
                The
                prices
                applicable to your account may be subject to modification from time to time pursuant to notice (which
                may be
                given via
                e-mail) provided by us at least thirty (30) days in advance of the payment date for which the
                modification
                would be
                effective. You may at any time cancel your purchase if you do not agree to any modified prices. All
                prices
                must be paid
                in U.S. dollars (or such other currency(ies) which may be accepted by DEI Network from time to time, as
                indicated at the
                time of payment) and are non-refundable.</p>

            <p> <b> (d) Refunds.</b> Other than as may be expressly set forth on our Platform Services as updated from
                time
                to time, we have no
                obligation to provide refunds or credits but may grant them in certain circumstances at our sole
                discretion.
            </p>

            <p> <b> (e) Taxes.</b></p>

            <p> <b> 1. Tax Liability.</b> You are solely responsible for understanding and evaluating any tax liability
                related to actions on
                the Platform Services, including without limitation, any monetization earned from our Platform Services,
                as
                well as
                paying any such tax liability to the applicable governmental authorities. You are solely responsible for
                determining the
                need to report any such sales or services pursuant to the requirements of local, state, or federal law.
                DEI
                Network
                cannot and does not offer tax advice to Users nor does DEI Network provide any tax documentation to
                Users;
                DEI Network
                recommends that you consult with a tax advisor for such advice and documentation. You are solely
                responsible
                for any
                taxes arising from your use of our Platform Services, any Content contained therein, and for the
                Platform
                Services that
                you request or perform therein, excluding our income.</p>

            <p> <b> 2. Compliance with Tax Laws.</b> You will comply with all applicable state, federal, and
                international
                laws, and assume all
                responsibility for making payments related to any state or federal income taxes, payroll and withholding
                taxes, social
                security taxes, unemployment insurance taxes, Medicare taxes, disability insurance or taxes, or worker’s
                compensation
                insurance or taxes. DEI Network disclaims any responsibility for the foregoing, and you agree to
                indemnify
                and hold DEI
                Network harmless against such taxes or contributions. As used herein, “taxes” will mean all taxes,
                charges,
                fees,
                encumbrances, liens, customs, duties, or other assessments, however, denominated, including any
                interest,
                penalties,
                additions to tax, or additional taxes that may become payable in respect thereof, imposed by the United
                States
                government, any state, local or foreign government, or any agency or political subdivision of any such
                government.</p>

            <p> <b> (f) Referrals.</b> DEI Network may engage distributors or other individuals or entities who may have
                been compensated or
                incentivized to speak on our behalf and/or refer you to our Platform Services. If you receive a referral
                from a
                third-party source, note that such an individual or entity may have been compensated by us for his or
                her
                statements.
                3. Scope of Service; Modifying and Terminating the Platform Services.</p>

            <p> <b> (a) Modification of Platform Services.</b> We may change and update our Platform Services at any
                time.
                We may add or remove
                features including without limitation converting free features into paid features and vice versa. We
                will
                endeavor to
                give you appropriate advance notice about any major changes, although you understand that we may stop,
                suspend, or
                change our Platform Services at any time without prior notice. If you do not like our Platform Services
                or
                these Terms
                or would like to provide constructive feedback, please let us know by contacting us at
                support&#64;DEIN-info.com. We do not
                promise to make any changes that you suggest, and your sole remedy if you are dissatisfied with our
                Platform
                Services or
                these Terms is that you may discontinue your use of our Platform Services.</p>

            <p> <b> (b) Suspension of Platform Services.</b> We may alter, suspend, or discontinue our Platform Services
                in
                whole or in part, at
                any time and for any reason, without notice. Our Platform Services may also periodically become
                unavailable
                due to
                maintenance or malfunction of computer equipment or for other reasons. In order to use our Platform
                Services, you must
                have a computer with Internet access that can access our website or a compatible mobile device enabled
                with
                any mobile
                application we may provide.</p>
            <p> <b> (c) Termination by Us.</b> We may terminate your access to our Platform Services, in our sole
                discretion, for any reason and
                at any time. If you have provided us with your email address, we will endeavor to provide electronic
                notice
                to you at
                such an email address. You agree that we are not liable to you or any third party for any termination of
                your access to
                our Platform Services.</p>
            <p> <b>(d) Termination by You.</b> You may terminate these Terms at any time by ceasing to use our Platform
                Services and, if
                applicable, by closing your Account. We may provide instructions on our Platform Services for how to
                close
                your Account
                and may update such instructions from time to time. Please follow such instructions if you would like to
                close your
                Account.</p>

            <p> <b> (e) Telecommunications.</b> When using our Platform Services, your telecommunications carrier’s
                normal
                rates and charges
                apply. Unless otherwise set forth in these Terms, we are not responsible for any charges you incur from
                your
                telecommunications carrier or otherwise as a result of use of our Platform Services. You are responsible
                for
                ensuring
                that, at all times while using our Platform Services, you are not in violation of any agreement with
                your
                telecommunications carrier.</p>

            <p> <b>(f) Survival of Terms.</b> The following Sections of these Terms and any accrued obligations will
                survive
                any termination of
                these Terms: 2, 3, 4, 5, and 7-10.</p>

            <p> <b> 4. Intellectual Property; Licenses; Content; Individual Data.</b></p>
            <p> <b>(a) Content, Individual Data, Suggestions.</b></p>

            <p> <b> i. Our Content.</b> Subject to these Terms and any other agreement between you and us, we hereby
                grant
                you a limited,
                personal, non-transferable, non-exclusive, non-sublicensable, revocable license to access and use
                Content
                that we make
                available through our Platform Services, solely for your personal and non-commercial use, and subject to
                any
                restrictions on certain types of Content set forth in these Terms. You understand that the Content that
                is
                posted on our
                Platform Services is used by you at your own risk.</p>

            <p> <b> ii. Changes to Content.</b> We reserve the right to make changes to any Content or descriptions of
                our
                Platform Services
                without obligation to issue any notice of such changes.</p>

            <p> <b> iii. License to User Content.</b> You hereby grant to us a royalty-free, nonexclusive, irrevocable,
                limited right and
                license to copy, modify, translate, reformat and otherwise use any Content that you upload to or provide
                through the
                Platform Services (your “User Content”): (a) in order to provide the Platform Services, including to
                make
                your User
                Content available, as applicable, to other Users in their capacity as Applicants or Employers in
                connection
                with job
                listings; (b) to analyze and improve the Platform Services; (c) to compile, use and disclose aggregate
                or
                de-identified
                data, statistics, measurements or other metrics derived from your User Content (including in combination
                with the
                aggregate or de-identified customer data of other Users) for its own purposes such that the aggregate or
                de-identified
                data does not identify you or any other individual; and (d) without limiting the foregoing (a), (b), and
                (c), to improve
                the Platform Services through the application of machine learning, tuning or any enhancement,
                modification,
                derivative
                or other improvement to the Platform in connection with the provision of any of the Platform Services
                (“AI
                Improvements”) and to exploit such AI Improvements without limitation. You agree DEI Network may extract
                and
                retain
                patterns from your User Content processed in connection with your use of the Platform Services which do
                not
                identify you
                and do not enable reconstruction of your User Content (“Patterns”) and use, copy, prepare derivative
                works
                of or
                otherwise exploit any such Pattern to continue to improve our Platform Services in perpetuity. Any AI
                Improvement
                arising from the Services will be the sole and exclusive property of DEI Network.</p>

            <p> <b>iv. Disclosure of Your User Content.</b> DEI Network will not disclose your User Content to third
                parties, except as
                permitted under these Terms or under the Privacy Policy.</p>

            <p> <b>v. Your Responsibility for User Content.</b> You are solely responsible for all of your User Content.
                You
                represent and
                warrant that you own all your User Content or you have all rights that are necessary to grant us the
                license
                rights in
                your User Content under these Terms. You also represent and warrant that neither your User Content, nor
                your
                use and
                provision of your User Content to be made available through our Platform Services, nor any use of your
                User
                Content by
                DEI Network on or through our Platform Services, will infringe, misappropriate or violate a third
                party’s
                intellectual
                property rights, or rights of publicity or privacy, or result in the violation of any applicable law or
                regulation.</p>

            <p> <b>vi. Unsolicited Feedback.</b> We welcome your feedback, ideas, and suggestions (collectively,
                “Suggestions”). If you send us
                any Suggestions, including as described under Section 5(a) of these Terms, you agree that: (A) your
                Suggestion(s) become
                our property and you are not owed any compensation in exchange; (B) none of the Suggestion(s) contain
                confidential or
                proprietary information of any third party; (C) we may use or redistribute Suggestion(s) for any purpose
                and
                in any way;
                (D) there is no obligation for us to review your Suggestion(s); and (E) we have no obligation to keep
                any
                Suggestions
                confidential.</p>

            <p> <b> (b) Applications; License to Use.</b></p>
            <p> <b>i. License to Use.</b> Subject to these Terms and any other agreement between you and us, we grant to
                you
                a limited,
                personal, non-exclusive, non-transferable, non-sublicensable, revocable license to access and use the
                Platform Services
                and to install and use any application we may offer on our website or a compatible mobile device for
                your
                personal,
                non-commercial purposes and use of our Platform Services, in each case, solely in the manner enabled by
                us.
                ii. Limitations; Revocation. Your license to use our Platform Services is automatically revoked if you
                violate these
                Terms. From time to time, we may upgrade our Platform Services or make improvements to our Platform
                Services. You agree
                that these Terms will apply to all such upgrades or improvements. The foregoing license grant is not a
                sale
                of any
                mobile application we may provide or the website or a sale of a copy of any such application or our
                website,
                and we
                retain all rights and interest in our Platform Services. Any attempt by you to transfer any of the
                rights,
                duties, or
                obligations hereunder, except as expressly provided for in these Terms, is void. We reserve all rights
                not
                expressly
                granted under these Terms.</p>
            <p> <b>(c) No Implied Licenses.</b> Nothing contained on our Platform Services should be construed as
                granting,
                by implication,
                estoppel, or otherwise, any license or right to use our Platform Services or any Content, through the
                use of
                framing or
                otherwise, except: (i) as expressly permitted by these Terms; or (ii) with our prior written permission
                or
                the written
                permission of the third party that may own the trademark or copyright of material displayed on our
                Platform
                Services.</p>

            <p> <b>(d) Copyright Infringement; DMCA Policy.</b> If you believe that any materials on our Platform
                Services
                infringe your
                copyright, you may request that such materials be removed. This request must bear a signature (or
                electronic
                equivalent)
                of the copyright holder or an agent and must include the following: (i) identification of the
                copyrighted
                work that you
                believe to be infringed, including a description of the work and, where possible, a copy or the location
                of
                an
                authorized version of the work; (ii) identification of the material that you believe to be infringing,
                including a
                description of the material, and its location on our website; (iii) your name, address, telephone number
                and
                email
                address; (iv) a statement that you have a good faith belief that the complained of use of the materials
                is
                not
                authorized by the copyright owner, its agent or the law; (v) a statement that the information in your
                claim
                is accurate;
                and (vi) a statement that “under penalty of perjury,” you declare that you are the lawful copyright
                owner or
                are
                authorized to act on the owner’s behalf. Our agent for copyright issues relating to our Platform
                Services is
                Copyright
                Agent, Natasha Nuytten at copyright&#64;dein-info.com or copyright&#64;getclara.io. To protect the
                rights of
                copyright owners,
                we reserve the right to suspend your Account and/or other any User privileges, delete or disable content
                alleged to be
                infringing and/or terminate the Account and/or other User privileges of a repeat infringer.</p>

            <p> <b> (e) Privacy Policy.</b> Our Privacy Policy describes the collection, use, and disclosure of data by
                us
                in connection with
                our Platform Services. Our Privacy Policy, as may be updated by us from time to time in accordance with
                its
                terms, is
                hereby incorporated into these Terms, and you hereby agree to the collection, use, and disclosure
                practices
                set forth
                therein.</p>
            <p> <b> (f) Security.</b> You acknowledge that our Platform Services uses the Internet for data transfer and
                Internet-connected
                servers to store Content and Individual Data. While we use commercially reasonable security measures for
                such servers,
                no security measures are entirely effective and Internet communications may have inherent insecurities.
                As
                such, we make
                no representations or warranties regarding the security offered in respect of our Platform Services.</p>

            <p> <b> 5. Your Use; Prohibited Conduct.</b></p>
            <p> <b> (a) General.</b> As a condition of your use of our Platform Services, you will not use our Platform
                Services for any purpose
                that is unlawful or otherwise prohibited by these Terms. You further agree to comply with any other
                applicable terms and
                conditions of use set forth on our Platform Services. We reserve the right, without prior notice to you
                and
                in our sole
                discretion, to terminate your access to our Platform Services if we decide that your use violates these
                Terms, including
                for the reasons listed in this Section 5, or for any other reason.</p>

            <p> <b>(b) Prohibited Use; Unauthorized Access.</b> You agree not to, and will not permit any person or
                entity to: (i) use, or
                allow the use of, our Platform Services for any unfair or deceptive practices or in contravention of any
                federal, state,
                local, foreign or other applicable law or rules and regulations of regulatory or administrative
                organizations;
                (ii) act
                in a fraudulent, tortious, malicious or negligent manner when using our Platform Services; (iii) act in
                any
                manner that,
                in our sole discretion, could damage, disable, overburden, impair or interfere with any other party’s
                use of
                our
                Platform Services; (iv) obtain or attempt to obtain any information through any means not intentionally
                made
                available
                through our Platform Services; (v) obtain unauthorized access to any computer system through our
                Platform
                Services; (vi)
                circumvent, remove or otherwise interfere with any security-related features of our Platform Services,
                features
                that
                prevent copying or using any part of our Platform Services or features that enforce limitations on the
                use
                of
                our
                Platform Services or any Content; (vii) introduce viruses, worms, Trojan horses and/or harmful code to
                our
                Platform
                Services; and (viii) use any robot, spider, site search/retrieval application or other automated device,
                process
                or
                means to access, retrieve, scrape or index any portion of our Platform Services or any Content. In the
                event
                that you
                gain access to information not intended to be accessed by you, you agree that you will immediately
                notify us
                and
                destroy
                all copies of such information in your possession.</p>

            <p> <b> (c) Prohibited Content and User Activity.</b> You agree that you will not, and will not authorize or
                facilitate any attempt
                by another person or organization to use our Platform Services to: (i) transmit any Content that is
                unlawful, harmful,
                threatening, abusive, harassing, defamatory, vulgar, offensive, obscene, pornographic, lewd, lascivious
                or
                otherwise
                objectionable, as determined by us; (ii) use a name or language that we, in our sole discretion, deem
                offensive; (iii)
                post defamatory statements; (iv) post hateful or offensive Content or Content that disparages any
                ethnic,
                racial,
                sexual, gender, religious or other group; (v) post Content that depicts or advocates the use of illegal
                drugs; (vi) post
                Content that characterizes violence as acceptable, glamorous or desirable; (vii) post Content which
                infringes another’s
                copyright, trademark or trade secret; (viii) post unsolicited advertising or unlawfully promote products
                or
                services;
                (ix) harass, threaten, bully, stalk or intentionally embarrass or cause distress to another person or
                entity; (x)
                promote, solicit or participate in any multi-level marketing or pyramid schemes; (xi) exploit children
                under
                18 years of
                age; (xii) engage in disruptive activity, such as sending multiple messages in an effort to monopolize a
                forum; (xiii)
                invade the privacy of any person, including without limitation posting personally identifying or
                otherwise
                private
                information about a person without their consent (or their parent’s consent in the case of a child under
                13
                years of
                age); (xiv) solicit personal information from children under 13 years of age; (xv) create a false
                identity
                or
                impersonate another person or entity; or (xvi) encourage conduct that would constitute a criminal or
                civil
                offense. We
                reserve the right to consider other conduct to be prohibited. In addition, you acknowledge and agree
                that
                you will not
                post any content to any of our social media accounts that is any of items (i)–(xvi) above.</p>
            <p> <b> (d) Employment Decisions; Job Listings; Interaction with other Users.</b> Employers are solely
                responsible for their
                postings and other job information that they upload or provide through the Platform Services. DEI
                Network is
                not an
                employer with respect to your use of any Platform Services and DEI Network will not be responsible for
                any
                employment
                decisions, for whatever reason, made by any entity posting jobs or otherwise using any Platform
                Services.
                DEI Network
                does not screen or censor the listings, including profiles offered. DEI Network is not involved in, and
                does
                not
                control, the actual transaction between Employers and Applicants. As a result, DEI Network is not
                responsible for User
                Content, the quality, safety or legality of the jobs or resumes posted, the truth or accuracy of the
                listings, the
                ability of Employers to offer job opportunities to Applicants or the ability of Applicants to fill job
                openings and DEI
                Network makes no representations about any jobs, resumes or User Content on or available through the
                Platform Services.
                While DEI Network reserves the right in its sole discretion to remove User Content, job postings,
                resumes or
                other
                material from the Platform Services from time to time, DEI Network does not assume any obligation to do
                so
                and to the
                extent permitted by law, disclaims any liability for failing to take any such action. You assume all
                risks
                associated
                with dealing with other users with whom you come in contact through the Platform Services. Because User
                authentication
                on the Internet is difficult, DEI Network cannot and does not confirm that each User is who they claim
                to
                be. Since we
                do not and cannot be involved in User-to-User dealings or control the behavior of participants on any
                Platform Services,
                in the event that you have a dispute with one or more Users, you release DEI Network (and our agents and
                employees) from
                claims, demands and damages (actual and consequential and direct and indirect) of every kind and nature,
                known and
                unknown, suspected and unsuspected, disclosed and undisclosed, arising out of or in any way connected
                with
                such disputes
                to the fullest extent permitted by law.</p>
            <p> <b> (e) Intellectual Property Infringement.</b> You agree that the structure, organization and code used
                in
                conjunction with our
                Platform Services are proprietary to us. You will not, and will not permit any person or entity to: (i)
                use
                our Platform
                Services on a service bureau, time sharing or any similar basis, or otherwise for the benefit of any
                other
                person or
                entity; (ii) alter, enhance, or make derivative works of our Platform Services or any Content available
                through the
                foregoing; (iii) reverse engineer, reverse assemble or decompile, or otherwise attempt to derive source
                code
                from our
                Platform Services; or (iv) sell, transfer, publish, disclose, display or otherwise make available our
                Platform Services
                including any modifications, enhancements, derivatives and other software and materials provided
                hereunder
                by us or
                copies thereof to others in violation of these Terms. Unless as otherwise set forth by us in writing,
                you
                understand and
                acknowledge that all Content contained on our Platform Services is the property of us and/or our
                affiliates
                or licensors
                and is protected from unauthorized copying and dissemination by United States copyright law, trademark
                law,
                international conventions, and other intellectual property laws. Product names are trademarks or
                registered
                trademarks
                of their respective owners.</p>
            <p> <b> 6. Third Party Services.</b></p>
            <p> <b> (a) Third-Party Service.</b> Our Platform Services may include features or functionalities that
                interoperate with services
                operated by third parties, which may be pursuant to a generally available application programming
                interface
                made
                available by such a third party or pursuant to an agreement that we have with such a third party. We
                have no
                control
                over any features or functionalities offered by any third party, and those features or functionalities
                may
                be modified,
                suspended or terminated at any time with no notice.</p>
            <p> <b>(b) Third-Party Links.</b> Our Platform Services may contain links to third-party sites. These links
                are
                provided to you as
                a convenience, and we are not responsible for the content of any linked third-party site. Any
                third-party
                site accessed
                from our Platform Services is independent from us, and we have no control over the content of that site.
                In
                addition, a
                link to any third-party site does not imply that we endorse or accept any responsibility for the content
                or
                use of such
                site. You understand that use of any third-party site is subject to its terms of service and privacy
                policy.
                We request
                that you exercise caution and good judgment when using third-party sites.</p>
            <p> <b> (c) Providers of Third Party Services.</b> You hereby acknowledge and agree that all of our
                licensors,
                suppliers, or other
                third parties: (i) are not parties to these Terms; (ii) have no obligation whatsoever to furnish any
                maintenance or
                support services with respect to DEI Network; (iii) are not responsible for addressing claims by you or
                any
                third party
                relating to our Platform Services, including without limitation any product liability claims, claims
                under
                consumer
                protection laws or claims under any other law, rule or regulation; and (iv) have no responsibility to
                investigate,
                defend, settle or discharge any claim that our Platform Services or use thereof infringes any third
                party
                intellectual
                property rights.</p>

            <p> <b> 7. Limitation of Liability; Disclaimers; Miscellaneous.</b></p>
            <p><b>(a) Disclaimer.</b></p>
            <p><b>i. No Warranty.</b> OUR SERVICES AND ALL CONTENT ON OR ACCESSIBLE FROM OUR SERVICES ARE PROVIDED "AS
                IS"
                WITHOUT WARRANTY OF
                ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION ANY IMPLIED WARRANTY OF
                MERCHANTABILITY,
                FITNESS FOR A
                PARTICULAR PURPOSE OR NON-INFRINGEMENT. SPECIFICALLY, BUT WITHOUT LIMITATION, WE DO NOT WARRANT THAT:
                (A)
                THE
                INFORMATION AVAILABLE THROUGH OUR SERVICES IS FREE OF ERRORS; (B) THE FUNCTIONS OR SERVICES (INCLUDING
                WITHOUT
                LIMITATION MECHANISMS FOR THE DOWNLOADING AND TRANSMITTING CONTENT) PROVIDED BY OUR SERVICES WILL BE
                UNINTERRUPTED,
                SECURE OR FREE OF ERRORS; (C) DEFECTS WILL BE CORRECTED, OR (D) THAT OUR SERVERS OR THE SERVER(S) THAT
                MAKE
                THEM
                AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.</p>
            <p> <b> ii. Waiver of Liability.</b> WE AND OUR AFFILIATES AND LICENSORS CANNOT AND DO NOT GUARANTEE THAT
                ANY
                INDIVIDUAL DATA OR
                OTHER PERSONAL INFORMATION SUPPLIED BY YOU WILL NOT BE MISAPPROPRIATED, INTERCEPTED, DELETED, DESTROYED
                OR
                USED BY
                OTHERS. Under no circumstances will we be liable for any loss or damage caused by failed delivery or
                receipt
                of Content
                or any third party’s use or distribution of Content. You hereby acknowledge and agree that DEI Network
                merely stores and
                hosts User Content but does not actively create User Content. Under no circumstances will DEI Network be
                liable for any
                claims that may arise from User Content, including without limitation claims for intellectual property
                infringement.</p>
            <p> <b> (b) Limitation of Liability.</b></p>
            <p> <b> iii. General.</b> IN NO EVENT SHALL WE BE LIABLE TO YOU, ANY OTHER USER OF OUR SERVICES, ANY THIRD
                PARTY
                PROVIDER OR ANY
                OTHER PERSON OR ENTITY FOR ANY SPECIAL, INCIDENTAL, PUNITIVE, CONSEQUENTIAL, EXEMPLARY OR OTHER INDIRECT
                DAMAGES
                (INCLUDING WITHOUT LIMITATION DAMAGES FOR LOSS OF PROFITS, LOSS OF DATA, LOSS OF USE OR COSTS OF
                OBTAINING
                SUBSTITUTE
                GOODS OR SERVICES) ARISING OUT OF THE USE, INABILITY TO USE, UNAUTHORIZED ACCESS TO OR USE OR MISUSE OF
                OUR
                SERVICES,
                YOUR INDIVIDUAL DATA, CONTENT OR ANY INFORMATION CONTAINED THEREON OR IN CONNECTION THEREWITH, WHETHER
                BASED
                UPON
                WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE, EVEN IF WE HAVE BEEN ADVISED OF THE
                POSSIBILITY OF SUCH
                DAMAGES OR LOSSES.</p>
            <p> <b> iv. Limitation.</b> OUR AGGREGATE LIABILITY TO YOU FOR ALL CLAIMS ARISING FROM THESE TERMS SHALL NOT
                EXCEED THE GREATEST OF:
                (A) $100.00; OR (B) THE AGGREGATE AMOUNT YOU HAVE PAID TO US IN FEES, IF ANY, IN THE THEN-PRIOR TWELVE
                (12)-MONTH
                PERIOD.</p>
            <p> <b> v. Exclusions.</b> SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE
                LIMITATION
                OR EXCLUSION OF
                LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS MAY NOT
                APPLY
                TO YOU BUT
                SHALL INSTEAD APPLY TO THE MAXIMUM EXTENT PERMITTED BY LAW.</p>
            <p> <b> (c) Release.</b> Our Platform Services are only a means of connecting Users, and we do not take part
                in
                the interaction
                between or among Users. As a result of our limited involvement in the actual contact between or among
                Users,
                in the
                event that you have a dispute with any Users, you hereby release us, and our officers, directors,
                employees,
                agents,
                investors, subsidiaries, and contractors from any and all claims, demands or damages (actual or
                consequential) of every
                kind and nature, known and unknown, suspected and unsuspected, disclosed and undisclosed, arising out of
                or
                in any way
                connected with such disputes. We expressly disclaim any liability or claims that may arise between or
                among
                Users of our
                Platform Services. You waive California Civil Code Section 1542, which says: “A general release does not
                extend to
                claims that the creditor or releasing party does not know or suspect to exist in his or her favor at the
                time of
                executing the release and that, if known by him or her, would have materially affected his or her
                settlement
                with the
                debtor or released party.” You hereby waive any other similar provision of applicable law that applies
                to
                you.</p>

            <p> <b> (d) Indemnification.</b> By using our Platform Services, you hereby agree to indemnify and hold
                harmless
                us and our
                officers, directors, employees and agents from any claims, damages, losses, liabilities, and all costs
                and
                expenses of
                defense (collectively, “Claims”), including without limitation attorneys' fees, resulting directly or
                indirectly from a
                claim by a third party that arises in connection with: (i) your provision of any Content, (ii) your use
                of
                our Platform
                Services and/or (iii) any user or other third party’s use of any Content that you submit via our
                Platform
                Services. At
                our option, you agree to defend us from any Claims.</p>
            <p> <b> (e) Waiver of Rights.</b> DEI Network’s failure to enforce any right or provision of these Terms
                will
                not be considered a
                waiver of such right or provision. The waiver of any such right or provision will be effective only if
                in
                writing and
                signed by a duly agent of DEI Network.</p>
            <p> <b> (f) Minors.</b> Our Platform Services is available only to, and may only be used by, individuals who
                can
                form legally
                binding contracts under applicable law or who are under the supervision of a parent or legal guardian
                who
                agrees to be
                bound by these terms and responsible for the acts or omissions of such individuals in connection with
                the
                Platform
                Services. If you are a parent or legal guardian and you discover that your child has created an
                unauthorized
                account on
                our Platform Services, please contact us at support&#64;DEIN-info.com and we will remove the account.
            </p>
            <p> <b> (g) Successors and Assigns; Binding Effect.</b> You may not assign or transfer your rights or
                obligations under these Terms
                in whole or in part to any third party without our consent. These Terms will bind and inure to the
                benefit
                of the
                parties to these Terms and their respective successors, permitted transferees, and permitted assigns.
                (h) Independent Contractor Status. We and you are independent contractors and are not partners, joint
                venturers, agents,
                employees, or representatives of each other.</p>
            <p> <b>(i) Entire Agreement; Amendment; Interpretation.</b> These Terms, including our Privacy Policy,
                contain
                the entire
                understanding of the parties with respect to the transactions and matters contemplated herein, supersede
                all
                previous
                communications, understandings, and agreements (whether oral or written) other than any click-through or
                end
                user
                license agreement provided by us, and cannot be amended except by a writing signed by both parties or by
                our
                posting of
                an amended version of these Terms on our website. The headings and captions used in these Terms are used
                for
                convenience
                only and are not to be considered in construing or interpreting these Terms. If any part of these Terms
                is
                held to be
                unlawful, void, or unenforceable, that part will be deemed severable and will not affect the validity
                and
                enforceability
                of the remaining provisions.</p>
            <p> <b> 8. Jurisdictional Issues.</b> Although our Platform Services may be used in various countries, we
                make
                no representation
                that information on our Platform Services is appropriate or available for use outside of the United
                States
                of America or
                the European Union. Those who choose to access our Platform Services from outside such markets do so on
                their own
                initiative and at their own risk and are responsible for compliance with applicable local laws. By using
                our
                Platform
                Services, you consent to having your Login Credentials and any personal information that you provide to
                us
                transferred
                to and processed in the United States of America subject to the restrictions on such data as provided in
                our
                Privacy
                Policy.</p>
            <p> <b> 9. Governing Law; Dispute Resolution.</b> These Terms, and any dispute between you and us, will be
                governed by the laws of
                the State of Delaware, United States of America, without regard to principles of conflicts of law that
                would
                result in
                the application of the law of any other jurisdiction, except that the Federal Arbitration Act will
                govern
                the
                interpretation and enforcement of the arbitration provisions set forth below. Unless you and we agree
                otherwise, in the
                event that this Section 9 is found not to apply to you or to a particular claim or dispute, either as a
                result of your
                decision to opt out of the Arbitration Procedures or as a result of a decision by the arbitrator or a
                court
                order, you
                agree that any claim or dispute that has arisen or may arise between you and us must be resolved
                exclusively
                by a state
                or federal court located in the State of Delaware, except that you or we are permitted: (a) to bring
                small
                claims
                actions in state court in the county in which you reside if such court has a small claims procedure and
                if
                such court is
                located in the United States of America; (b) to bring claims for injunctive relief in any court having
                jurisdiction over
                the parties; or (c) to seek enforcement of a judgment in any court having jurisdiction over the parties.
                To
                the extent
                permitted by law, you and we agree to waive trial by jury in any court proceeding.</p>
            <p> <b> 10. Agreement to Arbitrate; Waiver of Class Action.</b></p>
            (a) Mandatory Arbitration of Disputes; Arbitration Procedures. Except if you opt-out or for disputes
            relating to
            your or
            our intellectual property (such as trademarks, trade dress, domain names, trade secrets, copyrights, and
            patents) or for
            items (a)–(c) set forth in Section 9 (Governing Law; Dispute Resolution), you agree that all disputes
            between
            you and us
            (whether or not such dispute involves a third party) arising out of or relating to these Terms, our Platform
            Services,
            and/or our Privacy Policy will be finally resolved by arbitration before a single arbitrator conducted in
            the
            English
            language in the State of Delaware, under the Commercial Arbitration Rules of the American Arbitration
            Association
            (“AAA”) and you and we hereby expressly waive trial by jury. You and we will appoint as sole arbitrator a
            person
            mutually agreed by you and us or, if you and we cannot agree within thirty (30) days of either party’s
            request
            for
            arbitration, such single arbitrator will be selected by the AAA upon the request of either party. The
            parties
            will bear
            equally the cost of the arbitration (except that the prevailing party will be entitled to an award of
            reasonable
            attorneys' fees incurred in connection with the arbitration in such an amount as may be determined by the
            arbitrator).
            All decisions of the arbitrator will be final and binding on both parties and enforceable in any court of
            competent
            jurisdiction. Notwithstanding the foregoing, application may be made to any court for a judicial acceptance
            of
            the award
            or order of enforcement. Under no circumstances will the arbitrator be authorized to award damages, remedies
            or
            awards
            that conflict with these Terms.
            (b) Class Action Waiver. Any claims brought by you or us must be brought in such party’s individual
            capacity,
            and not as
            a plaintiff or class member in any purported class or representative proceeding. You agree and acknowledge
            that
            neither
            you nor we will participate in a class action or class-wide arbitration for any claims covered by these
            Terms.
            You
            hereby waive any and all rights to bring any claims related to these Terms and/or our Privacy Policy as a
            plaintiff or
            class member in any purported class or representative proceeding. You understand and agree that you may
            bring
            claims
            only on your own behalf.
            (c) Opt-out. You may opt out of this Agreement to Arbitrate. If you do so, neither you nor we can require
            the
            other to
            participate in an arbitration proceeding. To opt out, you must notify us in writing within thirty (30) days
            of
            the date
            that you first became subject to this arbitration provision. The opt-out notice must state that you do not
            agree
            to the
            Agreement to Arbitrate and must include your name, address, phone number, your DEI Network account, if
            applicable, to
            which the opt-out applies and a clear statement that you want to opt out of this Agreement to Arbitrate. You
            must sign
            the opt-out notice for it to be effective. This procedure is the only way you can opt out of the Agreement
            to
            Arbitrate.
            You must use this address to opt out: DEI Network Technology, Inc.: Arbitration Opt-Out,
            myinfo&#64;getclara.io.
            (d) Effect of Changes on Arbitration. Notwithstanding any provision in these Terms to the contrary, you and
            we
            agree
            that if we make any change to the Arbitration Procedures (other than a change to any notice address or
            website
            link
            provided herein) in the future, that change will not apply to any claim that was filed in a legal proceeding
            against us
            prior to the effective date of the change. Moreover, if we seek to terminate the Arbitration Procedures from
            these
            Terms, such termination will not be effective until thirty (30) days after the version of these Terms not
            containing the
            Arbitration Procedures is posted to our website and will not be effective as to any claim that was filed in
            a
            legal
            proceeding against us prior to the effective date of removal.
            (e) Survival. This Section 10 (Agreement to Arbitrate; Waiver of Class Action) will survive the termination
            of
            your
            relationship with us.
            <p> <b> 11. For Additional Information.</b> If you have any questions about these Terms, please contact us
                at
                support&#64;DEIN-info.com.</p>

        </div>
    </div>