import {
  Component,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { SjmManagerService } from 'src/app/Managers/sjm-manager.service';

@Component({
  selector: 'app-edit-description',
  templateUrl: './edit-description.component.html',
  styleUrl: './edit-description.component.scss',
})
export class EditDescriptionComponent {
  @ViewChild('editableText')
  editableText!: ElementRef;
  constructor(public sjmMgr: SjmManagerService) {}
  editableContent: string = '';
  @Output() editDescription = new EventEmitter<string | ''>();

  onBlur(event: any): void {
    this.editableContent = this.editableText.nativeElement.innerHTML;
    this.editDescription.emit(this.editableContent);
  }
  ngOnInit() {
    this.editableContent = this.sjmMgr.description;
  }
}
