import { Component } from '@angular/core';
interface Companies {
  key: string;
  companyName: string;
  tags: string[];
}
interface CompaniesData {
  key: string;
  data: string;
  sharedData: string;
  isShared: boolean;
}
@Component({
  selector: 'app-data-sharing',
  templateUrl: './data-sharing.component.html',
  styleUrl: './data-sharing.component.scss',
})
export class DataSharingComponent {
  isVisibleProfileModal = false;

  listOfCompanies: Companies[] = [
    {
      key: '1',
      companyName: 'Company Name',
      tags: ['Data type A', 'Data type B', 'Data type C', '+4'],
    },
    {
      key: '2',
      companyName: 'Company Name',
      tags: ['Data type A', 'Data type B', 'Data type C', '+4'],
    },
    {
      key: '3',
      companyName: 'Company Name',
      tags: ['Data type A', 'Data type B', 'Data type C', '+4'],
    },
    {
      key: '4',
      companyName: 'Company Name',
      tags: ['Data type A', 'Data type B', 'Data type C', '+4'],
    },
  ];
  listOfCompaniesData: CompaniesData[] = [
    {
      key: '1',
      data: 'Data Name',
      sharedData: 'Description of data',
      isShared: true,
    },
    {
      key: '2',
      data: 'Data Name',
      sharedData: 'Description of data',
      isShared: true,
    },
    {
      key: '3',
      data: 'Data Name',
      sharedData: 'Description of data',
      isShared: true,
    },
    {
      key: '4',
      data: 'Data Name',
      sharedData: 'Description of data',
      isShared: true,
    },
  ];
  editProfileModal(): void {
    this.isVisibleProfileModal = true;
  }
  handleProfileCancel(): void {
    this.isVisibleProfileModal = false;
  }
}
