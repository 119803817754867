import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class CandidateSortingService {
  constructor(private http: HttpClient) {}

  getCandidatesSorting() {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.get(environment.apiURL + `settings/candidate-sorting/`, {
      headers: header,
    });
  }
  getCandidatesJobSorting(jobObjectId: string) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.get(
      environment.apiURL +
        `deincore/partner/job/${jobObjectId}/settings/candidate-sorting/`,
      {
        headers: header,
      }
    );
  }
  updateCandidatesSorting(formData: any) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.put(
      environment.apiURL + `settings/candidate-sorting/`,
      formData,
      {
        headers: header,
      }
    );
  }
  updateCandidatesJobSorting(formData: any, jobObjectId: string) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.post(
      environment.apiURL +
        `deincore/partner/job/${jobObjectId}/settings/candidate-sorting/`,
      formData,
      {
        headers: header,
      }
    );
  }
}
