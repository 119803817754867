import { Component, ViewChild } from '@angular/core';
import { SjmManagerService } from 'src/app/Managers/sjm-manager.service';
import { FollowUpQuestionsManagerService } from 'src/app/Managers/follow-up-questions-manager.service';
import { SJMfollowUpQuestionsComponent } from './sjmfollow-up-questions/sjmfollow-up-questions.component';
import { SJMAISettingsComponent } from './sjmaisettings/sjmaisettings.component';
import { ApplicationMaterialsComponent } from './application-materials/application-materials.component';
import { FormArray, FormGroup } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Router } from '@angular/router';

@Component({
  selector: 'app-upload-new-job',
  templateUrl: './upload-new-job.component.html',
  styleUrls: ['./upload-new-job.component.scss'],
})
export class UploadNewJobComponent {
  currentStep: number = 1;
  totalSteps: number = 5;
  selectedFile: File | null = null;
  jobTitle: string = '';
  jobLocation: string = '';
  uploadJobPayload: any;
  editDescriptionPayload: any = { title: '', description: '', location: '' };
  @ViewChild(SJMfollowUpQuestionsComponent)
  sjmFollowUpComponent!: SJMfollowUpQuestionsComponent;

  @ViewChild(SJMAISettingsComponent)
  sjmAISettingsComponent!: SJMAISettingsComponent;

  @ViewChild(ApplicationMaterialsComponent)
  ApplicationMaterialsComponent!: ApplicationMaterialsComponent;

  constructor(
    private router: Router,
    public sjmMgr: SjmManagerService,
    public followUpMgr: FollowUpQuestionsManagerService,
    private notification: NzNotificationService
  ) {}

  async nextStepUploadJob(): Promise<void> {
    if (this.currentStep < this.totalSteps) {
      // console.log('Uploading', this.uploadJobPayload);
      // return;
      await this.sjmMgr.uploadJobFile(this.uploadJobPayload);
      this.currentStep++;
    }
  }
  async editDescription(): Promise<void> {
    if (this.currentStep < this.totalSteps) {
      if (this.editDescriptionPayload.title === '') {
        this.editDescriptionPayload.title = this.jobTitle;
        this.editDescriptionPayload = {
          title: this.jobTitle,
          description: this.sjmMgr.description,
          location: this.jobLocation,
        };
        this.currentStep++;
      } else {
        await this.sjmMgr.editDescription(
          this.editDescriptionPayload,
          this.sjmMgr.sjmJobObjId
        );
        this.currentStep++;
      }
    }
  }
  async submitApplicationMaterials(): Promise<void> {
    if (this.currentStep < this.totalSteps) {
      const childForm = this.ApplicationMaterialsComponent.form;
      if (childForm.valid) {
        console.log(childForm.value);
        await this.sjmMgr.addMateriaAndLinks(
          childForm.value,
          this.sjmMgr.sjmJobObjId
        );
      }
      this.currentStep++;
    }
  }
  async submitFollowUpQuestions(): Promise<void> {
    if (this.currentStep < this.totalSteps) {
      const childForm = this.sjmFollowUpComponent.form;
      if (childForm.valid) {
        this.followUpMgr.saveJobFollowUpQuestions(
          this.sjmMgr.sjmJobObjId,
          childForm.value.followUpQuestions
        );
      } else {
        const formArray = childForm.get('followUpQuestions') as FormArray;
        formArray.controls.forEach((group) => {
          const formGroup = group as FormGroup;
          Object.keys(formGroup.controls).forEach((key) => {
            const control = formGroup.get(key);
            if (control && control.invalid) {
              control.markAsDirty();
              control.updateValueAndValidity({ onlySelf: true });
            }
          });
        });
      }
      this.currentStep++;
    }
  }
  skipFollowUpQuestions(): void {
    if (this.currentStep < this.totalSteps) {
      this.currentStep++;
    }
  }

  async finishUploadingJob(): Promise<void> {
    const childForm = this.sjmAISettingsComponent.validateForm;
    if (childForm.valid) {
      const hasNonZeroValue = Object.values(childForm.value).some(
        (value: unknown) => {
          const numValue = Number(value);
          return typeof numValue === 'number' && numValue > 0;
        }
      );

      if (hasNonZeroValue) {
        try {
          await this.sjmMgr.patchAiSettings(
            childForm.value,
            this.sjmMgr.sjmJobObjId
          );
          this.notification.create(
            'success',
            'Done',
            'Job Uploaded successfully'
          );
          const atsName = 'clara';
          this.router.navigate(['jobs/candidates/', this.sjmMgr.sjmJobObjId], {
            queryParams: { atsName },
          });
        } catch (error) {}
      } else {
        this.notification.create(
          'error',
          'Error',
          'At least one value should be greater than zero'
        );
      }
    }
  }

  nextStep(): void {
    if (this.currentStep < this.totalSteps) {
      this.currentStep++;
    } else {
      //this.submitForm();
    }
  }

  previousStep(): void {
    if (this.currentStep > 1) {
      this.currentStep--;
    } else {
      this.router.navigate(['jobs']);
    }
  }

  goToStep(step: number): void {
    if (step >= 1 && step <= this.totalSteps) {
      this.currentStep = step;
    }
  }

  getStepClass(step: number): string {
    if (step < this.currentStep) {
      return 'done';
    } else if (step === this.currentStep) {
      return 'active';
    } else {
      return 'inactive';
    }
  }
  // EMIT JOB UPLOAD

  onFileSelected(file: File | null): void {
    this.selectedFile = file;
    this.updatePayload();
  }

  onJobTitleChange(title: string): void {
    if (title.trim() === '') {
      this.jobTitle = '';
      return;
    }
    this.jobTitle = title.trim();
    this.updatePayload();
  }
  onJobLocationChange(location: string): void {
    this.jobLocation = location;
    this.updatePayload();
  }

  updatePayload(): void {
    if (this.jobTitle && this.selectedFile) {
      this.uploadJobPayload = {
        title: this.jobTitle,
        file: this.selectedFile,
        location: this.jobLocation,
      };
    }
  }
  // EMIT EDIT DESCRIPTION
  onEditDescription(description: string | ''): void {
    this.editDescriptionPayload = {
      title: this.jobTitle,
      description: description,
      //  location: this.jobLocation,
    };
  }
  // EMIT EDIT FOLLOW UP QUESTIONS
  onSubmitFollowUpQuestions(data: any | []): void {
    console.log('data', data);
  }
  ngOnInit() {
    //this.currentStep = 3;
  }

  ngOnDestroy() {
    this.editDescriptionPayload = '';
  }
}
