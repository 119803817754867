<nz-spin [nzSpinning]="this.dashboardMgr.isLoadingDashboard">
    <div *ngIf="isChartVisible">
        <div nz-row [nzGutter]="15">
            <div nz-col [nzSpan]="10">
                <div class="card" style="height:455px">
                    <div class="card-header">
                        <p>Total Applications</p>
                        <button nz-button nz-dropdown [nzDropdownMenu]="interval">
                            {{selectedTimeframe}}
                            <span nz-icon nzType="down"></span>
                        </button>
                        <nz-dropdown-menu #interval="nzDropdownMenu">
                            <ul nz-menu>
                                <li nz-menu-item (click)="getAnaylytics('weekly','Weekly')">Weekly</li>
                                <li nz-menu-item (click)="getAnaylytics('monthly','Monthly')">Monthly</li>
                                <li nz-menu-item (click)="getAnaylytics('all_time','All time')">All time</li>
                            </ul>
                        </nz-dropdown-menu>
                    </div>
                    <div class="card-subheader" *ngIf="dashboardMgr.applications">
                        <strong>
                            {{dashboardMgr.applications.last_total}}
                            <label class="new-counts">
                                <span class="new-counts-icon">
                                    <svg width="9" height="9" viewBox="0 0 9 9" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M1.24375 8.5L0.5 7.75625L6.40625 1.85H1.2V0.800003H8.2V7.8H7.15V2.59375L1.24375 8.5Z" />
                                    </svg>
                                </span>
                                <span>{{dashboardMgr.applications.total}}</span>
                            </label>
                        </strong>
                    </div>
                    <div class="card-body" *ngIf="dashboardMgr.applications">
                        <div id="chartCandidates" *ngIf="dashboardMgr.applications.analytics.length > 0">
                            <apx-chart [series]="chartOptionsCandidates.series!" [chart]="chartOptionsCandidates.chart!"
                                [dataLabels]="chartOptionsCandidates.dataLabels!"
                                [plotOptions]="chartOptionsCandidates.plotOptions!"
                                [responsive]="chartOptionsCandidates.responsive!"
                                [xaxis]="chartOptionsCandidates.xaxis!" [legend]="chartOptionsCandidates.legend!"
                                [fill]="chartOptionsCandidates.fill!" [noData]="chartOptionsCandidates.noData!"
                                [stroke]="chartOptionsCandidates.stroke!" [tooltip]="chartOptionsCandidates.tooltip!"
                                [markers]="chartOptionsCandidates.markers!"></apx-chart>
                        </div>

                    </div>
                </div>

            </div>
            <div nz-col [nzSpan]="10">
                <div class="card" style="height:315px">
                    <div class="card-header">
                        <p>Jobs and Candidates Overview</p>
                    </div>
                    <div class="card-body">

                        <div nz-row [nzGutter]="15">
                            <div nz-col [nzSpan]="8">

                                <div>
                                    <div id="chartAssigne">
                                        <apx-chart [series]="chartOptionsAssigne.series!"
                                            [chart]="chartOptionsAssigne.chart!"
                                            [dataLabels]="chartOptionsAssigne.dataLabels!"
                                            [plotOptions]="chartOptionsAssigne.plotOptions!"
                                            [xaxis]="chartOptionsAssigne.xaxis!" [legend]="chartOptionsAssigne.legend!"
                                            [fill]="chartOptionsAssigne.fill!" [noData]="chartOptionsAssigne.noData!"
                                            [tooltip]="chartOptionsAssigne.tooltip!"></apx-chart>
                                    </div>
                                </div>
                            </div>
                            <div nz-col [nzSpan]="8">
                                <div>
                                    <div id="chartAssigneJobs">
                                        <apx-chart [series]="chartOptionsAssigneJob.series!"
                                            [chart]="chartOptionsAssigneJob.chart!"
                                            [dataLabels]="chartOptionsAssigneJob.dataLabels!"
                                            [plotOptions]="chartOptionsAssigneJob.plotOptions!"
                                            [xaxis]="chartOptionsAssigneJob.xaxis!"
                                            [legend]="chartOptionsAssigneJob.legend!"
                                            [fill]="chartOptionsAssigneJob.fill!"
                                            [noData]="chartOptionsAssigneJob.noData!"></apx-chart>
                                    </div>
                                </div>
                            </div>
                            <div nz-col [nzSpan]="8">
                                <div>
                                    <div id="chartAssigneJobsLifecycle">
                                        <apx-chart [series]="chartOptionsAssigneJobLifecycle.series!"
                                            [chart]="chartOptionsAssigneJobLifecycle.chart!"
                                            [dataLabels]="chartOptionsAssigneJobLifecycle.dataLabels!"
                                            [plotOptions]="chartOptionsAssigneJobLifecycle.plotOptions!"
                                            [xaxis]="chartOptionsAssigneJobLifecycle.xaxis!"
                                            [legend]="chartOptionsAssigneJobLifecycle.legend!"
                                            [fill]="chartOptionsAssigneJobLifecycle.fill!"
                                            [noData]="chartOptionsAssigneJobLifecycle.noData!"
                                            [tooltip]="chartOptionsAssigneJobLifecycle.tooltip!"></apx-chart>
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
                <div nz-row [nzGutter]="15">
                    <div nz-col [nzSpan]="12">
                        <div class="card-statistics">
                            <div class="flex-text">
                                <strong>Time Gained</strong>
                                <div>
                                    <p>{{ dashboardMgr.timeSaved.hours }}</p>
                                    <span>hours</span>
                                </div>
                                <div>
                                    <p>{{ dashboardMgr.timeSaved.minutes }}</p>
                                    <span>mins</span>
                                </div>
                            </div>
                            <nz-segmented [nzOptions]="options" [nzBlock]="true"
                                (nzValueChange)="handleTimeSavedChange($event)"></nz-segmented>
                        </div>
                    </div>
                    <div nz-col [nzSpan]="12">
                        <div class="card-statistics text-center">
                            <span class="title-icon">
                                TBC
                                <span class="arrow-icon arrow-absolute">
                                    <svg width="9" height="9" viewBox="0 0 9 9" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M1.24375 8.5L0.5 7.75625L6.40625 1.85H1.2V0.800003H8.2V7.8H7.15V2.59375L1.24375 8.5Z" />
                                    </svg>

                                </span>
                            </span>
                            <div>Increase in Pre-Qualified <br> CLARA candidates</div>

                        </div>
                    </div>

                </div>

            </div>
            <div nz-col [nzSpan]="4">
                <div class="card">
                    <div class="card-header">
                        <p>Notifications</p>
                        <button nz-button nzShape="circle" class="btn-circle-gray" (click)="openNotificationModal()">
                            <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M1.24375 8.5L0.5 7.75625L6.40625 1.85H1.2V0.800003H8.2V7.8H7.15V2.59375L1.24375 8.5Z" />
                            </svg>
                        </button>
                    </div>
                    <div class="card-body">
                        <ul class="notification-list notifiation-container"
                            *ngIf="dashboardMgr.notificationsList.length > 0">
                            <ng-container *ngFor="let notification of dashboardMgr.notificationsList">
                                <li *ngIf="notification.status === 'unread'" class="notification-item">
                                    <div class="notification-box">
                                        <div class="notification-info">{{notification.message}}</div>
                                        <div class="notification-comment">{{notification.info_json?.comment}}</div>
                                        <nz-badge class="ico-badge" nzColor="cyan"></nz-badge>
                                    </div>
                                </li>
                            </ng-container>
                        </ul>
                        <div *ngIf="dashboardMgr.notificationsList.length === 0" class="no-data">
                            No notifications yet!
                        </div>

                        <button *ngIf="dashboardMgr.notificationsList.length > 0" nz-button class="btn-gray" nzBlock
                            nzShape="round" (click)="openNotificationModal()">
                            Load More
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <app-all-jobs-overview [data]="dashboardMgr.applicationOverview"></app-all-jobs-overview>
        <app-hr-home></app-hr-home>
    </div>
</nz-spin>