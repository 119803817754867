import { Injectable } from '@angular/core';
import { FollowUpQuestionsService } from '../Services/follow-up-questions.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { follow_up_questions, jobDetail, application } from '../models/models';

@Injectable({
  providedIn: 'root',
})
export class FollowUpQuestionsManagerService {
  public questions: follow_up_questions[] = [];
  public questionsOnJob: follow_up_questions[] = [];
  public isLoading: boolean = false;
  public isSubmiting: boolean = false;
  isVisibleQuestionsModal: boolean = false;
  isVisibleJobQuestionsModal: boolean = false;
  successModal: boolean = false;
  statusFollowUpQuestions: boolean = false;
  public jobDetailData!: jobDetail;
  public application!: application;
  public isAllAnswered: boolean = false;
  constructor(
    public followUpServices: FollowUpQuestionsService,
    private notification: NzNotificationService
  ) {}
  public getFollowUpQuestions(id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.isLoading = true;
      this.followUpServices.getFollowUpQuestions(id).subscribe({
        next: (c: any) => {
          let questionsList = c.response.follow_up_questions.reduce(
            (acc: any, question: any) => {
              let categoryIndex = acc.findIndex(
                (item: any) => item.category === question.category
              );
              if (categoryIndex === -1) {
                acc.push({
                  category: question.category,
                  questions: [
                    {
                      id: question.id,
                      question: question.question,
                      answer: question.answer ? question.answer : '',
                    },
                  ],
                });
              } else {
                acc[categoryIndex].questions.push({
                  id: question.id,
                  question: question.question,
                  answer: question.answer ? question.answer : '',
                });
              }
              return acc;
            },
            []
          );
          this.questions = questionsList;
          this.jobDetailData = c.response.job_details;
          this.application = c.response.application;
          this.isLoading = false;
          this.checkAllQuestionsAnswered();
          resolve();
        },
        error: (error: any) => {
          this.notification.create('error', 'Error', error.error.error);
          this.isLoading = false;
          reject(error);
        },
      });
    });
  }
  private checkAllQuestionsAnswered(): void {
    this.isAllAnswered = this.questions.every((category: any) =>
      category.questions.every((question: any) => question.answer.trim() !== '')
    );
  }
  public submitFollowUpQuestions(data: any, id: string) {
    this.isSubmiting = true;
    this.followUpServices.submitFollowUpQuestions(data, id).subscribe({
      next: (c: any) => {
        this.isSubmiting = false;
        this.isVisibleQuestionsModal = false;
        this.successModal = true;
        this.getFollowUpQuestions(id);
      },
      error: (error: any) => {
        this.notification.create(
          'error',
          'Error',
          'Please fill the required fields'
        );
        this.isSubmiting = false;
      },
    });
  }

  public getJobFollowUpQuestions(id: string): void {
    this.isLoading = true;
    this.questionsOnJob = [];
    this.followUpServices.getJobFollowUpQuestions(id).subscribe({
      next: (c: any) => {
        if (c.response.follow_up_questions != null) {
          let questionsList = c.response.follow_up_questions.reduce(
            (acc: any, question: any) => {
              let categoryIndex = acc.findIndex(
                (item: any) => item.category === question.category
              );
              if (categoryIndex === -1) {
                acc.push({
                  category: question.category,
                  id: question.id,
                  questions: [
                    {
                      question: question.question,
                      isEditable: false,
                    },
                  ],
                });
              } else {
                acc[categoryIndex].questions.push({
                  id: question.id,
                  question: question.question,
                  answer: question.answer ? question.answer : '',
                });
              }
              return acc;
            },
            []
          );
          console.log(questionsList);
          this.questionsOnJob = questionsList;
        }

        this.isLoading = false;
      },
      error: (error: any) => {
        this.notification.create('error', 'Error', error.error.error);
        this.isLoading = false;
      },
    });
  }
  public saveJobFollowUpQuestions(id: string, formData: any): void {
    this.isLoading = true;
    this.followUpServices.saveJobFollowUpQuestions(id, formData).subscribe({
      next: (c: any) => {
        this.isLoading = false;
      },
      error: (error: any) => {
        this.notification.create('error', 'Error', error.error.error);
        this.isLoading = false;
      },
    });
  }
  public updateJobFollowUpQuestion(id: string, formData: any): void {
    this.isLoading = true;
    this.followUpServices.updateJobFollowUQuestion(id, formData).subscribe({
      next: (c: any) => {
        this.isLoading = false;
      },
      error: (error: any) => {
        this.notification.create('error', 'Error', error.error.error);
        this.isLoading = false;
      },
    });
  }
  public getStatusFollowUpQuestions(id: string): void {
    this.isLoading = true;
    this.followUpServices.getStatusFollowUpQuestions(id).subscribe({
      next: (c: any) => {
        this.statusFollowUpQuestions =
          Object(c.response)['follow_up_questions'] === 'false' ? false : true;
        this.isLoading = false;
      },
      error: (error: any) => {
        this.notification.create('error', 'Error', error.error.error);
        this.isLoading = false;
      },
    });
  }
  public sentStatusFollowUpQuestions(formData: any, id: string): void {
    this.isLoading = true;
    this.followUpServices.sentStatusFollowUpQuestions(formData, id).subscribe({
      next: (c: any) => {
        this.isLoading = false;
      },
      error: (error: any) => {
        this.notification.create('error', 'Error', error.error.error);
        this.isLoading = false;
      },
    });
  }
}
