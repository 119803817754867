import { Component } from '@angular/core';
import { AuthenticationService } from 'src/app/Services/authentication.service';
import { Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { PermissionService } from 'src/app/Services/permission.service';
import { WebSocketService } from 'src/app/Services/socket/web-socket.service';
import { environment } from 'src/environments/environment';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  isLoading: boolean = false;
  passwordVisible: boolean = false;
  isLoginError: boolean = false;
  validateForm!: UntypedFormGroup;
  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    public auth: AuthenticationService,
    private notification: NzNotificationService,
    private permissionService: PermissionService,
    private webSocketService: WebSocketService
  ) {}
  submitForm(): void {
    if (this.validateForm.valid) {
      const email = this.validateForm.controls['email'].value;
      const password = this.validateForm.controls['password'].value;
      if (email && password) {
        this.isLoading = true;
        this.auth.login(email, password).subscribe({
          next: (res: any) => {
            localStorage.removeItem('user');
            localStorage.setItem('user', JSON.stringify(res.response));

            //this.webSocketService.openWebSocket(res.response.id);
            this.isLoading = false;
            this.auth.isSubmenu = false;
            const bodyTag = document.body;
            bodyTag.classList.remove('is-submenu');
            this.permissionService.updatePermissions(res.response);
            this.router.navigate(['/']);
          },
          error: (error) => {
            this.notification.create('error', 'Error', error.error.error);
            this.isLoginError = true;
            this.isLoading = false;
          },
        });
      }
    } else {
      Object.values(this.validateForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
  }
}
