import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  constructor() {}
  private jobObjectId: string = '';

  setJobRemoteId(id: string) {
    this.jobObjectId = id;
  }

  getJobRemoteId(): string {
    return this.jobObjectId;
  }
}
