<div class="fullscreen bg-gradient">
    <div class="shape1"></div>
    <div class="shape2"></div>

    <div class="login-wrap">
        <h1>Welcome to CLARA</h1>
        <nz-alert class="pb-20" *ngIf="isError" nzType="error" nzMessage="Invalid Username or Password!"
            nzShowIcon></nz-alert>

        <form nz-form [formGroup]="validateForm" class="login-form" (ngSubmit)="submitForm()">
            <label for="first_name">First Name*</label>
            <nz-form-item>
                <nz-form-control nzErrorTip="Please input your first name!">
                    <input nzSize="large" nz-input formControlName="first_name" placeholder="First Name"
                        id="first_name" />
                </nz-form-control>
            </nz-form-item>
            <label for="last_name">Last Name*</label>
            <nz-form-item>
                <nz-form-control nzErrorTip="Please input your last name!">
                    <input nzSize="large" nz-input formControlName="last_name" placeholder="Last Name" id="last_name" />
                </nz-form-control>
            </nz-form-item>
            <label for="email">Email*</label>
            <nz-form-item>
                <nz-form-control nzErrorTip="Please input your valid email!">
                    <input nzSize="large" nz-input formControlName="email" placeholder="Email" id="email" />
                </nz-form-control>
            </nz-form-item>
            <label for="password">New Password*</label>
            <nz-form-item>
                <nz-form-control
                    nzErrorTip="Please input your New Password at least 8 characters and 1 special character!">
                    <nz-input-group [nzSuffix]="suffixTemplate" nzSize="large">
                        <input [type]="passwordVisible ? 'text' : 'password'" nzSize="large" nz-input
                            formControlName="password" placeholder="Password" id="password" />
                    </nz-input-group>
                    <ng-template #suffixTemplate>
                        <span nz-icon [nzType]="passwordVisible ? 'eye' : 'eye-invisible'"
                            (click)="passwordVisible = !passwordVisible"></span>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <div class="register-info">
                    <div class="register-info-item">
                        <span nz-icon nzType="check" nzTheme="outline" class="pr-10"></span>
                        <span>Must be at least 8 characters</span>
                    </div>
                    <div class="register-info-item">
                        <span nz-icon nzType="check" nzTheme="outline" class="pr-10"></span>
                        <span>Must contain at least 1 special character</span>
                    </div>
                </div>
            </nz-form-item>

            <div class="custom-footer">
                <button nz-button nzBlock nzSize="large" [nzType]="'primary'" nzShape="round"><span
                        *ngIf="this.usersServiceManger.isAddingUser" nz-icon nzType="loading" nzTheme="outline"></span>
                    Get Started</button>
                <a routerLink="/login"> <button nz-button nzType="link" nzBlock nzSize="large" nzShape="round">Back to
                        login</button></a>
            </div>
        </form>
    </div>
</div>