import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class NotificationsSettingService {
  constructor(private http: HttpClient) {}
  getNotificationsSettings() {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.get(environment.apiURL + `settings/notifications/`, {
      headers: header,
    });
  }
  updatedNotificationsSettings(formData: any) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.put(
      environment.apiURL + `settings/notifications/`,
      formData,
      {
        headers: header,
      }
    );
  }
}
