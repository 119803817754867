<div class="page-banner text-center">
    CLARA will ask candidates to attach a resume plus any additional the materials you require. <br> Please select the
    applicable
    materials from the list below:
</div>
<nz-spin [nzSpinning]="sjmMgr.isLoadingJobDetail">
    <div class="materials" *ngIf="sjmMgr.jobDetailSJMData">
        <div class="title-black">Upload Materials</div>
        <!-- Static Just UI Start -->
        <div class="material-item">
            <div class="d-flex align-item-middle">
                <div class="checkbox-wrap ">
                    <svg class="ico-checkbox" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity=".4">
                            <path d="M0 8a8 8 0 0 1 8-8h4a8 8 0 0 1 8 8v4a8 8 0 0 1-8 8H8a8 8 0 0 1-8-8V8z"
                                fill="#5822D3" />
                            <g filter="url(#edax0a0eza)">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="m8.313 11.503 5.391-5.392a1.125 1.125 0 0 1 1.591 1.59L9.108 13.89c-.44.44-1.152.44-1.591 0l-2.813-2.812a1.125 1.125 0 1 1 1.591-1.591l2.018 2.017z"
                                    fill="#fff" />
                            </g>
                        </g>
                        <defs>
                            <filter id="edax0a0eza" x="4.375" y="5.781" width="11.25" height="8.938"
                                filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                    result="hardAlpha" />
                                <feOffset dy=".5" />
                                <feComposite in2="hardAlpha" operator="out" />
                                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
                                <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_806_238929" />
                                <feBlend in="SourceGraphic" in2="effect1_dropShadow_806_238929" result="shape" />
                            </filter>
                        </defs>
                    </svg>

                </div>
                <div class="light-info-box">
                    Resumes are always required to apply with CLARA
                </div>
            </div>
            <div class="d-flex align-item-middle">
                <div class="pr-16 color-black">Mandatory</div>
                <nz-switch [(ngModel)]="checked" [nzDisabled]="isDisabled"></nz-switch>
            </div>
        </div>
        <!-- Static Just UI End -->
        <form [formGroup]="form" (ngSubmit)="submitForm()">
            <!-- Upload Materials Start -->
            <div class="material-item" *ngFor="let material of materials.controls; let i = index"
                [formGroup]="getMaterialFormGroup(i)">
                <div class="d-flex align-item-middle">
                    <div class="checkbox-wrap">
                        <label nz-checkbox formControlName="is_display">{{ material.get('is_custom')?.value
                            ? 'Custom' : material.get('material_name')?.value
                            }}</label>
                    </div>
                    <div class="custom-type" *ngIf="material.get('is_custom')?.value">
                        <input nz-input placeholder="Material Name" formControlName="material_name" />
                    </div>
                </div>
                <div class="d-flex align-item-middle">
                    <div class="pr-16 color-black">Mandatory</div>
                    <nz-switch formControlName="is_mandatory"
                        [nzDisabled]="material.get('is_custom')?.value && !material.get('material_name')?.value"></nz-switch>
                    <button *ngIf="material.get('is_custom')?.value" class="remove-button"
                        (click)="removeCustomMaterial(i)">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z"
                                fill="#1C1A20" fill-opacity=".7" />
                        </svg>
                    </button>
                </div>

            </div>
            <div class="add-button">
                <button (click)="addCustomMaterial()" nz-button nzType="link"><span nz-icon nzType="plus"
                        nzTheme="outline"></span>
                    <span class="font-weight-medium">Add Custom Application Material</span></button>
            </div>
            <!-- Upload Materials End -->
            <br><br>
            <!-- Upload Links Start -->
            <div class="title-black">Links</div>
            <div class="material-item" *ngFor="let link of links.controls; let i = index"
                [formGroup]="getLinkFormGroup(i)">
                <div class="d-flex align-item-middle">
                    <div class="checkbox-wrap">
                        <label nz-checkbox formControlName="is_display">{{ link.get('is_custom')?.value
                            ? 'Custom' : link.get('link_name')?.value
                            }}</label>
                    </div>
                    <div class="custom-type" *ngIf="link.get('is_custom')?.value">
                        <input nz-input placeholder="Link Name" formControlName="link_name" />
                    </div>
                </div>
                <div class="d-flex align-item-middle">
                    <div class="pr-16 color-black">Mandatory</div>
                    <nz-switch formControlName="is_mandatory" class="custom-switch"
                        [nzDisabled]="link.get('is_custom')?.value && !link.get('link_name')?.value"></nz-switch>
                    <button *ngIf="link.get('is_custom')?.value" class="remove-button" (click)="removeLink(i)">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z"
                                fill="#1C1A20" fill-opacity=".7" />
                        </svg>
                    </button>
                </div>

            </div>
            <div class="add-button">
                <button (click)="addLink()" nz-button nzType="link"><span nz-icon nzType="plus"
                        nzTheme="outline"></span>
                    <span class="font-weight-medium">Add Custom Link</span></button>
            </div>
            <!-- Upload Links End -->

        </form>
    </div>
</nz-spin>