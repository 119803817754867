<div class="page">
    <app-side-menu *appIsGranted="{ content_type: 'application', desiredPermission: 'view' }"></app-side-menu>
    <main class="main" *ngIf="followUpMgr.jobDetailData">
        <nz-breadcrumb *appIsGranted="{ content_type: 'application', desiredPermission: 'view' }">
            <nz-breadcrumb-item>
                <a routerLink="/"><span nz-icon nzType="home"></span></a>
            </nz-breadcrumb-item>
            <nz-breadcrumb-item><a routerLink="/">Jobs</a></nz-breadcrumb-item>
            <nz-breadcrumb-item>{{followUpMgr.jobDetailData.title}}</nz-breadcrumb-item>
        </nz-breadcrumb>
        <div class="d-flex align-middle">
            <h1>{{followUpMgr.jobDetailData.title}}</h1>
            <div class="ml-10" *ngIf="followUpMgr.jobDetailData.remote_id">JOB ID
                {{followUpMgr.jobDetailData.remote_id}}</div>
        </div>

        <div class="follow-up" *ngIf="followUpMgr.questions.length > 0">
            <div class="alert-material">
                <div class="alert-left">
                    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M16.5 29.333C9.136 29.333 3.167 23.364 3.167 16S9.137 2.667 16.5 2.667c7.364 0 13.334 5.969 13.334 13.333S23.864 29.333 16.5 29.333zm-1.329-8 9.427-9.428-1.886-1.885-7.541 7.543-3.772-3.772-1.885 1.885 5.657 5.657z"
                            fill="#12D382" />
                    </svg>
                    <div class="ml-8">Your application was received!</div>
                </div>
                <div class="alert-right">
                    <button class="btn-alert-material" (click)="visibleMaterialModal()">View application
                        materials</button>
                </div>
            </div>
            <div class="purple-box">
                <div class="purple-box-header">
                    Follow Up Questions
                </div>
                <div class="purple-box-body">
                    <p>Your application materials look great! We have some follow up questions in the categories below
                        that
                        can enhance your application.</p>
                    <div class="questions-categories">
                        <div class="question-item" *ngFor="let item of followUpMgr.questions; let i = index">
                            <span class="ico-info" nz-icon nzType="check" nzTheme="outline"></span>
                            <span>{{item.category}}</span>
                        </div>

                        <button (click)="openQuestionsModal(followUpMgr.questions)" nz-button nzSize="large"
                            nzType="primary" nzShape="round" class="mt-10">
                            Go to Questions</button>
                    </div>

                </div>
            </div>
        </div>


        <div class="job-detail color-black-container" *ngIf="followUpMgr.jobDetailData">
            <div class="left-panel">
                <p><b>About the job</b></p>
                <p [innerHTML]="followUpMgr.jobDetailData.description"></p>

            </div>
            <div class="right-panel">
                <h3>Details</h3>
                <ul class="info-list">
                    <li>
                        <span>Position</span>
                        <span>{{followUpMgr.jobDetailData.position}}</span>
                    </li>
                    <li>
                        <span>Location</span>
                        <span>{{followUpMgr.jobDetailData.location}}</span>
                    </li>
                    <li>
                        <span>Role Duration</span>
                        <span>{{followUpMgr.jobDetailData.role_duration}}</span>
                    </li>

                </ul>
                <h3>Job Posting</h3>
                <ul class="info-list">
                    <li>
                        <span>Job Name</span>
                        <span>{{followUpMgr.jobDetailData.title}}</span>
                    </li>
                    <li>
                        <span>Job ID</span>
                        <span>{{followUpMgr.jobDetailData.remote_id}}</span>
                    </li>
                    <li>
                        <span>Date Posted</span>
                        <span>{{followUpMgr.jobDetailData.posting_date}}</span>
                    </li>

                </ul>
                <h3>Education</h3>
                <p *ngIf="followUpMgr.jobDetailData.extracted_details.education">
                    {{followUpMgr.jobDetailData.extracted_details.education.degree}}
                </p>

            </div>
        </div>

        <nz-modal [(nzVisible)]="followUpMgr.isVisibleQuestionsModal" nzTitle="Follow Up Questions"
            (nzOnCancel)="handleCancel()" [nzFooter]="null" nzWidth="768px"
            [nzBodyStyle]="{ 'height': 'calc(100vh - 115px)', 'overflow-y': 'auto' }" [nzStyle]="{ top: '0' }"
            nzClassName="no-border-radius">
            <ng-container *nzModalContent>
                <div class="multi-form">
                    <div>
                        <div class="steps-box">
                            <div class="step-dot" *ngFor="let question of followUpMgr.questions; let i = index"
                                (click)="goToStep(i)" [class.active]="currentStep === i"></div>
                        </div>
                        <nz-spin [nzSpinning]="this.followUpMgr.isSubmiting">
                            <ng-container *ngFor="let item of followUpMgr.questions; let i = index">
                                <ng-container *ngIf="currentStep === i">
                                    <div>
                                        <h2 class="font-heading">{{ item.category }}</h2>
                                        <form nzLayout="vertical" nz-form [formGroup]="formGroup">
                                            <ng-container *ngFor="let question of item.questions; let num = index">
                                                <nz-form-item>
                                                    <div class="custom-label">
                                                        <span>Question {{num+1}}:</span>
                                                        <ng-container
                                                            *ngFor="let line of question.question.split('\n'); let i = index">
                                                            <span [ngClass]="{'indent': i !== 0}">{{ line }}</span> <br>
                                                        </ng-container>
                                                    </div>


                                                    <nz-form-control nzErrorTip="Please input your answer!">
                                                        <textarea nz-input placeholder="Type your answer"
                                                            [formControlName]="'question_' + question.id"
                                                            [nzAutosize]="{ minRows: 3, maxRows: 5 }"
                                                            (input)="onInputAnswer($event)"
                                                            [ngClass]="{ 'readonly': followUpMgr.isAllAnswered }"></textarea>
                                                    </nz-form-control>
                                                </nz-form-item>
                                            </ng-container>
                                        </form>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </nz-spin>
                    </div>
                    <div class="custom-footer" *ngIf="!followUpMgr.isAllAnswered">
                        <button (click)="nextStep('submit')" nz-button nzBlock class="mb-10" nzSize="large"
                            nzType="primary" nzShape="round">{{currentStep ===
                            followUpMgr.questions.length - 1 ?
                            'Submit Answers' : 'Next'}}</button>
                        <button nz-button nzBlock nzType="link" nzSize="large" (click)="prevStep()">Save and
                            Close</button>
                    </div>
                    <nz-alert class="mb-20"
                        *ngIf="followUpMgr.isAllAnswered && currentStep === followUpMgr.questions.length - 1"
                        nzType="info" nzMessage="All questions are answered!" nzDescription="" nzShowIcon></nz-alert>
                    <div class="custom-footer" *ngIf="followUpMgr.isAllAnswered">
                        <div class="flex-buttons">
                            <button (click)="nextStep('submited')" nzBlock nz-button class="mb-10" nzSize="large"
                                nzType="primary" nzShape="round">{{currentStep ===
                                followUpMgr.questions.length - 1 ?
                                'Back to Home' : 'Next'}}</button>
                            <ng-container
                                *ngIf="followUpMgr.isAllAnswered && currentStep === followUpMgr.questions.length - 1 && !user.id">
                                <span class="or-separator">OR</span>
                                <button (click)="joinDein()" nzSize="large" nz-button nzBlock class="mb-10"
                                    nzType="default" nzShape="round">Join
                                    CLARA</button>

                            </ng-container>
                        </div>
                        <button nz-button nzBlock nzType="link" nzSize="large"
                            (click)="cancelFollowUpQuestion()">Cancel</button>

                    </div>
                </div>
            </ng-container>
        </nz-modal>
        <nz-modal [(nzVisible)]="followUpMgr.successModal" nzTitle="" (nzOnCancel)="cancelSuccessModal()"
            [nzFooter]="null" nzWidth="400px">
            <ng-container *nzModalContent>
                <div class="success-box">
                    <h3>Your answers were saved!</h3>
                    <h3>CLARA is committed to our applicant progress. We’re here to help you find roles where you can
                        grow,
                        excel and add value.
                    </h3>
                    <div *ngIf="age === 'above_18'">
                        <div *ngIf="!user.id">
                            <p>If you would like to be recommend you for other open positions, join us today!</p>
                            <button (click)="joinDein()" nz-button nzBlock class="mb-10" nzType="primary"
                                nzShape="round">Join
                                CLARA</button>
                            <p>By joining CLARA you will enjoy many benefits:</p>
                            <ul>
                                <li>Free membership</li>
                                <li>Automatic matching and referrals to open positions</li>
                            </ul>

                            <div class="custom-footer">
                                <button (click)="joinDein()" nz-button nzBlock class="mb-10" nzType="primary"
                                    nzShape="round">Join
                                    CLARA</button>
                                <button nz-button nzBlock nzType="link" (click)="cancelSuccessModal()">No
                                    Thanks</button>
                            </div>
                        </div>
                    </div>
                    <button *ngIf="user.id" (click)="backToHome()" nz-button nzBlock class="mb-10" nzType="primary"
                        nzShape="round">Back to
                        Home</button>
                </div>
            </ng-container>
        </nz-modal>
        <nz-modal [(nzVisible)]="isVisbileMaterial" nzTitle="" (nzOnCancel)="cancelMaterialModal()"
            nzTitle="Application Materials" [nzFooter]="null" nzWidth="572px">
            <ng-container *nzModalContent>
                <div *ngIf="followUpMgr.application && followUpMgr.application.application_materials.length > 0">
                    <div *ngFor="let material of followUpMgr.application.application_materials">
                        <div class="material-item">
                            <!-- Material icon -->
                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M16 29.333C8.636 29.333 2.667 23.364 2.667 16S8.637 2.667 16 2.667c7.364 0 13.334 5.969 13.334 13.333S23.364 29.333 16 29.333zm-1.329-8 9.427-9.428-1.886-1.885-7.541 7.543-3.772-3.772-1.885 1.885 5.657 5.657z"
                                    fill="#12D382" />
                            </svg>


                            <div class="ml-8">{{ material.filename }}</div>
                        </div>
                    </div>
                </div>
                <div *ngIf="followUpMgr.application && followUpMgr.application.links.length > 0">
                    <div *ngFor="let link of followUpMgr.application.links">
                        <div class="material-item">
                            <!-- Link icon -->
                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="m23.543 19.77-1.886-1.885L23.543 16A5.334 5.334 0 0 0 16 8.457l-1.885 1.886-1.886-1.886 1.886-1.885a8 8 0 0 1 11.313 11.313l-1.885 1.886zm-3.772 3.773-1.886 1.885A8 8 0 1 1 6.572 14.115l1.885-1.886 1.886 1.886L8.457 16A5.333 5.333 0 1 0 16 23.543l1.885-1.886 1.886 1.886zm0-13.2 1.886 1.886-9.428 9.427-1.886-1.885 9.428-9.427v-.001z"
                                    fill="#1C1A20" fill-opacity=".7" />
                            </svg>

                            <div class="ml-8">{{ link.link_url }}</div>
                        </div>
                    </div>

                    <div class="footer-right">
                        <button nz-button (click)="cancelMaterialModal()" nzType="default" nzShape="round">Done</button>
                    </div>
                </div>
            </ng-container>
        </nz-modal>

    </main>
</div>