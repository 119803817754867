<div class="page">
    <!-- Side menu -->
    <app-side-menu></app-side-menu>
    <!-- main -->
    <main class="main">
        <nz-breadcrumb>
            <nz-breadcrumb-item>
                <a routerLink="/"><span nz-icon nzType="home"></span></a>
            </nz-breadcrumb-item>
            <nz-breadcrumb-item>Jobs</nz-breadcrumb-item>
        </nz-breadcrumb>
        <div class="heading-flex">
            <div class="heading">
                <h1 class="font-heading">Jobs</h1>
            </div>
            <div>
                <div class="d-flex">
                    <button nz-button nzType="default"
                        *appIsGranted="{ content_type: 'partnersettings', desiredPermission: 'view' }" nzShape="round"
                        (click)="goToUploadNewJob()" class="font-size-medium mr-8"><span nz-icon nzType="upload"
                            nzTheme="outline"></span> Upload New Job</button>
                    <button nz-button nzType="primary"
                        *appIsGranted="{ content_type: 'partnersettings', desiredPermission: 'view' }" nzShape="round"
                        (click)="importJobsModal()" class="font-size-medium"><span nz-icon nzType="plus"
                            nzTheme="outline"></span> Import Jobs</button>
                </div>
            </div>
        </div>
        <div nz-row [nzGutter]="16">
            <div nz-col [nzSpan]="24">
                <nz-tabset>
                    <nz-tab [nzTitle]="titleTemplateActive">
                        <ng-template #titleTemplateActive>
                            <div class="heading">
                                <p>Active Jobs <span class="jobs-counter">{{jobsMgr.totalActive}}</span> </p>
                            </div>

                        </ng-template>
                        <div class="fiter-form">
                            <div nz-row [nzGutter]="16">
                                <div nz-col [nzSpan]="6">
                                    <form nz-form [formGroup]="searchFormActive" (ngSubmit)="submitSearchActive()">
                                        <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" nzSize="large">
                                            <input type="text" nz-input placeholder="Search"
                                                formControlName="searchActive" (input)="onSearchInputActive()" />
                                        </nz-input-group>
                                        <ng-template #suffixIconButton>
                                            <button nz-button nzType="default" nzSearch nzSize="large"><span nz-icon
                                                    nzType="search"></span></button>
                                        </ng-template>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <nz-spin [nzSpinning]="jobsMgr.isLoading">
                            <div class="table-data mb-20">
                                <nz-table [nzFrontPagination]="false" #dataActive [nzData]="jobsMgr.jobsActiveData">
                                    <thead>
                                        <tr>
                                            <th scope="col">Job Name</th>
                                            <th scope="col">ATS Name</th>
                                            <th scope="col">Job ID</th>
                                            <th scope="col">Date Posted</th>
                                            <th scope="col" *ngIf="user.role !=='Hiring Manager'">Candidate Processing
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let data of dataActive.data" (click)="selectRow(data)">
                                            <td class="cursor-pointer">
                                                <a class="capitalize">{{data.title}}
                                                    <svg *ngIf="isRecent(data.posting_date)" width="16" height="17"
                                                        viewBox="0 0 16 17" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M16 8.492a.56.56 0 0 0-.228-.45l.003-.005-1.934-1.126 1.113-1.943-.003-.002a.553.553 0 0 0-.447-.778v-.006H12.27V1.937h-.005a.549.549 0 0 0-.773-.449l-.003-.005-1.967 1.145L8.433.726 8.43.73a.552.552 0 0 0-.892 0L7.532.726 6.425 2.66 4.518 1.55l-.002.005a.55.55 0 0 0-.773.45h-.005v2.231H1.536v.005a.553.553 0 0 0-.446.778l-.005.003 1.089 1.9-1.95 1.134.003.004a.56.56 0 0 0 0 .9l-.002.004 1.934 1.126-1.113 1.943.003.002a.553.553 0 0 0 .446.778v.006h2.235v2.245h.004a.55.55 0 0 0 .773.449l.003.005 1.967-1.145 1.09 1.902.004-.003a.552.552 0 0 0 .892 0l.005.003 1.107-1.933 1.907 1.11.002-.005a.55.55 0 0 0 .773-.45h.005v-2.231h2.202v-.005a.553.553 0 0 0 .446-.778l.005-.003-1.089-1.9 1.95-1.134-.003-.004a.559.559 0 0 0 .227-.45zm-9.304 2.382-2.043-.91.902 1.574-.51.296-1.382-2.412.524-.305 1.977.87-.868-1.515.51-.296 1.381 2.412-.491.286zm.965-.562L6.28 7.9l1.694-.985.26.452-1.185.689.29.506 1.159-.674.259.451-1.16.675.316.55 1.184-.69.259.453-1.694.986zm3.923-2.282-1.37-1.412.533 1.899-.541.315-2.068-2.013.57-.332 1.42 1.498-.544-2.008.399-.231 1.45 1.48-.578-1.988.57-.332.7 2.81-.541.314z"
                                                            fill="#5822D3" />
                                                    </svg>
                                                </a>
                                            </td>
                                            <td class="cursor-pointer color-black">
                                                {{data.ats | uppercase}}
                                            </td>
                                            <td class="cursor-pointer color-black">
                                                {{data.remote_id ? data.remote_id : data['_id']}}
                                            </td>
                                            <td class="cursor-pointer">
                                                <div class="d-flex">
                                                    <div class="mr-16 color-black">{{data.posting_date | date}}</div>
                                                    <div>{{data.posting_date | dateAgo}}</div>
                                                </div>
                                            </td>
                                            <td *ngIf="user.role !=='Hiring Manager'">
                                                <button *ngIf="data.awaiting_count > 0" nz-button nzType="default"
                                                    nzShape="round"
                                                    (click)="processCandidates( data['_id']); $event.stopPropagation()">
                                                    Process Candidates
                                                </button>
                                                <div class="info-label"
                                                    *ngIf="data.awaiting_count === 0 && data.received_application_count === data.processed_application_count">
                                                    <span nz-icon class="purple-icon" nzType="check"
                                                        nzTheme="outline"></span>
                                                    Candidates Processed
                                                </div>
                                                <div class="info-label"
                                                    *ngIf="data.received_application_count > data.processed_application_count">
                                                    <span nz-icon class="purple-icon" nzType="loading"
                                                        nzTheme="outline"></span>
                                                    Activating Candidate Processing
                                                </div>
                                                <!-- <div class="info-label"
                                                    *ngIf="data.process_status !== 'EXTRACTION_COMPLETED'">
                                                    <span nz-icon class="purple-icon" nzType="loading"
                                                        nzTheme="outline"></span>
                                                    Job processing...
                                                </div> -->

                                            </td>
                                        </tr>
                                    </tbody>
                                </nz-table>
                                <div class="pagination-center">
                                    <nz-pagination *ngIf="jobsMgr.totalActive > 10" [nzPageIndex]="1"
                                        (nzPageIndexChange)="handlePageIndexChangeActive($event)"
                                        [nzTotal]="jobsMgr.totalActive"></nz-pagination>
                                </div>

                            </div>
                        </nz-spin>

                    </nz-tab>
                    <nz-tab [nzTitle]="titleTemplateClosed">
                        <ng-template #titleTemplateClosed>
                            <div class="heading">
                                <p>Closed Jobs <span class="jobs-counter">{{jobsMgr.totalClosed}}</span> </p>
                            </div>

                        </ng-template>
                        <div class="fiter-form">
                            <div nz-row [nzGutter]="16">
                                <div nz-col [nzSpan]="8">
                                    <form nz-form [formGroup]="searchFormClosed" (ngSubmit)="submitSearchClosed()">
                                        <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" nzSize="large">
                                            <input type="text" (input)="onSearchInputClosed()" nz-input
                                                placeholder="Search" formControlName="searchClosed" />
                                        </nz-input-group>
                                        <ng-template #suffixIconButton>
                                            <button nz-button nzType="default" nzSearch nzSize="large"><span nz-icon
                                                    nzType="search"></span></button>
                                        </ng-template>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <nz-spin [nzSpinning]="jobsMgr.isLoadingClosed">
                            <div class="table-data ">
                                <nz-table [nzFrontPagination]="false" #dataClosed [nzData]="jobsMgr.jobsClosedData">
                                    <thead>
                                        <tr>
                                            <th scope="col">Job Name</th>
                                            <th scope="col">ATS Name</th>
                                            <th scope="col">Job ID</th>
                                            <th scope="col">Date Posted</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let data of dataClosed.data">
                                            <td class="capitalize">{{data.title}}</td>
                                            <td class="cursor-pointer color-black">
                                                {{data.ats | uppercase}}
                                            </td>
                                            <td>{{data.remote_id ? data.remote_id : data['_id']}}</td>
                                            <td class="cursor-pointer">
                                                <div class="d-flex">
                                                    <div class="mr-16 color-black">{{data.posting_date | date}}</div>
                                                    <div>{{data.posting_date | dateAgo}}</div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </nz-table>
                                <div class="pagination-center mb-20">
                                    <nz-pagination *ngIf="jobsMgr.totalClosed > 10" [nzPageIndex]="1"
                                        (nzPageIndexChange)="handlePageIndexChangeClosed($event)"
                                        [nzTotal]="jobsMgr.totalClosed"></nz-pagination>
                                </div>

                            </div>
                        </nz-spin>
                    </nz-tab>
                </nz-tabset>
            </div>

        </div>
        <nz-modal [(nzVisible)]="jobsMgr.isVisibleImportJob" nzTitle="Import Jobs"
            (nzOnCancel)="handleCancelImportJob()" (nzOnOk)="handleOkImportJob()" nzWidth="900px" [nzFooter]="null">
            <ng-container *nzModalContent>
                <nz-steps [nzCurrent]="currentStep" class="wizard">
                    <nz-step></nz-step>
                    <nz-step></nz-step>
                </nz-steps>
                <div class="multi-form">
                    <ng-container *ngIf="currentStep === 0">
                        <nz-spin [nzSpinning]="jobsMgr.isLoadingImportJobs" nzTip="Loading Jobs from ATS ....">
                            <h2>Select a Job</h2>

                            <div class="collapsible-container">
                                <!-- Open Jobs Section -->
                                <div class="collapsible-item" *ngIf="jobsMgr.importJobsData.length > 0">
                                    <div class="collapsible-header">
                                        <label>Open Jobs</label>

                                        <span nz-icon class="ico-caret cursor-pointer"
                                            [nzType]="isVisibleJobCollapse[0] ? 'caret-down' : 'caret-up'"
                                            nzTheme="outline" (click)="toggleCollapse(0)"></span>
                                    </div>
                                    <div *ngIf="isVisibleJobCollapse[0]" class="collapsible-content">
                                        <div *ngFor="let job of jobsMgr.importJobsData; let i = index">
                                            <!-- Render only open jobs -->
                                            <ng-container *ngIf="job.status.toLowerCase() === 'open'">
                                                <label nz-checkbox [nzChecked]="setOfCheckedId.has(job.remote_id)"
                                                    (nzCheckedChange)="onItemChecked(job.remote_id, $event)">
                                                    {{ job.title || 'Unknown Title' }} &nbsp;&nbsp;&nbsp; {{
                                                    job.remote_id }}
                                                </label>

                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-24"
                                    *ngIf="!jobsMgr.isLoadingImportJobs && jobsMgr.importJobsData.length === 0">
                                    <nz-alert class="" nzType="info" nzMessage="Currently no jobs available!"
                                        nzDescription="" nzShowIcon></nz-alert>
                                </div>

                                <!-- Closed Jobs Section -->
                                <div class="collapsible-item" *ngIf="jobsMgr.importJobsData.length > 0">
                                    <div class="collapsible-header">
                                        <label>Closed Jobs</label>
                                        <span nz-icon class="ico-caret cursor-pointer"
                                            [nzType]="isVisibleJobCollapse[1] ? 'caret-down' : 'caret-up'"
                                            nzTheme="outline" (click)="toggleCollapse(1)"></span>
                                    </div>
                                    <div *ngIf="isVisibleJobCollapse[1]" class="collapsible-content">
                                        <div *ngFor="let job of jobsMgr.importJobsData; let i = index">
                                            <!-- Render only closed jobs -->
                                            <ng-container *ngIf="job.status.toLowerCase() === 'closed'">
                                                <label nz-checkbox [nzChecked]="setOfCheckedId.has(job.remote_id)"
                                                    (nzCheckedChange)="onItemChecked(job.remote_id, $event)">
                                                    {{ job.title || 'Unknown Title' }} &nbsp;{{ job.remote_id }}
                                                </label>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </nz-spin>
                    </ng-container>
                    <ng-container *ngIf="currentStep === 1">
                        <h2>Confirm your Selection</h2>
                        <div class="selected-data">
                            <div class="selected-item"
                                *ngFor="let data of selectedImportJobs; let i = index; last as isLast">
                                <div nz-row [nzGutter]="16">
                                    <div nz-col [nzSpan]="12">
                                        <div class="info-list">
                                            <h4>Job Posting</h4>
                                            <ul>
                                                <li>
                                                    <span>Job Name</span>
                                                    <span>{{data.title}}</span>
                                                </li>
                                                <li>
                                                    <span>Job ID</span>
                                                    <span>{{data.remote_id}}</span>
                                                </li>
                                                <li>
                                                    <span>Date Posted</span>
                                                    <span>{{data.posting_date}}</span>
                                                </li>
                                                <li>
                                                    <span>ATS Posting</span>
                                                    <span>{{data.ats | uppercase}}</span>
                                                </li>

                                            </ul>
                                        </div>
                                    </div>
                                    <div nz-col [nzSpan]="12">
                                        <div class="info-list">
                                            <h4>Details</h4>
                                            <ul>
                                                <li>
                                                    <span>Position</span>
                                                    <span>{{data.position}}</span>
                                                </li>
                                                <li>
                                                    <span>Location</span>
                                                    <span>{{data.location}}</span>
                                                </li>
                                                <li>
                                                    <span>Role Duration</span>
                                                    <span>{{data.role_duration}}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div nz-col [nzSpan]="12" *ngIf="data.education">
                                        <div class="info-list">
                                            <h4>Education</h4>
                                            <ul>
                                                <li>
                                                    <span>{{data.education}}</span>
                                                </li>

                                            </ul>
                                        </div>
                                    </div>
                                    <div nz-col [nzSpan]="12" *ngIf="data.salary">
                                        <div class="info-list">
                                            <h4>Salary</h4>
                                            <ul>
                                                <li>
                                                    <span>{{data.salary}}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="full-detail">
                                    <button nz-button nzType="default" nzBlock (click)="toggleFullDetails(i)">See {{
                                        showFullDetails[i] ? 'Less' : 'Full' }}
                                        Details</button>
                                    <div *ngIf="showFullDetails[i]">
                                        <div class="decription-content"
                                            [innerHTML]="replaceEscapeSequences(data.description)"></div>
                                    </div>
                                </div> -->
                                <ng-container *ngIf="!isLast">
                                    <nz-divider nzText="And"></nz-divider>
                                </ng-container>
                            </div>
                        </div>

                    </ng-container>
                    <div class="custom-footer">
                        <button nz-button nzBlock nzSize="large" nzType="primary" [disabled]="setOfCheckedId.size === 0"
                            nzShape="round" (click)="nextStep()">
                            <span *ngIf="jobsMgr.isLoadingImportingJobs" nz-icon nzType="loading"
                                nzTheme="outline"></span>{{currentStep === 1 ? 'Submit' : 'Next'}}
                        </button>
                        <button nz-button nzBlock nzSize="large" nzType="link" (click)="prevStep()" nzShape="round"
                            *ngIf="currentStep > 0">Previous</button>
                    </div>
                </div>

            </ng-container>
        </nz-modal>

    </main>
</div>