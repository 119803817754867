import { Injectable } from '@angular/core';
import { ApplicationsService } from '../Services/applications.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { candidate, jobDetail, stepsOptions, counts } from '../models/models';

@Injectable({
  providedIn: 'root',
})
export class ApplicationManagerService {
  scoreCalculated: string = 'done';
  public page = 1;
  minScore = 0;
  maxScore = 100;
  search: number = 0;
  jobObjectId = 0;
  isSearching = false;
  range = [0, 100];
  count_all_applications = 0;
  count_not_qualified = 0;
  count_pre_qualified = 0;
  count_qualified = 0;
  count_reject = 0;
  count_final_decision = 0;
  nextPageUrl: string | null = null;
  isSharing: boolean = false;
  isThumbLoading: boolean = false;
  isLoadingJobDetail: boolean = false;
  gettingAllApplication: boolean = false;
  updatingSegment: boolean = false;
  public isVisibleShareModal: boolean = false;
  public isVisibleAdvancedModal: boolean = false;
  public isVisibleAdvancedBulkModal: boolean = false;
  public isVisibleRejectBulkModal: boolean = false;
  public isVisibleRejectModal: boolean = false;
  public isVisibleThumbsModal: boolean = false;

  public all_applications: candidate[] = [];
  public not_qualified: candidate[] = [];
  public pre_qualified: candidate[] = [];
  public qualified: candidate[] = [];
  public reject: candidate[] = [];
  public final_decision: candidate[] = [];
  public hiringMangerSelections: candidate[] = [];
  public screeningStepsOptions: stepsOptions[] = [];
  public currentCounts!: counts;
  public countsLoading: boolean = false;
  public candidateLocationsData: string[] = [];
  public jobDetailData!: jobDetail;
  public requirementsData: any[] = [];

  public getCandidateBytype(type: string): candidate[] {
    let candaiteList: candidate[] = [];
    switch (type) {
      case 'not_qualified':
        candaiteList = this.not_qualified;
        break;
      case 'pre_qualified':
        candaiteList = this.pre_qualified;
        break;
      case 'qualified':
        candaiteList = this.qualified;
        break;
      default:
        // If type is empty or doesn't match, return all candidates
        candaiteList = [
          ...this.not_qualified,
          ...this.pre_qualified,
          ...this.qualified,
        ];
        break;
    }
    return candaiteList;
  }
  public updateCandidates(type: string, updatedCandidates: candidate[]): void {
    switch (type) {
      case 'not_qualified':
        this.not_qualified = updatedCandidates;
        break;
      case 'pre_qualified':
        this.pre_qualified = updatedCandidates;
        break;
      case 'qualified':
        this.qualified = updatedCandidates;
        break;
    }
  }
  constructor(
    public applicationService: ApplicationsService,
    private notification: NzNotificationService
  ) {}

  public getNotQualified(
    jobObjectId: string,
    segment: string,
    search: string,
    min_score: number,
    max_score: number,
    is_adjust_ai_settings: boolean,
    advance: boolean,
    reject: boolean,
    share: boolean,
    thumbs_down: boolean,
    thumbs_up: boolean,
    answered_followup_questions: boolean,
    locations: string,
    job_req_id: string,
    job_req_name: string
  ) {
    this.gettingAllApplication = true;
    this.applicationService
      .getOthersApplications(
        jobObjectId,
        segment,
        search,
        min_score,
        max_score,
        is_adjust_ai_settings,
        advance,
        reject,
        share,
        thumbs_down,
        thumbs_up,
        answered_followup_questions,
        locations,
        job_req_id,
        job_req_name
      )
      .subscribe((c: any) => {
        this.not_qualified = [];
        this.not_qualified = c.response;
        this.gettingAllApplication = false;
      });
  }
  public getPreQualified(
    jobObjectId: string,
    segment: string,
    search: string,
    min_score: number,
    max_score: number,
    is_adjust_ai_setting: boolean,
    advance: boolean,
    reject: boolean,
    share: boolean,
    thumbs_down: boolean,
    thumbs_up: boolean,
    answered_followup_questions: boolean,
    locations: string,
    job_req_id: string,
    job_req_name: string
  ): Promise<void> {
    this.gettingAllApplication = true;
    return new Promise<void>((resolve) => {
      this.applicationService
        .getOthersApplications(
          jobObjectId,
          segment,
          search,
          min_score,
          max_score,
          is_adjust_ai_setting,
          advance,
          reject,
          share,
          thumbs_down,
          thumbs_up,
          answered_followup_questions,
          locations,
          job_req_id,
          job_req_name
        )
        .subscribe((c: any) => {
          this.pre_qualified = [];
          this.pre_qualified = c.response;
          this.gettingAllApplication = false;
          resolve();
        });
    });
  }
  public getQualified(
    jobObjectId: string,
    segment: string,
    search: string,
    min_score: number,
    max_score: number,
    is_adjust_ai_setting: boolean,
    advance: boolean,
    reject: boolean,
    share: boolean,
    thumbs_down: boolean,
    thumbs_up: boolean,
    answered_followup_questions: boolean,
    locations: string,
    job_req_id: string,
    job_req_name: string
  ): Promise<void> {
    this.gettingAllApplication = true;
    return new Promise<void>((resolve) => {
      this.applicationService
        .getOthersApplications(
          jobObjectId,
          segment,
          search,
          min_score,
          max_score,
          is_adjust_ai_setting,
          advance,
          reject,
          share,
          thumbs_down,
          thumbs_up,
          answered_followup_questions,
          locations,
          job_req_id,
          job_req_name
        )
        .subscribe((c: any) => {
          this.qualified = [];
          this.qualified = c.response;
          this.gettingAllApplication = false;
          resolve();
        });
    });
  }

  public shareApplications(data: any): Promise<void> {
    return new Promise<void>((resolve) => {
      this.isSharing = true;
      this.applicationService.shareApplications(data).subscribe({
        next: (c: any) => {
          this.isVisibleShareModal = false;
          this.isSharing = false;
          this.notification.create(
            'success',
            'Shared Succesful',
            'Candidate(s) shared with Hiring Manager succesfully.'
          );
          resolve();
        },
        error: (error: any) => {
          this.notification.create('error', 'Error', error.error.error);
          this.isSharing = false;
        },
      });
    });
  }
  public submitAdvancedApplications(data: any): Promise<void> {
    return new Promise<void>((resolve) => {
      this.isSharing = true;
      this.applicationService.shareApplications(data).subscribe({
        next: (c: any) => {
          this.isVisibleAdvancedModal = false;
          this.isVisibleAdvancedBulkModal = false;
          this.isSharing = false;
          this.notification.create('success', 'Candidate(s) Advanced', '');
          resolve();
        },

        error: (error: any) => {
          console.log(error);
          if (error.error?.error) {
            const err = error.error?.error;
            this.notification.create('error', 'Error', err);
          }
          this.isSharing = false;
        },
      });
    });
  }
  public submitThumbAction(
    data: any,
    application_obj_id: string,
    job_obj_id: string
  ): Promise<void> {
    return new Promise<void>((resolve) => {
      this.isThumbLoading = true;
      this.applicationService
        .submitThumbAction(data, application_obj_id, job_obj_id)
        .subscribe({
          next: (c: any) => {
            this.isVisibleThumbsModal = false;
            this.isThumbLoading = false;
            resolve();
          },

          error: (error: any) => {
            this.notification.create('error', 'Error', error.error.error);
            this.isThumbLoading = false;
          },
        });
    });
  }
  public submitRejectApplications(data: any): Promise<void> {
    return new Promise<void>((resolve) => {
      this.isSharing = true;
      this.applicationService.shareApplications(data).subscribe({
        next: (c: any) => {
          this.isVisibleRejectModal = false;
          this.isVisibleRejectBulkModal = false;
          this.isSharing = false;
          this.notification.create('success', 'Candidate(s) Rejected', '');
          resolve();
        },
        error: (error: any) => {
          this.notification.create('error', 'Error', error.error.error);
          this.isSharing = false;
        },
      });
    });
  }
  public hiringManagerSelection(jobId: string): Promise<void> {
    return new Promise<void>((resolve) => {
      this.applicationService
        .hiringManagerSelection(jobId)
        .subscribe((c: any) => {
          this.hiringMangerSelections = c.response;
          resolve();
        });
    });
  }
  public screeningStepsDropdown(jobId: string): Promise<void> {
    return new Promise<void>((resolve) => {
      this.applicationService
        .screeningStepsDropdown(jobId)
        .subscribe((c: any) => {
          this.screeningStepsOptions = c.response;
          resolve();
        });
    });
  }
  public jobApplicationCounts(jobId: string): Promise<void> {
    this.countsLoading = true;
    return new Promise<void>((resolve) => {
      this.applicationService
        .jobApplicationCounts(jobId)
        .subscribe((c: any) => {
          this.currentCounts = c.response;
          this.countsLoading = false;
          resolve();
        });
    });
  }
  public candidateLocations(jobId: string): Promise<void> {
    return new Promise<void>((resolve) => {
      this.applicationService.candidateLocations(jobId).subscribe((c: any) => {
        this.candidateLocationsData = c.response;
        console.log(this.candidateLocationsData);
        resolve();
      });
    });
  }

  public jobDetail(jobObjectId: string, callback: () => void): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.isLoadingJobDetail = true;
      this.applicationService.jobDetail(jobObjectId).subscribe({
        next: (c: any) => {
          this.jobDetailData = c.response;

          // Extracted details from the response
          const extractedDetails = c.response.extracted_details;
          this.requirementsData = [];
          if (extractedDetails) {
            const extractDescriptions = (
              items: any,
              requirmentName: string
            ) => {
              items.forEach((item: any) => {
                if (item.description) {
                  this.requirementsData.push({
                    id: item.id,
                    requirmentName: requirmentName,
                    description: item.description,
                  });
                }
              });
            };

            // Extract descriptions from the required fields
            extractDescriptions(
              extractedDetails.required_experience,
              'required_experience'
            );
            extractDescriptions(
              extractedDetails.nice_to_have_experience,
              'nice_to_have_experience'
            );
            extractDescriptions(
              extractedDetails.required_skills,
              'required_skills'
            );
            extractDescriptions(
              extractedDetails.nice_to_have_skills,
              'nice_to_have_skills'
            );
            extractDescriptions(
              extractedDetails.people_skills,
              'people_skills'
            );

            // Check if degree exists in education and add it to requirementsData
            if (
              extractedDetails.education &&
              extractedDetails.education.degree
            ) {
              this.requirementsData.push({
                id: 1,
                requirmentName: 'education',
                description: extractedDetails.education.degree,
              });
            }
          }

          this.isLoadingJobDetail = false;
          callback();
          resolve();
        },
        error: (error: any) => {
          this.notification.create('error', 'Error', error.error.error);
          this.isLoadingJobDetail = false;
          reject(error);
        },
      });
    });
  }

  public getApplicationsByUrl(jobObjectId: string, nextPageUrl: any) {
    this.applicationService
      .getApplicationsByUrl(jobObjectId, nextPageUrl)
      .subscribe({
        next: (c: any) => {
          this.all_applications = [
            ...this.all_applications,
            ...c.response.results,
          ];

          this.nextPageUrl = Object(c.response)['next'];
        },
        error: (error: any) => {
          this.notification.create('error', 'Error', error.error.error);
        },
      });
  }

  public updateSegment(segment: any) {
    this.updatingSegment = true;
    this.applicationService.updateSegment(segment).subscribe({
      next: (c: any) => {
        this.updatingSegment = false;
      },
      error: (error: any) => {
        this.notification.create('error', 'Error', error.error.error);
        this.updatingSegment = false;
      },
    });
  }
  public bookmarkApplication(id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.applicationService.bookmarkApplication(id).subscribe({
        next: (c: any) => {
          this.notification.create(
            'success',
            'Application Bookmarked',
            'Application has been successfully bookmarked!'
          );
          resolve();
        },
        error: (error: any) => {
          this.notification.create('error', 'Error', error.error.error);
          reject(error);
        },
      });
    });
  }

  public removeBookmarkApplication(id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.applicationService.removeBookmarkApplication(id).subscribe({
        next: () => {
          this.notification.create(
            'success',
            'Removed Application',
            'The bookmark for the application has been successfully removed'
          );
          resolve();
        },
        error: (error: any) => {
          this.notification.create('error', 'Error', error.error.error);
          reject(error);
        },
      });
    });
  }
}
