import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SjmManagerService } from 'src/app/Managers/sjm-manager.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-upload-candidates',
  templateUrl: './upload-candidates.component.html',
  styleUrl: './upload-candidates.component.scss',
})
export class UploadCandidatesComponent {
  currentStep: number = 1;
  totalSteps: number = 4;
  selectedFiles: File[] = [];
  jobObjectId: string = '';
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public sjmMgr: SjmManagerService,
    private notification: NzNotificationService
  ) {}
  getStepClass(step: number): string {
    if (step < this.currentStep) {
      return 'done';
    } else if (step === this.currentStep) {
      return 'active';
    } else {
      return 'inactive';
    }
  }
  nextStep(): void {
    if (this.currentStep < this.totalSteps) {
      this.currentStep++;
    } else {
      //this.submitForm();
    }
  }

  previousStep(): void {
    if (this.currentStep > 1) {
      this.currentStep--;
    }
  }
  onFilesSelected(files: any): void {
    this.selectedFiles = files;
  }
  async uploadResums(): Promise<void> {
    await this.sjmMgr.uploadResumes(this.selectedFiles, this.jobObjectId);
    this.currentStep++;
  }
  async submitFinalization() {
    let applicationsIds = this.sjmMgr.resumeFinilizeData.map((resume) => ({
      application_obj_id: resume.application_obj_id,
      process_status: resume.process === 'ready_to_process',
    }));

    let payload = {
      candidates: applicationsIds,
    };
    await this.sjmMgr.updateCandidateResumeProcess(payload, this.jobObjectId);

    this.notification.create(
      'success',
      'Candidates uploaded and processing',
      ''
    );
    const processing = 'processing';

    this.router.navigate(['jobs/candidates/', this.jobObjectId], {
      queryParams: { processing },
    });
  }

  async ngOnInit() {
    this.route.params.subscribe(async (params) => {
      this.jobObjectId = params['jobObjectId'];
    });
  }
  ngOnDestroy() {
    this.sjmMgr.resumeFinilizeData = [];
  }
}
