// working
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';

import { AuthenticationService } from 'src/app/Services/authentication.service';
import { PermissionService } from 'src/app/Services/permission.service';

export const authGuard = (route: ActivatedRouteSnapshot) => {
  const authService = inject(AuthenticationService);
  const permissionService = inject(PermissionService);
  const router = inject(Router);

  if (authService.isLoggedIn()) {
    const permission = route.data['permission']; // Obtain permission data from the route
    if (permission) {
      const hasPermission = permissionService.hasPermission(
        permission.content_type,
        permission.desiredPermission
      );
      if (!hasPermission) {
        return router.parseUrl('/not-authorized') as UrlTree;
      }
    }

    return true;
  }

  return router.parseUrl('/login') as UrlTree;
};
