import { Injectable } from '@angular/core';
import { ImpersonationService } from '../Services/impersonation.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { user, partners, users } from '../models/users';
import { PermissionService } from 'src/app/Services/permission.service';
import { UsersManagerService } from 'src/app/Managers/users-manager.service';
import { Router, ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ImpersonationManagerService {
  public isLoadingPage: boolean = false;
  public impersonating: boolean = false;
  public isLoadingMorePartner = false;
  partnersData: partners[] = [];
  partnersUsers: user[] = [];
  public totalPartners: number = 0;
  nextPageUrl: string | null = null;
  public partnerPage = 1;
  constructor(
    private notification: NzNotificationService,
    private impersonationService: ImpersonationService,
    private router: Router,
    private route: ActivatedRoute,
    private permissionService: PermissionService,
    public userMgr: UsersManagerService
  ) {}
  public getPartners(page: number) {
    this.isLoadingPage = true;
    this.impersonationService.getPartners(page).subscribe((c: any) => {
      this.partnersData = c.response.results;
      this.totalPartners = Object(c.response)['count'];
      this.nextPageUrl = Object(c.response)['next'];
      this.isLoadingPage = false;
    });
  }
  public currentImpersonation() {
    this.isLoadingPage = true;
    this.impersonationService.currentImpersonation().subscribe((c: any) => {
      this.impersonating = c.impersonating;
      if (this.impersonating && this.router.url === '/impersonation') {
        this.handleImpersonation(c.data);
      }

      this.isLoadingPage = false;
    });
  }
  handleImpersonation(data: any): void {
    localStorage.removeItem('user');
    this.permissionService.clearPermissionsOnLogout();
    localStorage.setItem('user', JSON.stringify(data));
    this.permissionService.updatePermissions(data);
    this.router.navigate(['/']);
  }
  handleStopImpersonation(data: any): void {
    localStorage.removeItem('user');
    this.permissionService.clearPermissionsOnLogout();
    localStorage.setItem('user', JSON.stringify(data));
    this.permissionService.updatePermissions(data);
    this.router.navigateByUrl('/').then(() => {
      window.location.reload();
    });
  }
  public getPartnersMore(page: number) {
    this.isLoadingPage = true;
    this.isLoadingMorePartner = true;
    this.impersonationService.getPartners(page).subscribe((c: any) => {
      this.partnersData = [...this.partnersData, ...c.response.results];
      this.totalPartners = Object(c.response)['count'];
      this.nextPageUrl = Object(c.response)['next'];
      this.isLoadingPage = false;
      this.isLoadingMorePartner = false;
    });
  }
  public getPartnersusers(id: number) {
    this.isLoadingPage = true;
    this.impersonationService.getPartnersusers(id).subscribe((c: any) => {
      this.partnersUsers = c.response;
      if (this.partnersUsers.length === 0) {
        this.notification.create(
          'info',
          'There is no User associated with this partner!',
          ''
        );
      }
      this.isLoadingPage = false;
    });
  }
  public startImpersonation(id: number, reason: string) {
    this.isLoadingPage = true;
    this.impersonationService
      .startImpersonation(id, reason)
      .subscribe((c: any) => {
        this.partnersUsers = c.response;
        this.notification.create('success', 'User Impersonation started!', '');
        this.currentImpersonation();
        this.isLoadingPage = false;
      });
  }
  public exitImpersonation() {
    this.isLoadingPage = true;
    this.impersonationService.exitImpersonation().subscribe((c: any) => {
      this.notification.create('success', 'User Impersonation stopped!', '');
      this.currentImpersonation();
      this.handleStopImpersonation(c.data);
      this.isLoadingPage = false;
    });
  }
}
