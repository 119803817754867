<div class="page">
    <!-- Side menu -->
    <app-side-menu></app-side-menu>
    <!-- main -->
    <main class="main">
        <nz-breadcrumb [nzSeparator]="iconTemplate">
            <nz-breadcrumb-item>
                <a routerLink="/"><span nz-icon nzType="home"></span></a>
            </nz-breadcrumb-item>
            <nz-breadcrumb-item><a class="breadcrumb-menu-item"
                    (click)="toggleSubmenu()">Settings</a></nz-breadcrumb-item>
            <nz-breadcrumb-item>Screening Actions</nz-breadcrumb-item>
        </nz-breadcrumb>
        <nz-spin [nzSpinning]="screeningMgr.isLoading">
            <div nz-row [nzGutter]="16">
                <div nz-col>
                    <div class="heading-flex">
                        <h1>Screening Actions</h1>
                        <p>CLARA can map actions to your Applicant Tracking System (ATS). Use the switch <br> to enable
                            CLARA
                            to synchronize statuses
                            with your ATS.</p>
                    </div>

                    <div class="description-list" *ngIf="screeningMgr.screeningActionsData">
                        <div *ngFor="let key of objectKeys(screeningMgr.screeningActionsData)">
                            <div *ngIf="screeningMgr.screeningActionsData[key]">
                                <div class="description-list-item">
                                    <div class="description-list-term">{{ key | titlecase }}</div>
                                    <div class="description-list-detail">
                                        <nz-switch
                                            [(ngModel)]="screeningMgr.screeningActionsData[key].map_actions_ats_to_clara"
                                            (ngModelChange)="changeScreeningAction(screeningMgr.screeningActionsData[key].map_actions_ats_to_clara, key)"
                                            [nzDisabled]="!screeningMgr.screeningActionsData[key].ats_mapping_available"></nz-switch>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </nz-spin>
        <nz-modal [(nzVisible)]="screeningMgr.isVisiableScreeningAction" (nzOnCancel)="removeScreeningCancel()"
            [nzFooter]="null" nzWidth="572px" [nzClosable]="false">
            <ng-container *nzModalContent>
                <div class="center-form">
                    <div class="form-header">
                        <h2 class="font-heading">Screening Actions {{actionType ? 'On' : 'Off'}}</h2>
                    </div>
                    <ng-container>
                        <div class="gray-alert">
                            <div *ngIf="actionType === false">
                                By switching ‘off’ the Screening Actions, CLARA will no longer map actions to and from
                                your
                                Applicant Tracking System
                                (ATS).
                            </div>
                            <div *ngIf="actionType">
                                By switching ‘on’ the Screening Actions, CLARA will map actions to and from your
                                Applicant Tracking System (ATS).
                            </div>
                        </div>

                    </ng-container>
                    <div class="custom-footer">
                        <button (click)="submitScreeningAction(actionType)" class="btn-theme-clara mb-10 d-block"
                            [ngClass]="actionType  ? 'btn-purple-color' : 'btn-danger-color'"><span
                                *ngIf="screeningMgr.isLoadingBtn" nz-icon nzType="loading"
                                nzTheme="outline"></span>Confirm</button>
                        <button nz-button nzType="text" nzSize="large" nzBlock (click)="removeScreeningCancel()"
                            nzShape="round">Cancel</button>
                    </div>

                </div>
            </ng-container>
        </nz-modal>

        <ng-template #iconTemplate><span nz-icon nzType="right" nzTheme="outline"></span></ng-template>
    </main>
</div>