import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class JobAISettingsService {
  constructor(private http: HttpClient) {}

  aiServices(jobId: any) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.get(
      environment.apiURL + `deincore/partner/job/${jobId}/settings/ai/`,
      {
        headers: header,
      }
    );
  }
  updateAiSettings(formData: any, jobId: any) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.post(
      environment.apiURL + `deincore/partner/job/${jobId}/settings/ai/`,
      formData,
      {
        headers: header,
      }
    );
  }
}
