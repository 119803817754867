import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'intials',
})
export class IntialsPipe implements PipeTransform {
  transform(fullName: string): string {
    if (!fullName) {
      return '';
    }

    const initials = fullName
      .split(' ')
      .map((name) => name.charAt(0))
      .join('');

    return initials;
  }
}
