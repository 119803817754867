import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-all-jobs-overview',
  templateUrl: './all-jobs-overview.component.html',
  styleUrl: './all-jobs-overview.component.scss',
})
export class AllJobsOverviewComponent {
  @Input() data: any;
  user: any;
  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user') || '{}');
  }
}
