import { Component, EventEmitter, Output, Input } from '@angular/core';
import { AssignJobManagerService } from 'src/app/Managers/assign-job-manager.service';
import { PermissionService } from 'src/app/Services/permission.service';

export interface Data {
  id: number;
  jobName: string;
  role: string;
  disabled: boolean;
}

@Component({
  selector: 'app-assign-job',
  templateUrl: './assign-job.component.html',
  styleUrls: ['./assign-job.component.scss'],
})
export class AssignJobComponent {
  @Input() jobObjectId!: string;
  @Output() okClicked = new EventEmitter<void>();
  @Output() cancelClicked = new EventEmitter<void>();
  @Output() submitAssignJobEvent = new EventEmitter<void>();
  user: any;
  isVisible = false;
  selectedUserIds: number[] = [];
  checked = false;
  indeterminate = false;
  setOfCheckedId = new Set<number>();

  constructor(
    public userMgr: AssignJobManagerService,
    public permissionService: PermissionService
  ) {}

  handleOk(): void {
    this.isVisible = false;
    this.okClicked.emit();
  }

  handleCancel(): void {
    this.isVisible = false;
    this.cancelClicked.emit();
  }

  onChangeUser(userId: number): void {
    this.selectedUserIds = [userId];
  }

  onAllChecked(checked: boolean): void {
    this.userMgr.usersRecruiter.forEach((user) =>
      this.updateCheckedSet(user.id, checked)
    );
    this.refreshCheckedStatus();
  }

  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }

  refreshCheckedStatus(): void {
    this.checked = this.userMgr.usersRecruiter.every((user) =>
      this.setOfCheckedId.has(user.id)
    );
    this.indeterminate =
      this.userMgr.usersRecruiter.some((user) =>
        this.setOfCheckedId.has(user.id)
      ) && !this.checked;
  }

  async assignJob(): Promise<void> {
    // console.log('ddd', this.setOfCheckedId);
    // return;
    const data = {
      job_obj_id: this.jobObjectId,
      user_ids: Array.from(this.setOfCheckedId).concat(this.selectedUserIds),
    };
    await this.userMgr.assignJob(data);
    this.submitAssignJobEvent.emit();
  }

  async ngOnInit(): Promise<void> {
    this.user = JSON.parse(localStorage.getItem('user') || '{}');
    const userRole = this.user.role?.trim();
    this.userMgr.jobObjectId = this.jobObjectId;
    if (userRole === 'Partner Admin' || userRole === 'Account Owner') {
      await this.userMgr.getHR('Hiring Manager');
      await this.userMgr.getRecruiter('Recruiter');
      await this.userMgr.getAssignJobs(this.jobObjectId);

      this.userMgr.usersRecruiter.forEach((user) => {
        if (user.isAssignedJob) {
          this.setOfCheckedId.add(user.id);
        }
      });

      this.refreshCheckedStatus();
    }
  }
}
