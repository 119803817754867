<div class="page">
    <!-- Side menu -->
    <app-side-menu></app-side-menu>
    <!-- main -->
    <main class="main">
        <nz-breadcrumb>
            <nz-breadcrumb-item>
                <a routerLink="/"><span nz-icon nzType="home"></span></a>
            </nz-breadcrumb-item>
            <nz-breadcrumb-item><a class="breadcrumb-menu-item"
                    (click)="toggleSubmenu()">Settings</a></nz-breadcrumb-item>
            <nz-breadcrumb-item>Notifications</nz-breadcrumb-item>
        </nz-breadcrumb>

        <div nz-row [nzGutter]="16">
            <div nz-col [nzSpan]="8">

                <div class="heading-flex">
                    <h1>Notifications</h1>
                </div>
                <p>Set your notifications preferences below.</p>
                <nz-spin [nzSpinning]="notificatinMgr.isLoading">
                    <nz-table #basicTable [nzData]="notificatinMgr.notificationsSettingData"
                        [nzFrontPagination]="false">
                        <thead>
                            <tr>
                                <th>Notification Channel</th>
                                <th>Email Notifications</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of basicTable.data">
                                <td>{{data.name}}</td>
                                <td><nz-switch [(ngModel)]="data.value"
                                        (ngModelChange)="changeNotificationsSettings(data)"></nz-switch></td>
                            </tr>
                        </tbody>
                    </nz-table>
                </nz-spin>

            </div>
        </div>

    </main>
</div>