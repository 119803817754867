<div class="page bg-gradient">

    <!-- Side menu -->
    <app-side-menu></app-side-menu>
    <!-- main -->
    <main class="main">
        <div nz-row [nzGutter]="20" nzJustify="space-between" nzAlign="middle">
            <div nz-col [nzSpan]="16">
                <h1 class="font-heading">Welcome back, <span class="capitalize">{{user.user_profile ?
                        user.user_profile.first_name : "Admin"}}</span></h1>
            </div>

        </div>



        <div class="info-box">

            <h2 class="font-heading">Welcome to CLARA!</h2>
            <p>To get CLARA working, you will have to import jobs by creating an integration to your applicant
                tracking system (ATS).
                Let’s do that!</p>
            <a routerLink="/integrations"> <button nz-button nzType="primary" nzSize="large" nzShape="round" nzBlock>Go
                    to
                    Integrations</button></a>
        </div>



    </main>
</div>