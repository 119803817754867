import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class SjmService {
  constructor(private http: HttpClient) {}
  getCall() {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.get(environment.apiURL + `settings/ai/`, {
      headers: header,
    });
  }
  uploadJobFile(data: any) {
    const formData = new FormData();
    formData.append('title', data.title);
    formData.append('file', data.file);
    formData.append('location', data.location);
    return this.http.post(
      environment.apiURL + `deincore/partner/jobs/standalone/upload-file`,
      formData
    );
  }
  editDescription(data: any, id: string) {
    let header = new HttpHeaders({
      Accept: 'application/json',
    });

    return this.http.put(
      environment.apiURL +
        `deincore/partner/jobs/standalone/edit-description/${id}`,
      data,
      {
        headers: header,
      }
    );
  }
  patchAiSettings(data: any, id: string) {
    let header = new HttpHeaders({
      Accept: 'application/json',
    });

    return this.http.patch(
      environment.apiURL + `deincore/partner/jobs/standalone/settings/ai/${id}`,
      data,
      {
        headers: header,
      }
    );
  }
  uploadSingleResumeAPI(formData: FormData, jobObjectId: string) {
    return this.http.post(
      environment.apiURL +
        `deincore/partner/jobs/standalone/upload-candidate-resume/${jobObjectId}`,
      formData
    );
  }
  updateCandidateResumeProcess(data: any, id: string) {
    let header = new HttpHeaders({
      Accept: 'application/json',
    });

    return this.http.patch(
      environment.apiURL +
        `deincore/partner/jobs/standalone/edit-candidates-resume-process/${id}`,
      data,
      {
        headers: header,
      }
    );
  }
  jobDetail(jobObjectId: string) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });

    return this.http.get(
      environment.apiURL + `deincore/partner/jobs/${jobObjectId}/detail/`,
      {
        headers: header,
      }
    );
  }
  addMateriaAndLinks(data: any, id: string) {
    let header = new HttpHeaders({
      Accept: 'application/json',
    });

    return this.http.post(
      environment.apiURL +
        `deincore/partner/jobs/standalone/add-materials-and-links/${id}`,
      data,
      {
        headers: header,
      }
    );
  }
  validateEmail(data: any) {
    let header = new HttpHeaders({
      Accept: 'application/json',
    });

    return this.http.post(
      environment.apiURL +
        `deincore/partner/jobs/standalone/apply-job/validate-email/`,
      data,
      {
        headers: header,
      }
    );
  }
  uploadCanidateResumeAndProfile(data: any, job_obj_id: string) {
    const formData = new FormData();
    formData.append('first_name', data.first_name);
    formData.append('last_name', data.last_name);
    formData.append('email', data.email);
    formData.append('file', data.file);
    return this.http.post(
      environment.apiURL +
        `deincore/partner/jobs/standalone/apply-job/upload-candidate-resume/${job_obj_id}`,
      formData
    );
  }

  uploadCanidateMaterials(
    data: any,
    job_obj_id: string,
    application_obj_id: string
  ) {
    const formData = new FormData();
    formData.append('material_name', data.material_name);
    formData.append('file', data.file);
    return this.http.post(
      environment.apiURL +
        `deincore/partner/jobs/standalone/apply-job/upload-candidate-material/${job_obj_id}/${application_obj_id}`,
      formData
    );
  }
  submitCandidateLinks(data: any, application_obj_id: string) {
    let header = new HttpHeaders({
      Accept: 'application/json',
    });

    return this.http.post(
      environment.apiURL +
        `deincore/partner/jobs/standalone/apply-job/candidate-links/${application_obj_id}`,
      data,
      {
        headers: header,
      }
    );
  }
  getJobInformation(job_obj_id: string) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });

    return this.http.get(
      environment.apiURL + `deincore/partner/apply-job/${job_obj_id}/detail/`,
      {
        headers: header,
      }
    );
  }
}
