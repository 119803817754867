import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-job-detail',
  templateUrl: './job-detail.component.html',
  styleUrl: './job-detail.component.scss',
})
export class JobDetailComponent {
  @Input() jobDetailData: any;
  replaceEscapeSequences(html: any): any {
    if (html == null) {
      return '';
    }
    return html
      .replace(/\\n/g, '')
      .replace(/\n/g, '')
      .replace(/\\f/g, '')
      .replace(/\f/g, '')
      .replace(/\\t/g, '&emsp;')
      .replace(/\t/g, '&emsp;')
      .replace(/\\u00a0/g, '&nbsp;')
      .replace(/\\u0026/g, '&amp;');
  }
}
