import { Component, ViewChild } from '@angular/core';
import { AiSettingsManagerService } from 'src/app/Managers/ai-settings-manager.service';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { ChartComponent } from 'ng-apexcharts';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { AuthenticationService } from 'src/app/Services/authentication.service';

import {
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexChart,
  ApexLegend,
  ApexTheme,
  ApexDataLabels,
  ApexPlotOptions,
  ApexStroke,
} from 'ng-apexcharts';
export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
  legend: ApexLegend;
  theme: ApexTheme;
  dataLabels: ApexDataLabels;
  plotOptions?: ApexPlotOptions;
  stroke?: ApexStroke;
};

@Component({
  selector: 'app-aisettings',
  templateUrl: './aisettings.component.html',
  styleUrl: './aisettings.component.scss',
})
export class AISettingsComponent {
  @ViewChild('chart') chart!: ChartComponent;
  public chartOptions!: Partial<ChartOptions>;

  isAddingVairablesBox = false;
  validateForm!: FormGroup;
  deIndentifyForm!: FormGroup;
  checkedSettings: any[] = [];
  partnerId: string = '';

  constructor(
    private fb: UntypedFormBuilder,
    public aiManager: AiSettingsManagerService,
    private notification: NzNotificationService,
    public auth: AuthenticationService
  ) {}

  editAiSettingsModal(values: any, opemModal: string): void {
    Object.keys(this.validateForm.controls).forEach((key) => {
      this.validateForm.removeControl(key);
    });
    values.forEach((setting: { key: any; value: any }) => {
      this.validateForm.addControl(setting.key, this.fb.control(setting.value));
    });
    if (opemModal === 'open') {
      this.aiManager.isVisibleAiModal = true;
    }
  }

  handleAiCancel(): void {
    this.aiManager.isVisibleAiModal = false;
  }

  async openDeInModal(data: any): Promise<void> {
    await this.aiManager.getDeIdentification();
    this.deIndentifyForm = this.fb.group({
      de_identify_name: [data.de_identify_name],
      de_identify_education: [data.de_identify_education],
      de_identify_experience: [data.de_identify_experience],
    });
    this.aiManager.isVisibleDeIndModal = true;
  }
  async submitDeIndentifySettings(): Promise<void> {
    if (this.deIndentifyForm.valid) {
      const formData = this.deIndentifyForm.value;
      await this.aiManager.updateDeIdentification(formData);
    }
  }
  handleDeInCancel(): void {
    this.aiManager.isVisibleDeIndModal = false;
  }
  async submitAiSettings(): Promise<void> {
    if (this.validateForm.valid) {
      const hasNonZeroValue = Object.values(this.validateForm.value).some(
        (value: unknown) => {
          const numValue = Number(value);
          return typeof numValue === 'number' && numValue > 0;
        }
      );

      if (hasNonZeroValue) {
        try {
          await this.aiManager.updateAiSettings(this.validateForm.value);
          this.createDonutChart(this.aiManager.aisSettingsActive);
          this.editAiSettingsModal(this.aiManager.aisSettingsActive, 'close');
        } catch (error) {}
      } else {
        this.notification.create(
          'error',
          'Error',
          'At least one value should be greater than zero'
        );
      }
    } else {
      Object.values(this.validateForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  getOptions(): number[] {
    return [0, 1, 2, 3, 4, 5];
  }
  addingVariableBox(): void {
    this.checkedSettings = [];
    this.isAddingVairablesBox = !this.isAddingVairablesBox;
  }
  submitNewSettingsForm() {
    // const newSettingsToAdd = this.checkedSettings.map((setting) => ({
    //   ...setting,
    //   value: 1,
    //   editable: true,
    // }));

    // for (const editable of newSettingsToAdd) {
    //   const isDuplicate = this.aiManager.aisSettingsActive.find(
    //     (existingSetting) => existingSetting.key === editable.key
    //   );

    //   if (!isDuplicate) {
    //     this.aiManager.aisSettingsActive.push(editable);
    //   }
    // }

    // this.editAiSettingsModal(this.aiManager.aisSettingsActive, 'close');

    // this.isAddingVairablesBox = false;
    const newSettingsToAdd = this.checkedSettings.map((setting) => ({
      ...setting,
      value: 1,
      editable: true,
    }));
    for (const editable of newSettingsToAdd) {
      const isDuplicate = this.aiManager.aisSettingsActive.find(
        (existingSetting) => existingSetting.key === editable.key
      );

      if (!isDuplicate) {
        this.aiManager.aisSettingsActive.push(editable);
        // Remove the added setting from aiManager.aisSettingsInactive
        const inactiveIndex = this.aiManager.aisSettingsInactive.findIndex(
          (setting) => setting.key === editable.key
        );
        if (inactiveIndex !== -1) {
          this.aiManager.aisSettingsInactive.splice(inactiveIndex, 1);
        }
      }
    }

    // Recalculate percentages
    const totalWeight = this.aiManager.aisSettingsActive.reduce(
      (sum, item) => sum + item.value,
      0
    );
    this.aiManager.aisSettingsActive.forEach((item) => {
      item.field_percentage = ((item.value / totalWeight) * 100).toFixed(1);
    });

    this.editAiSettingsModal(this.aiManager.aisSettingsActive, 'close');
    this.isAddingVairablesBox = false;
    this.createDonutChart(this.aiManager.aisSettingsActive);
  }

  removeNewSettings(keyToRemove: string) {
    const indexToRemove = this.aiManager.aisSettingsActive.findIndex(
      (setting) => setting.key === keyToRemove
    );
    if (indexToRemove !== -1) {
      this.aiManager.aisSettingsActive.splice(indexToRemove, 1);
      this.editAiSettingsModal(this.aiManager.aisSettingsActive, 'close');
    }
  }

  updateCheckedSettings(setting: string[]) {
    this.checkedSettings = [...setting];
  }
  onRadioChange(key: string, value: any): void {
    const activeIndex = this.aiManager.aisSettingsActive.findIndex(
      (setting) => setting.key === key
    );

    if (activeIndex !== -1) {
      this.aiManager.aisSettingsActive[activeIndex].value = value;
      const totalWeight = this.aiManager.aisSettingsActive.reduce(
        (sum, item) => sum + item.value,
        0
      );
      this.aiManager.aisSettingsActive.forEach((item) => {
        item.field_percentage = ((item.value / totalWeight) * 100).toFixed(1);
      });
    } else {
      const inactiveIndex = this.aiManager.aisSettingsInactive.findIndex(
        (setting) => setting.key === key
      );
      if (inactiveIndex !== -1) {
        const setting = this.aiManager.aisSettingsInactive.splice(
          inactiveIndex,
          1
        )[0];
        setting.value = value;
        this.aiManager.aisSettingsActive.push(setting);
        const totalWeight = this.aiManager.aisSettingsActive.reduce(
          (sum, item) => sum + item.value,
          0
        );
        this.aiManager.aisSettingsActive.forEach((item) => {
          item.field_percentage = ((item.value / totalWeight) * 100).toFixed(1);
        });
      }
    }
    this.createDonutChart(this.aiManager.aisSettingsActive);
  }

  createDonutChart(data: any) {
    this.chartOptions = {
      series: data.map((item: any) => parseFloat(item.field_percentage)),
      chart: {
        type: 'pie',
        height: 172,
      },
      theme: {
        monochrome: {
          enabled: true,
          color: '#0ac084',
        },
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        pie: {},
      },
      stroke: {
        width: 0,
      },
      legend: {
        horizontalAlign: 'center',
      },
      labels: data.map((item: any) => {
        return `<span style="font-weight:500;display: inline-block;width: 130px;text-transform: capitalize;white-space: nowrap;font-family: 'Barlow', sans-serif;">${this.getScoreName(
          item.name
        )}</span> ${item.field_percentage}%`;
      }),
    };
  }
  getScoreName(name: string): string {
    switch (name) {
      case 'Nice to have skill':
        return 'Nice to have skills';
      case 'Required skill':
        return 'Required skills';
      case 'People skill':
        return 'People skills';
      default:
        return name;
    }
  }
  toggleSubmenu() {
    const bodyTag = document.body;
    bodyTag.classList.toggle('is-submenu');
    this.auth.isSubmenu = !this.auth.isSubmenu;
  }
  async ngOnInit(): Promise<void> {
    this.validateForm = this.fb.group({});
    this.deIndentifyForm = this.fb.group({});
    try {
      await this.aiManager.getAiSettings();
      this.createDonutChart(this.aiManager.aisSettingsActive);
      await this.aiManager.getDeIdentification();
    } catch (error) {}
  }
}
