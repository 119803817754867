<aside class="aside-menu h-100" [ngClass]="{ 'active': imprMgr.impersonating}">

    <nav class="menu">
        <a *ngIf="user.role==='Admin'" class="btn-impersonation" routerLink="/impersonation" routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }">
            <span><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <mask id="izll4lw3ma" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24"
                        height="24">
                        <path fill="#D9D9D9" d="M0 0h24v24H0z" />
                    </mask>
                    <g mask="url(#izll4lw3ma)">
                        <path
                            d="M1.798 19.308v-2.223c0-.516.133-.976.4-1.38a2.72 2.72 0 0 1 1.065-.944c.95-.465 1.906-.823 2.867-1.075.96-.252 2.017-.378 3.168-.378 1.151 0 2.207.126 3.168.378.961.252 1.917.61 2.867 1.075.445.225.8.54 1.066.944.266.404.399.864.399 1.38v2.223h-15zm17 0v-2.346c0-.657-.16-1.282-.482-1.877a4.546 4.546 0 0 0-1.368-1.531c.67.1 1.307.255 1.91.464.602.21 1.177.458 1.725.744.516.275.915.6 1.197.974.281.374.422.783.422 1.226v2.346h-3.404zm-9.5-7.616c-.962 0-1.786-.342-2.472-1.028-.685-.685-1.028-1.51-1.028-2.472s.343-1.786 1.028-2.471c.686-.686 1.51-1.029 2.472-1.029.963 0 1.787.343 2.472 1.029.685.685 1.028 1.509 1.028 2.471 0 .963-.343 1.787-1.028 2.472-.685.686-1.51 1.028-2.472 1.028zm8.635-3.5c0 .963-.343 1.787-1.029 2.472-.685.686-1.509 1.028-2.471 1.028-.113 0-.257-.012-.431-.038a3.793 3.793 0 0 1-.43-.085c.394-.474.697-1 .908-1.578a5.19 5.19 0 0 0-.006-3.593 5.78 5.78 0 0 0-.903-1.583 1.87 1.87 0 0 1 .43-.1 4.04 4.04 0 0 1 .432-.023c.962 0 1.786.343 2.471 1.029.686.685 1.029 1.509 1.029 2.471zM3.298 17.808h12v-.723a1.01 1.01 0 0 0-.157-.558c-.104-.163-.27-.305-.497-.427a11.797 11.797 0 0 0-2.542-.964 11.5 11.5 0 0 0-2.804-.328 11.5 11.5 0 0 0-2.804.328c-.872.218-1.719.54-2.542.964-.227.122-.393.264-.497.427a1.01 1.01 0 0 0-.157.558v.723zm6-7.616c.55 0 1.02-.195 1.413-.587.391-.392.587-.863.587-1.413s-.196-1.02-.587-1.412a1.926 1.926 0 0 0-1.413-.588c-.55 0-1.02.196-1.412.588a1.926 1.926 0 0 0-.588 1.412c0 .55.196 1.021.588 1.413.391.392.862.587 1.412.587z"
                            fill="#1C1A20" fill-opacity=".7" />
                    </g>
                </svg>
            </span>
            <span class="angle-icon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="m13.172 12-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414 4.95-4.95z" fill="#1C1A20"
                        fill-opacity=".7" />
                </svg>
            </span>

        </a>

        <ul>
            <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                <a routerLink="/">
                    <span class="menu-text">Home</span>
                </a>
            </li>
            <li *appIsGranted="{ content_type: 'job', desiredPermission: 'view' }" routerLinkActive="active">
                <a routerLink="/jobs">
                    <span class="menu-text">Jobs</span>
                </a>
            </li>
            <li *appIsGranted="{ content_type: 'candidate', desiredPermission: 'view' }" routerLinkActive="active">
                <a routerLink="/candidates">
                    <span class="menu-text">Candidates</span>
                </a>
            </li>
            <li *appIsGranted="{ content_type: 'user', desiredPermission: 'view' }" routerLinkActive="active">
                <a routerLink="/users">
                    <span class="menu-text">Users</span>
                </a>
            </li>
            <li *appIsGranted="{ content_type: 'partner', desiredPermission: 'view' }" routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }">
                <a routerLink="/partners">
                    <span class="menu-text">Partners</span>
                </a>
            </li>
        </ul>
    </nav>
    <div class="sub-menu" *ngIf="auth.isSubmenu">
        <ul>
            <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                <a routerLink="/my-account">
                    My Account
                </a>
            </li>
            <li *appIsGranted="{ content_type: 'partnersettings', desiredPermission: 'view' }" routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }">
                <a routerLink="/ai-settings">
                    AI Settings
                </a>
            </li>
            <li *appIsGranted="{ content_type: 'partnersettings', desiredPermission: 'view' }" routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }">
                <a routerLink="/candidate-sorting">Candidate Sorting</a>
            </li>
            <li *appIsGranted="{ content_type: 'partnersettings', desiredPermission: 'view' }" routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }">
                <a routerLink="/notifications-settings">
                    Notifications
                </a>
            </li>
            <li *appIsGranted="{ content_type: 'partnersettings', desiredPermission: 'view' }" routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }">
                <a routerLink="/screening-actions">Screening Actions</a>
            </li>
            <li *appIsGranted="{ content_type: 'partnersettings', desiredPermission: 'view' }" routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }">
                <a routerLink="/job-processing">Job Processing</a>
            </li>

            <li *appIsGranted="{ content_type: 'partnersettings', desiredPermission: 'view' }" routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }">
                <a routerLink="/integrations">Integrations</a>
            </li>
            <li *appIsGranted="{ content_type: 'partnersettings', desiredPermission: 'view' }" routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }">
                <a routerLink="/subscription">Subscription Settings</a>
            </li>

        </ul>
        <div class="logout-link" (click)="logout()">Log Out</div>
    </div>
    <div class="aside-bottom">
        <a class="profile-icon" routerLink="/my-profile" *ngIf="user.role==='Candidate'" routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }" [ngClass]="{ 'setting-active': auth.isSubmenu}">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="hp5zykqh4a" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24"
                    height="24">
                    <path fill="#D9D9D9" d="M0 0h24v24H0z" />
                </mask>
                <g mask="url(#hp5zykqh4a)">
                    <path
                        d="M5.85 17.1c.85-.65 1.8-1.163 2.85-1.538A9.737 9.737 0 0 1 12 15c1.15 0 2.25.188 3.3.563 1.05.374 2 .887 2.85 1.537a7.73 7.73 0 0 0 1.363-2.325A7.838 7.838 0 0 0 20 12c0-2.217-.78-4.104-2.337-5.662C16.104 4.779 14.217 4 12 4s-4.104.78-5.662 2.338C4.779 7.896 4 9.783 4 12c0 .983.162 1.908.487 2.775.325.867.78 1.642 1.363 2.325zM12 13c-.983 0-1.813-.338-2.488-1.012C8.837 11.313 8.5 10.483 8.5 9.5c0-.983.338-1.813 1.012-2.487C10.188 6.338 11.017 6 12 6c.983 0 1.813.338 2.488 1.013.675.675 1.012 1.504 1.012 2.487 0 .983-.338 1.813-1.012 2.488C13.813 12.663 12.983 13 12 13zm0 9a9.738 9.738 0 0 1-3.9-.788 10.099 10.099 0 0 1-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175A9.738 9.738 0 0 1 2 12c0-1.383.263-2.683.788-3.9a10.099 10.099 0 0 1 2.137-3.175c.9-.9 1.958-1.612 3.175-2.137A9.738 9.738 0 0 1 12 2c1.383 0 2.683.263 3.9.788a10.098 10.098 0 0 1 3.175 2.137c.9.9 1.613 1.958 2.137 3.175A9.738 9.738 0 0 1 22 12a9.738 9.738 0 0 1-.788 3.9 10.098 10.098 0 0 1-2.137 3.175c-.9.9-1.958 1.613-3.175 2.137A9.738 9.738 0 0 1 12 22zm0-2c.883 0 1.717-.13 2.5-.387a7.53 7.53 0 0 0 2.15-1.113 7.53 7.53 0 0 0-2.15-1.113A7.933 7.933 0 0 0 12 17c-.883 0-1.717.13-2.5.387A7.53 7.53 0 0 0 7.35 18.5a7.53 7.53 0 0 0 2.15 1.113A7.933 7.933 0 0 0 12 20zm0-9c.433 0 .792-.142 1.075-.425.283-.283.425-.642.425-1.075 0-.433-.142-.792-.425-1.075C12.792 8.142 12.433 8 12 8c-.433 0-.792.142-1.075.425-.283.283-.425.642-.425 1.075 0 .433.142.792.425 1.075.283.283.642.425 1.075.425z"
                        fill="#000" />
                </g>
            </svg>
        </a>
        <span (click)="toggleSubmenu()" class="setting-icon" [ngClass]="{ 'active': auth.isSubmenu}">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M10.2001 6.00001V4.20001H12.9001V0.600006H14.7001V4.20001H17.4001V6.00001H10.2001ZM12.9001 17.4V7.80001H14.7001V17.4H12.9001ZM3.3001 17.4V13.8H0.600098V12H7.8001V13.8H5.1001V17.4H3.3001ZM3.3001 10.2V0.600006H5.1001V10.2H3.3001Z"
                    fill="#1C1B1F" />
            </svg>
        </span>

    </div>

</aside>