import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UsersManagerService } from 'src/app/Managers/users-manager.service';
import {
  FormGroup,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
@Component({
  selector: 'app-create-candidate-account',
  templateUrl: './create-candidate-account.component.html',
  styleUrl: './create-candidate-account.component.scss',
})
export class CreateCandidateAccountComponent {
  isLoading: boolean = false;
  currentStep: number = 1;
  totalSteps: number = 3;
  passwordVisible: boolean = false;
  isError: boolean = false;
  token: string = '';
  validateForm!: UntypedFormGroup;
  raceOptions = [
    'American Indian or Alaska Native',
    'Asian',
    'Black or African American',
    'Native Hawaiian or Other Pacific Islander',
    'White',
    'Two or more races',
    'Other (please specify)',
  ];
  constructor(
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public userMgr: UsersManagerService
  ) {}
  submitForm(): void {
    if (this.validateForm.valid) {
      const ageInt: number =
        parseInt(this.validateForm.get('age')?.value, 10) ?? 0;

      let userDetails = {};
      userDetails = {
        token: this.token,
        password: this.validateForm.get('password')?.value,
        accept_terms: this.validateForm.get('accept_terms')?.value,
        age_confirmation: this.validateForm.get('age_confirmation')?.value
          ? 18
          : 13,
        age: ageInt,
        opt: this.validateForm.get('opt')?.value,
        professional_network_url: this.validateForm.get(
          'professional_network_url'
        )?.value,
        other_url: this.validateForm.get('other_url')?.value,
        education: this.validateForm.get('education')?.value,
        gender: this.validateForm.get('gender')?.value,
        ethnicity: this.validateForm.get('ethnicity')?.value,
        employment_status: this.validateForm.get('employment_status')?.value,
        race: this.validateForm.get('race')?.value.toString(),
        income_status: this.validateForm.get('income_status')?.value,
      };

      // console.log(userDetails);
      // return;
      this.userMgr.registerCandidate(userDetails);
    } else {
      Object.values(this.validateForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  nextStep(): void {
    if (this.currentStep < this.totalSteps) {
      this.currentStep++;
    } else {
      this.submitForm();
    }
  }

  previousStep(): void {
    if (this.currentStep > 1) {
      this.currentStep--;
    }
  }
  goToStep(step: number): void {
    if (step >= 1 && step <= this.totalSteps) {
      this.currentStep = step;
    }
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.token = params['token'];
      const first_name = params['first_name'];
      const last_name = params['last_name'];
      const email = params['email'];

      this.validateForm = this.fb.group({
        first_name: [{ value: first_name, disabled: true }],
        last_name: [{ value: last_name, disabled: true }],
        email: [{ value: email, disabled: true }],
        password: [
          '',
          [
            Validators.required,
            Validators.minLength(8),
            Validators.pattern(/^(?=.*[!@#$%^&*])/),
          ],
        ],
        accept_terms: [false, Validators.required],
        age_confirmation: [false, Validators.required],
        opt: ['Opt In', Validators.required],
        professional_network_url: [''],
        other_url: [''],
        age: [''],
        education: [''],
        gender: [''],
        ethnicity: [''],
        employment_status: [''],
        race: [[]],
        income_status: [''],
      });
    });
  }
}
