import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ImpersonationService {
  constructor(private http: HttpClient) {}
  getPartners(page: number) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.get(
      environment.apiURL + `account/partners/?page=${page}`,
      {
        headers: header,
      }
    );
  }
  currentImpersonation() {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.get(
      environment.apiURL + `deincore/dashboard/current-impersonation`,
      {
        headers: header,
      }
    );
  }
  getPartnersusers(id: number) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.get(
      environment.apiURL + `deincore/dashboard/list-partnerusers/${id}`,
      {
        headers: header,
      }
    );
  }
  startImpersonation(id: number, reason: string) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.post(
      environment.apiURL + `deincore/dashboard/start-impersonation/${id}`,
      { reason },
      {
        headers: header,
      }
    );
  }
  exitImpersonation() {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.put(
      environment.apiURL + `deincore/dashboard/stop-impersonation`,
      {
        headers: header,
      }
    );
  }
}
