<div class="page">
    <div class="contianer">
        <div class="page-header">
            <div>
                <h1>Automated Decision-Making and Profiling Notice</h1>
                <p>Effective: February 26, 2024</p>
            </div>


        </div>
    </div>
    <div class="page-body">
        <div class="contianer">
            <p>DEI Network (“DEIN,” “we,” “our,” “us”) uses artificial intelligence (AI) tools to evaluate job seekers’
                eligibility and
                fit for employment opportunities, including through the Clara platform. This use of artificial
                intelligence may
                constitute profiling in furtherance of decisions that produce legal or similarly significant effects
                under certain
                privacy laws.</p>
            <p>Before processing your personal information in this manner, we are providing you with an opportunity to
                opt out of our
                use of your personal information for profiling in furtherance of decisions that produce legal or
                similarly significant
                effects. The content below provides additional information about our use of artificial intelligence
                tools for such
                purposes.</p>
            <p><b>Decisions subject to profiling.</b> We use artificial intelligence tools to evaluate your eligibility
                and fit for employment
                opportunities and to provide you with information about employment opportunities that align with your
                background and
                qualifications.</p>
            <p>Categories of personal information used in the profiling. Our artificial intelligence tools consider such
                personal
                information as employment information (e.g., employment history) and education information (e.g.,
                educational
                background). Additionally, our artificial intelligence tools consider the job application materials that
                you share with
                us (e.g., resumes, cover letters).</p>
            <p><b>Explanation of the logic used in the profiling.</b> A natural language processing algorithm breaks
                down the resume contents
                into small, encapsulated knowledge sections. Some of these sections might include the different skills
                and experiences
                listed within the resume. Others might comprise the user’s educational performance and real-world
                proficiencies. The AI
                algorithm compares the job description contents with the resume contents one section at a time to
                determine the level of
                alignment between the two.</p>
            <p><b>How profiling is used in the decision-making process.</b> Our artificial intelligence tools — applying
                the logic and
                considering the information described above — are intended to match job seekers with employment
                opportunities determined
                to be most aligned with a particular job seeker’s qualifications and background. When companies use our
                Services, the
                results computed by the AI algorithm are conveyed to the relevant hiring managers for a final review
                before any hiring
                decisions are made. Alternatively, we may use the results computed by the AI algorithm to notify you of
                employment
                opportunities, so that you can pursue them.</p>
            <p><b>Why profiling is relevant to the decision-making process.</b> A key purpose of our Services is to
                connect job seekers with
                employment opportunities that match their backgrounds and qualifications. Our use of artificial
                intelligence tools
                allows us to analyze key characteristics of your background and qualifications and identify
                opportunities that match
                those characteristics.</p>
            <p><b>Potential benefits and consequences of the decision based on the profiling.</b> Our use of artificial
                intelligence tools
                allows us to efficiently and effectively identify employment opportunities that align with your
                background and
                qualifications. However, these artificial intelligence tools will not necessarily identify all
                employment opportunities
                for which a particular job seeker is qualified.</p>
            <p><b>Correcting or deleting the personal data used in profiling.</b> If you would like to correct or delete
                personal information
                that we are using as part of our profiling capabilities, you may contact us at myinfo&#64;dein-info.com.
                or
                myinfo&#64;getclara.io in order to do so.</p>
            <p><b>Your rights.</b> If you do not opt out of our use of your personal information for profiling purposes
                now, you may do so at
                a later point in time by emailing us at myinfo&#64;dein-info.com. or myinfo&#64;getclara.io. For more
                information about your
                data rights, please consult our Privacy Policy.</p>
            <h2>WOULD YOU LIKE TO OPT OUT OF OUR USE OF YOUR PERSONAL INFORMATION FOR PROFILING PURPOSES?</h2>
            <p>[No, I would like to continue / Yes, I would like to opt out]</p>

        </div>
    </div>
</div>