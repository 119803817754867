import { Injectable } from '@angular/core';
import { AiSettingsService } from '../Services/ai-settings.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { aiSettings, deIdentificationSettings } from '../models/models';

@Injectable({
  providedIn: 'root',
})
export class AiSettingsManagerService {
  public isLoading: boolean = false;
  public isLoadingDeIndentification: boolean = false;
  public isVisibleAiModal = false;
  public isVisibleDeIndModal = false;
  aisSettingsData: aiSettings[] = [];
  deIdentificationData?: deIdentificationSettings;
  aisChartData: aiSettings[] = [];
  aisSettingsActive: aiSettings[] = [];
  aisSettingsInactive: aiSettings[] = [];
  constructor(
    public aiServices: AiSettingsService,
    private notification: NzNotificationService
  ) {}
  public getAiSettings(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.isLoading = true;
      this.aiServices.aiServices().subscribe({
        next: (c: any) => {
          this.aisSettingsData = c.response;

          this.aisSettingsActive = c.response.filter(
            (setting: any) => setting.value != 0
          );
          this.aisSettingsInactive = c.response.filter(
            (setting: any) => setting.value == 0
          );
          const totalWeight = this.aisSettingsActive.reduce(
            (sum, item) => sum + item.value,
            0
          );
          this.aisSettingsActive.forEach((item) => {
            item.field_percentage = ((item.value / totalWeight) * 100).toFixed(
              1
            );
          });

          this.isLoading = false;
          resolve();
        },
        error: (error: any) => {
          this.notification.create('error', 'Error', error.error.error);
          this.isLoading = false;
          reject(error);
        },
      });
    });
  }

  public updateAiSettings(data: aiSettings): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.isLoading = true;
      this.aiServices.updateAiSettings(data).subscribe({
        next: (c: any) => {
          this.isLoading = false;
          this.isVisibleAiModal = false;
          this.getAiSettings().then(() => {
            this.notification.create(
              'success',
              'Candidate Scoring Successful',
              'Your candidate list was successfully scored.'
            );
            resolve();
          });
        },
        error: (error: any) => {
          this.notification.create('error', 'Error', error.error.error);
          this.isLoading = false;
          reject(error);
        },
      });
    });
  }
  public getDeIdentification(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.isLoadingDeIndentification = true;
      this.aiServices.getDeIdentification().subscribe({
        next: (c: any) => {
          this.deIdentificationData = c.response;
          this.isLoadingDeIndentification = false;
          resolve();
        },
        error: (error: any) => {
          this.notification.create('error', 'Error', error.error.error);
          this.isLoadingDeIndentification = false;
          reject(error);
        },
      });
    });
  }
  public updateDeIdentification(data: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.isLoadingDeIndentification = true;
      this.aiServices.updateDeIdentification(data).subscribe({
        next: (c: any) => {
          this.isLoadingDeIndentification = false;
          this.isVisibleDeIndModal = false;
          this.getDeIdentification().then(() => {
            this.notification.create(
              'success',
              'De-Identification Updated successfully ',
              ''
            );
            resolve();
          });
        },
        error: (error: any) => {
          this.notification.create('error', 'Error', error.error.error);
          this.isLoadingDeIndentification = false;
          reject(error);
        },
      });
    });
  }
}
