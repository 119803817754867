import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AiSettingsService {
  constructor(private http: HttpClient) {}

  aiServices() {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.get(environment.apiURL + `settings/ai/`, {
      headers: header,
    });
  }
  updateAiSettings(formData: any) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.put(environment.apiURL + `settings/ai/`, formData, {
      headers: header,
    });
  }
  getDeIdentification() {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.get(
      environment.apiURL + `settings/partner/ai/de-identification`,
      {
        headers: header,
      }
    );
  }
  updateDeIdentification(formData: any) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.put(
      environment.apiURL + `settings/partner/ai/de-identification`,
      formData,
      {
        headers: header,
      }
    );
  }
}
