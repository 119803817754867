<div class="fullscreen bg-gradient">
    <div class="login-wrap">
        <h1 class="font-heading">Welcome to CLARA</h1>
        <div class="alert-login" *ngIf="isLoginError">
            Wrong password? <br> <a routerLink="/forgot-password">Did you forget your password?</a>
        </div>

        <form nz-form [formGroup]="validateForm" class="login-form" (ngSubmit)="submitForm()">
            <label for="email">Email</label>
            <nz-form-item>
                <nz-form-control nzErrorTip="Please input a valid email without spaces!">
                    <input type="text" nzSize="large" nz-input formControlName="email" placeholder="Email" id="email" />
                </nz-form-control>
            </nz-form-item>
            <label for="password">Password</label>

            <nz-form-control nzErrorTip="Please input your Password!">
                <nz-input-group [nzSuffix]="suffixTemplate" nzSize="large">
                    <input [type]="passwordVisible ? 'text' : 'password'" nzSize="large" nz-input
                        formControlName="password" placeholder="Password" id="password" />
                </nz-input-group>
                <ng-template #suffixTemplate>
                    <span nz-icon [nzType]="passwordVisible ? 'eye' : 'eye-invisible'"
                        (click)="passwordVisible = !passwordVisible"></span>
                </ng-template>
            </nz-form-control>
            <nz-form-item>
                <a class="forgot-link" routerLink="/forgot-password">Forgot Password</a>
            </nz-form-item>
            <button nz-button nzBlock nzSize="large" [nzType]="'primary'" nzShape="round"><span *ngIf="isLoading"
                    nz-icon nzType="loading" nzTheme="outline"></span>
                Login</button>

        </form>
    </div>
</div>