import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UsersManagerService } from 'src/app/Managers/users-manager.service';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrl: './verify-email.component.scss',
})
export class VerifyEmailComponent {
  token: string = '';

  constructor(
    private route: ActivatedRoute,
    public userMgr: UsersManagerService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      const token = params['token'];
      if (!token) {
        this.userMgr.isTokenExpired = true;
      } else {
        this.userMgr.verifyingUser(token);
      }
    });
  }
}
