<div class="fullscreen bg-gradient">
    <div class="login-wrap">
        <div *ngIf="!this.usersServiceManger.isEmailSent">
            <h1 class="font-heading">Forgot Password?</h1>
            <div class="note-box ">Enter your email to get instructions.</div>
            <form [formGroup]="validateForm" nz-form class="login-form" (ngSubmit)="submitForm()">
                <label for="email">Email</label>
                <nz-form-item>
                    <nz-form-control nzErrorTip="Please input valid email!">
                        <input type="email" nzSize="large" nz-input formControlName="Email" placeholder="Email"
                            id="email" formControlName="email" />
                    </nz-form-control>
                </nz-form-item>
                <button nz-button class="login-form-button login-form-margin" nzBlock nzSize="large"
                    [nzType]="'primary'" nzShape="round"><span *ngIf="this.usersServiceManger.isAddingUser" nz-icon
                        nzType="loading" nzTheme="outline"></span>Send Instructions</button>
                <div class="login-footer">
                    <div><a routerLink="/login">Log In</a></div>
                </div>
            </form>
        </div>
        <div *ngIf="this.usersServiceManger.isEmailSent">
            <h1 class="font-heading">Sent!</h1>
            <div class="note-box">
                <p>We sent password reset instructions to the email supplied.</p>
                <p>This message can take up to 5 minutes to be received. Be sure to check the spam folder.</p>
            </div>
            <div class="login-footer">
                <div><a routerLink="/login">Log In</a></div>
            </div>
        </div>
    </div>
</div>