import { Component } from '@angular/core';
import { DashboardManagerService } from 'src/app/Managers/dashboard-manager.service';

@Component({
  selector: 'app-hr-home',
  templateUrl: './hr-home.component.html',
  styleUrl: './hr-home.component.scss',
})
export class HRHomeComponent {
  constructor(public dashboardMgr: DashboardManagerService) {}
  ngOnInit() {
    this.dashboardMgr.getAnalyticsHR('weekly');
  }
  ngOnDestroy() {
    this.dashboardMgr.jobApplicationOverveiw = [];
  }
}
