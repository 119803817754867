import { Component, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SjmManagerService } from 'src/app/Managers/sjm-manager.service';
import { ApplicationManagerService } from 'src/app/Managers/applications-manager.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
@Component({
  selector: 'app-my-applications',
  templateUrl: './my-applications.component.html',
  styleUrl: './my-applications.component.scss',
})
export class MyApplicationsComponent {
  detailForm!: FormGroup;
  linksForm!: FormGroup;
  currentStep: number = 1;
  totalSteps: number = 3;
  selectedFiles: File[] = [];
  selectedResumeFile: File | null = null;
  uploadedMaterials: { material_name: string; file: File }[] = [];
  jobObjectId: string = '';
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    public sjmMgr: SjmManagerService,
    private notification: NzNotificationService,
    public applicationMgr: ApplicationManagerService
  ) {}
  getStepClass(step: number): string {
    if (step < this.currentStep) {
      return 'done';
    } else if (step === this.currentStep) {
      return 'active';
    } else {
      return 'inactive';
    }
  }
  nextStep(): void {
    if (this.currentStep < this.totalSteps) {
      this.currentStep++;
    } else {
      //this.submitForm();
    }
  }

  previousStep(): void {
    if (this.currentStep > 1) {
      this.currentStep--;
    }
  }
  async onSubmitDetailForm() {
    if (this.detailForm.valid) {
      console.log('Form Submitted', this.detailForm.value);
      let data = {
        email: this.detailForm.value.email,
      };
      await this.sjmMgr.validateEmail(data);
      this.currentStep++;
      this.selectedResumeFile = null;
      this.uploadedMaterials = [];
    } else {
      Object.values(this.detailForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  async uploadResums(): Promise<void> {
    await this.sjmMgr.uploadResumes(this.selectedFiles, this.jobObjectId);
    this.currentStep++;
  }
  async nextLinks() {
    this.createLinksForm();
    this.currentStep++;
    // this.router.navigate(['jobs/candidates/', this.jobObjectId], {
    //   queryParams: { processing },
    // });
  }
  convertLinksToArray = (obj: any) => {
    return {
      links: Object.entries(obj)
        .filter(([link_name, link_url]) => link_url)
        .map(([link_name, link_url]) => ({
          link_name,
          link_url,
        })),
    };
  };

  async submitApplications() {
    if (this.linksForm.valid) {
      let profileData = {
        ...this.detailForm.value,
        file: this.selectedResumeFile,
      };
      const links = this.convertLinksToArray(this.linksForm.value);

      await this.sjmMgr.uploadCanidateResumeAndProfile(
        profileData,
        this.jobObjectId
      );
      await this.sjmMgr.uploadCanidateMaterials(
        this.uploadedMaterials,
        this.jobObjectId
      );
      await this.sjmMgr.submitCandidateLinks(links);

      this.selectedResumeFile = null;
      this.uploadedMaterials = [];
      this.router.navigate(['/application-submitted', this.jobObjectId]);
    } else {
      Object.values(this.linksForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  changeResumeFile(event: any) {
    const file = event.target.files[0];
    if (file.size <= 10 * 1024 * 1024) {
      // Check file type
      const allowedTypes = [
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.apple.pages',
      ];
      if (allowedTypes.includes(file.type)) {
        this.selectedResumeFile = file;
        console.log(this.selectedResumeFile);
      } else {
        event.target.value = null;
        this.notification.create(
          'error',
          'Error',
          'Only PDF, Word, or Pages files are allowed!'
        );
      }
    } else {
      event.target.value = null;
      this.notification.create(
        'error',
        'Error',
        'File size must be less than 10MB!'
      );
    }
  }
  changeFile(event: any, material: any) {
    const file = event.target.files[0];
    if (file.size <= 10 * 1024 * 1024) {
      // File size validation
      const allowedTypes = [
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.apple.pages',
      ];
      if (allowedTypes.includes(file.type)) {
        // File type validation
        const materialObject = {
          material_name: material.material_name,
          file: file,
        };
        this.uploadedMaterials.push(materialObject);
      } else {
        event.target.value = null;
        this.notification.create(
          'error',
          'Error',
          'Only PDF, Word, or Pages files are allowed!'
        );
      }
    } else {
      event.target.value = null;
      this.notification.create(
        'error',
        'Error',
        'File size must be less than 10MB!'
      );
    }
  }
  get isSubmitDisabled(): boolean {
    const materials =
      this.sjmMgr.jobInfo?.job_materials_and_links?.materials || [];

    return materials
      .filter((material) => material.is_mandatory && material.is_display)
      .some(
        (mandatoryMaterial) =>
          !this.uploadedMaterials.some(
            (uploaded) =>
              uploaded.material_name === mandatoryMaterial.material_name
          )
      );
  }

  createLinksForm() {
    const urlPattern = /^www\.[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}(\/[^\s]*)?$/;

    const links = this.sjmMgr.jobInfo?.job_materials_and_links?.links || [];
    const formControls = links
      .filter((link) => link.is_display)
      .reduce<{ [key: string]: any }>((controls, link) => {
        const validators = [];

        if (link.is_mandatory) {
          validators.push(Validators.required);
        }

        validators.push(Validators.pattern(urlPattern));

        controls[link.link_name] = ['', validators];
        return controls;
      }, {});

    this.linksForm = this.fb.group(formControls);
  }

  async ngOnInit() {
    this.route.params.subscribe(async (params) => {
      this.jobObjectId = params['jobObjectId'];
      await this.sjmMgr.getJobInformation(this.jobObjectId);
    });

    this.detailForm = this.fb.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
    });
  }
  ngAfterViewInit() {
    // Set autocomplete attributes dynamically
    const apiKeyInput = document.getElementById('platform_api_key');
    if (apiKeyInput) {
      apiKeyInput.setAttribute('autocomplete', 'new-password');
    }
  }
  ngOnDestroy() {
    this.sjmMgr.resumeFinilizeData = [];
  }
}
