import { Component } from '@angular/core';
import { Router } from '@angular/router';
import {
  FormGroup,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
@Component({
  selector: 'app-create-company',
  templateUrl: './create-company.component.html',
  styleUrls: ['./create-company.component.scss'],
})
export class CreateCompanyComponent {
  isLoading: boolean = false;
  isVisibleModal = false;
  passwordVisible: boolean = false;
  isError: boolean = false;
  currentStep = 0;
  validateForm!: UntypedFormGroup;
  form!: FormGroup;
  constructor(private router: Router, private fb: UntypedFormBuilder) {}
  submitForm(): void {
    if (this.validateForm.valid) {
      const firstName = this.validateForm.controls['firstName'].value;
      const password = this.validateForm.controls['password'].value;

      if (password) {
        this.isVisibleModal = true;
      }
    } else {
      Object.values(this.validateForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      firstName: [{ value: 'Dina', disabled: true }, [Validators.required]],
      lastName: [{ value: 'Pali', disabled: true }, [Validators.required]],
      email: [
        { value: 'dina@company.com', disabled: true },
        [Validators.required],
      ],
      password: ['', [Validators.required]],
    });
    this.form = this.fb.group({
      companyName: ['', Validators.required],
      website: [''],
      phoneNumber: [''],
      ext: [''],
      street1: [''],
      street2: [''],
      city: [''],
      state: [''],
      zipCode: [''],
      userFirstName: ['', [Validators.required]],
      userLastName: ['', [Validators.required]],
      userEmail: ['', [Validators.required, Validators.email]],
      userRole: ['', [Validators.required]],
    });
  }

  handleCancel(): void {
    this.isVisibleModal = false;
  }

  nextStep(): void {
    if (this.currentStep < 1) {
      this.currentStep++;
    } else {
      if (this.form.valid) {
      }
    }
  }

  prevStep(): void {
    if (this.currentStep > 0) {
      this.currentStep--;
    }
  }
}
