import { Component } from '@angular/core';
import { FollowUpQuestionsManagerService } from 'src/app/Managers/follow-up-questions-manager.service';
import { ApplicationManagerService } from 'src/app/Managers/applications-manager.service';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
@Component({
  selector: 'app-job-follow-upquestions',
  templateUrl: './job-follow-upquestions.component.html',
  styleUrl: './job-follow-upquestions.component.scss',
})
export class JobFollowUPQuestionsComponent {
  isExpanded = false;
  showEditable = false;
  objectId: string = '';
  form!: FormGroup;
  constructor(
    private fb: FormBuilder,
    public followUpMgr: FollowUpQuestionsManagerService,
    public applicationMgr: ApplicationManagerService
  ) {}

  openQuestionsModal(): void {
    this.followUpMgr.isVisibleJobQuestionsModal = true;
  }
  handleCancel(): void {
    this.followUpMgr.isVisibleJobQuestionsModal = false;
  }
  toggleReadMore() {
    this.isExpanded = !this.isExpanded;
  }
  toggleEditable() {
    this.showEditable = true;
  }
  get followUpQuestions(): FormArray {
    return this.form.get('followUpQuestions') as FormArray;
  }

  addFollowUpQuestion(): void {
    const questionGroup = this.fb.group({
      category: ['', [Validators.required]],
      question: ['', [Validators.required]],
    });
    this.followUpQuestions.push(questionGroup);
  }

  removeFollowUpQuestion(index: number): void {
    this.followUpQuestions.removeAt(index);
  }

  onSubmitFollowUpQuestion(): void {
    if (this.form.valid) {
      console.log('Form Data:', this.form.value);
      this.followUpMgr.saveJobFollowUpQuestions(this.objectId, this.form.value);
    } else {
      const formArray = this.form.get('followUpQuestions') as FormArray;
      formArray.controls.forEach((group) => {
        const formGroup = group as FormGroup;
        Object.keys(formGroup.controls).forEach((key) => {
          const control = formGroup.get(key);
          if (control && control.invalid) {
            control.markAsDirty();
            control.updateValueAndValidity({ onlySelf: true });
          }
        });
      });
    }
  }
  changeNotificationsSettings(status: any): void {
    const formData: any = {
      send_follow_up_questions: status ? 'true' : 'false',
    };
    this.followUpMgr.sentStatusFollowUpQuestions(formData, this.objectId);
  }
  toggleEdit(categoryId: number, questionIndex: number): void {
    const category = this.followUpMgr.questionsOnJob.find(
      (c) => c.id === categoryId
    );
    if (category) {
      const question = category.questions[questionIndex];
      question.isEditable = !question.isEditable;
    }
  }
  saveUpdatedQuestion(
    categoryId: number,
    category: string,
    content: any,
    question: any
  ): void {
    const updatedData = {
      id: categoryId,
      category: category,
      question: content,
    };

    this.followUpMgr.updateJobFollowUpQuestion(this.objectId, updatedData);
    question.isEditable = false;
  }

  ngOnInit() {
    this.form = this.fb.group({
      followUpQuestions: this.fb.array([]),
    });
    this.addFollowUpQuestion();
    this.objectId = this.applicationMgr.jobDetailData['_id'];
    this.followUpMgr.getStatusFollowUpQuestions(this.objectId);
    this.followUpMgr.getJobFollowUpQuestions(this.objectId);
  }
}
