import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FollowUpQuestionsManagerService } from 'src/app/Managers/follow-up-questions-manager.service';
import { FormBuilder, FormGroup, UntypedFormGroup } from '@angular/forms';
@Component({
  selector: 'app-follow-up-questions',
  templateUrl: './follow-up-questions.component.html',
  styleUrl: './follow-up-questions.component.scss',
})
export class FollowUpQuestionsComponent {
  validateForm!: UntypedFormGroup;
  formGroup!: FormGroup;
  applicationId: string = '';
  age: string = '';
  token: string = '';
  firstName: string = '';
  lastName: string = '';
  email: string = '';
  currentStep = 0;
  user: any;
  isVisbileMaterial: boolean = false;
  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public followUpMgr: FollowUpQuestionsManagerService
  ) {}

  openQuestionsModal(data: any): void {
    this.followUpMgr.isVisibleQuestionsModal = true;
    this.initForm(data);
  }
  handleCancel(): void {
    this.followUpMgr.isVisibleQuestionsModal = false;
  }
  cancelSuccessModal(): void {
    this.followUpMgr.successModal = false;
  }
  cancelMaterialModal(): void {
    this.isVisbileMaterial = false;
  }
  visibleMaterialModal(): void {
    this.isVisbileMaterial = true;
  }
  joinDein(): void {
    this.router.navigate(['/create-account'], {
      queryParams: {
        token: this.token,
        first_name: this.firstName,
        last_name: this.lastName,
        email: this.email,
      },
    });
  }
  backToHome(): void {
    this.router.navigate(['/']);
  }
  goToStep(index: number): void {
    this.currentStep = index;
  }
  nextStep(action: string): void {
    if (this.currentStep < this.followUpMgr.questions.length - 1) {
      this.currentStep++;
    } else {
      if (this.formGroup.valid) {
        if (action === 'submited') {
          this.router.navigate(['/']);
        } else {
          let result = this.sumbitPayload(this.formGroup.value);
          this.followUpMgr.submitFollowUpQuestions(result, this.applicationId);
        }
      }
    }
  }

  prevStep(): void {
    let result = this.sumbitPayload(this.formGroup.value);
    this.followUpMgr.submitFollowUpQuestions(result, this.applicationId);
  }
  cancelFollowUpQuestion(): void {
    this.followUpMgr.isVisibleQuestionsModal = false;
  }

  sumbitPayload(data: any): void {
    let payload: any = {
      answers: [],
    };
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const id = parseInt(key.split('_')[1]);
        payload.answers.push({ id: id, answer: data[key] });
      }
    }
    return payload;
  }

  initForm(data: any): void {
    const formControls: any = {};
    for (const category of data) {
      for (const question of category.questions) {
        formControls['question_' + question.id] = [question.answer];
      }
    }
    this.formGroup = this.fb.group(formControls);
  }
  onInputAnswer(event: Event): void {
    if (this.followUpMgr.isAllAnswered) {
      event.preventDefault();
      const inputElement = event.target as HTMLTextAreaElement;
      inputElement.value = inputElement.value.slice(0, -1);
    }
  }
  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user') || '{}');
    this.activatedRoute.queryParams.subscribe(async (params) => {
      this.token = params['token'];
      this.firstName = params['first_name'];
      this.lastName = params['last_name'];
      this.email = params['email'];
      this.applicationId = params['application_id'];
      this.age = params['age'];
      await this.followUpMgr.getFollowUpQuestions(params['application_id']);
    });
  }
}
