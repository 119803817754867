import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UsersManagerService } from 'src/app/Managers/users-manager.service';
import {
  FormGroup,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
@Component({
  selector: 'app-get-started',
  templateUrl: './get-started.component.html',
  styleUrl: './get-started.component.scss',
})
export class GetStartedComponent {
  isLoading: boolean = false;
  passwordVisible: boolean = false;
  isError: boolean = false;
  token: string = '';
  payment_link = '';
  fragment_part = '';
  validateForm!: UntypedFormGroup;
  constructor(
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public userMgr: UsersManagerService
  ) {}
  async submitForm(): Promise<void> {
    if (this.validateForm.valid) {
      const userDetails = {
        token: this.token,
        password: this.validateForm.get('password')?.value,
      };

      await this.userMgr.registerPartnerAndStripIntegration(userDetails);
      console.log(this.payment_link != '');
      if (this.payment_link) {
        let payment_redirectURL = this.payment_link;
        if (this.fragment_part) {
          payment_redirectURL += '#' + this.fragment_part;
        }
        window.location.href = payment_redirectURL;
      } else {
        console.log('Do not navigate to stripe portal');
      }
    } else {
      Object.values(this.validateForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.token = params['token'];
      const first_name = params['first_name'];
      const last_name = params['last_name'];
      const email = params['email'];
      this.payment_link = params['payment_link'];

      this.validateForm = this.fb.group({
        first_name: [{ value: first_name, disabled: true }],
        last_name: [{ value: last_name, disabled: true }],
        email: [{ value: email, disabled: true }],
        password: [
          '',
          [
            Validators.required,
            Validators.minLength(8),
            Validators.pattern(/^(?=.*[!@#$%^&*])/),
          ],
        ],
      });
    });
    this.route.fragment.subscribe((fragment) => {
      if (fragment) {
        this.fragment_part = fragment;
      }
    });
  }
}
