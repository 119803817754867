import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { CandidateSortingManagerService } from 'src/app/Managers/candidate-sorting-manager.service';
@Component({
  selector: 'app-sorting-criteria',
  templateUrl: './sorting-criteria.component.html',
  styleUrl: './sorting-criteria.component.scss',
})
export class SortingCriteriaComponent {
  @Input()
  jobObjectId!: string;
  @Input()
  jobName!: string;
  @Output() okClicked = new EventEmitter<void>();
  @Output() cancelClicked = new EventEmitter<void>();
  @Output() submitSortingEvent = new EventEmitter<void>();

  isVisible = false;
  sortingForm = true;
  sortingConfirm = false;
  constructor(public sortingMgr: CandidateSortingManagerService) {}
  handleOk(): void {
    this.isVisible = false;
    this.okClicked.emit();
  }

  handleCancel(): void {
    this.isVisible = false;
    this.cancelClicked.emit();
  }
  onChangeScore(value: any): void {
    let minScorePreQualified = Math.min(value + 1, 100);
    let data = {
      automatic: true,
      pre_qualified: {
        min_score: minScorePreQualified,
        max_score: 100,
        enabled: true,
      },
      not_qualified: {
        min_score: 0,
        max_score: value + 0.9,
        enabled: true,
      },
    };
    let copiedData = JSON.parse(JSON.stringify(data));
    this.sortingMgr.updateSortingJobData = copiedData;
  }
  submitingSorting(): void {
    this.sortingForm = false;
    this.sortingConfirm = true;
  }
  backSorting(): void {
    this.sortingForm = true;
    this.sortingConfirm = false;
  }
  isUpdatingPercentValue() {
    this.sortingMgr.isUpdatingPercent = false;
    this.sortingMgr.percent = 0;
  }
  submitSorting(): void {
    this.sortingMgr.updateCandidatesJobSorting(
      this.sortingMgr.updateSortingJobData,
      this.jobObjectId
    );
    this.submitSortingEvent.emit();
  }
  async ngOnInit(): Promise<void> {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.sortingMgr.jobObjectId = this.jobObjectId;
    await this.sortingMgr.getCandidatesJobSorting(this.jobObjectId);
  }
}
