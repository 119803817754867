import { Component, EventEmitter, Output } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss'],
})
export class UploadFileComponent {
  selectedFile: File | null = null;
  jobTitle: string = '';
  jobLocation: string = '';
  @Output() fileSelected = new EventEmitter<File | null>();
  @Output() jobTitleChanged = new EventEmitter<string>();
  @Output() jobLocationChanged = new EventEmitter<string>();
  private readonly MAX_FILE_SIZE_MB = 10;

  constructor(private notification: NzNotificationService) {}

  // drag and drop file upload
  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;

    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      if (file.type === 'application/pdf') {
        if (file.size > this.MAX_FILE_SIZE_MB * 1024 * 1024) {
          this.notification.create(
            'error',
            'Error',
            `File size exceeds ${this.MAX_FILE_SIZE_MB}MB!`
          );
          return;
        }
        this.selectedFile = file;
        this.processFile(file);
        this.fileSelected.emit(this.selectedFile);
      } else {
        this.notification.create(
          'error',
          'Error',
          'Only PDF files are allowed!'
        );
      }
    }
  }

  onFileDropped(files: FileList): void {
    if (files.length > 0) {
      const file = files[0];
      if (file.type === 'application/pdf') {
        if (file.size > this.MAX_FILE_SIZE_MB * 1024 * 1024) {
          this.notification.create(
            'error',
            'Error',
            `File size exceeds ${this.MAX_FILE_SIZE_MB}MB!`
          );
          return;
        }
        this.selectedFile = file;
        this.processFile(file);
        this.fileSelected.emit(this.selectedFile);
      } else {
        this.notification.create(
          'error',
          'Error',
          'Only PDF files are allowed!'
        );
      }
    }
  }

  processFile(file: File): void {
    console.log('File accepted:', file);
  }

  removeFile(): void {
    this.selectedFile = null;
    const fileInput = document.getElementById(
      'fileDropRef'
    ) as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
    }
    this.fileSelected.emit(this.selectedFile);
  }

  onJobTitleChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.jobTitle = input.value;
    this.jobTitleChanged.emit(this.jobTitle);
  }
  onJobLocationChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.jobLocation = input.value;
    this.jobLocationChanged.emit(this.jobLocation);
  }
}
