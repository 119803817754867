import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersManagerService } from 'src/app/Managers/users-manager.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-unsubscribe-email',
  templateUrl: './unsubscribe-email.component.html',
  styleUrl: './unsubscribe-email.component.scss',
})
export class UnsubscribeEmailComponent {
  token: string = '';
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public userMgr: UsersManagerService,
    private notification: NzNotificationService
  ) {}
  yes(): void {
    let formData = {
      token: this.token,
    };
    this.userMgr.UnsubscribeFromNewsletter(formData);
  }
  no(): void {
    this.notification.create('success', 'Thank you for being here!', '');
    this.router.navigate(['/']);
  }
  ngOnInit(): void {
    this.route.queryParams.subscribe((params: { [x: string]: any }) => {
      this.token = params['token'];
    });
  }
}
