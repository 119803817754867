<div class="page" [ngClass]="{'bg-gradient': !integrationsMgr.isLoading && integrationsMgr.isIntegrationsDone}">
    <!-- Side menu -->
    <app-side-menu></app-side-menu>
    <!-- main -->
    <main class="main">
        <div nz-row [nzGutter]="20" nzJustify="space-between" nzAlign="middle">
            <div nz-col [nzSpan]="16">
                <h1 class="font-heading">Welcome back, <span class="capitalize">{{user.user_profile ?
                        user.user_profile.first_name : "Admin"}}</span></h1>
            </div>
            <div nz-col [nzSpan]="4">
                <div class="header-action">
                    <div
                        *ngIf="user.role === 'Partner Admin' || user.role === 'Account Owner' || user.role === 'Recruiter'">
                        <button (click)="openJobsModal()" class="btn-white">
                            Jobs <svg *ngIf="jobsMgr.latestJobCount > 0" class="ico-badge-md" width="24" height="24"
                                viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M24 11.988a.839.839 0 0 0-.341-.675l.004-.008-2.902-1.688 1.67-2.915-.005-.004a.842.842 0 0 0-.04-.755.83.83 0 0 0-.63-.412v-.008h-3.351V2.156h-.007a.837.837 0 0 0-.409-.635.823.823 0 0 0-.75-.039l-.005-.007-2.95 1.717L12.65.34l-.006.003A.828.828 0 0 0 11.975 0a.828.828 0 0 0-.67.344L11.299.34 9.637 3.239l-2.86-1.664-.004.006a.824.824 0 0 0-1.158.675h-.009v3.347H2.304v.007a.83.83 0 0 0-.63.412.842.842 0 0 0-.039.756l-.007.004 1.633 2.85-2.924 1.7.003.007a.839.839 0 0 0 .001 1.348l-.004.008 2.902 1.688-1.67 2.916.005.003a.842.842 0 0 0 .04.755.83.83 0 0 0 .63.412v.008h3.35v3.367h.008c.028.256.17.496.409.635a.824.824 0 0 0 .75.039l.004.007 2.951-1.717 1.634 2.852.006-.003a.828.828 0 0 0 .669.343.828.828 0 0 0 .67-.344l.006.004 1.661-2.899 2.86 1.664.004-.006a.824.824 0 0 0 1.159-.675h.008v-3.347h3.303v-.006a.83.83 0 0 0 .63-.412.843.843 0 0 0 .039-.757l.007-.004-1.633-2.85 2.924-1.7-.003-.007a.838.838 0 0 0 .34-.673zM10.044 15.56l-3.065-1.364 1.353 2.36-.765.445-2.073-3.619.786-.457 2.966 1.305-1.303-2.273.765-.445 2.073 3.619-.737.43zm1.448-.842L9.419 11.1l2.54-1.478.39.678-1.778 1.034.435.76 1.74-1.012.388.677-1.74 1.012.473.825 1.777-1.034.389.678-2.541 1.479zm5.884-3.423-2.054-2.119.8 2.848-.813.473-3.102-3.02.856-.498 2.128 2.248-.814-3.012.597-.348 2.177 2.22-.869-2.98.856-.499 1.05 4.214-.812.473z"
                                    fill="#5822D3" />
                            </svg></button>
                    </div>
                    <div class="action-bar">
                        <span (click)="openNotificationDrawer()">
                            <nz-badge [nzCount]="getUnreadNotificationsCount()">
                                <svg viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M7.10005 13H8.90005V10.9H11V9.10003H8.90005V7.00002H7.10005V9.10003H5.00005V10.9H7.10005V13ZM0.800049 16.6V14.8H2.00005V8.80002C2.00005 7.35002 2.44588 6.07502 3.33755 4.97502C4.22922 3.87502 5.38338 3.19169 6.80005 2.92502V1.60002C6.80005 1.26669 6.91672 0.983358 7.15005 0.750024C7.38338 0.516691 7.66672 0.400024 8.00005 0.400024C8.33338 0.400024 8.61672 0.516691 8.85005 0.750024C9.08338 0.983358 9.20005 1.26669 9.20005 1.60002V2.92502C10.6167 3.19169 11.7709 3.87502 12.6625 4.97502C13.5542 6.07502 14 7.35002 14 8.80002V14.8H15.2V16.6H0.800049ZM7.99475 19.6C7.49828 19.6 7.07505 19.4238 6.72505 19.0713C6.37505 18.7188 6.20005 18.295 6.20005 17.8H9.80005C9.80005 18.3 9.62328 18.725 9.26975 19.075C8.9162 19.425 8.4912 19.6 7.99475 19.6ZM3.80005 14.8H12.2V8.80002C12.2 7.63336 11.7917 6.64169 10.975 5.82502C10.1584 5.00836 9.16671 4.60002 8.00005 4.60002C6.83338 4.60002 5.84172 5.00836 5.02505 5.82502C4.20838 6.64169 3.80005 7.63336 3.80005 8.80002V14.8Z"
                                        fill="#131518" />
                                </svg>
                            </nz-badge>

                        </span>
                    </div>
                </div>
            </div>
        </div>

        <app-dein-home *ngIf="user.role==='Admin'"></app-dein-home>
        <div *ngIf="!integrationsMgr.isIntegrationsDone"> <app-partner-home
                *appIsGranted="{ content_type: 'partnersettings', desiredPermission: 'view' }"
                (emitNotificationModal)="openNotificationDrawer()"></app-partner-home>
        </div>
        <div class="info-box" *ngIf="!integrationsMgr.isLoading && integrationsMgr.isIntegrationsDone">
            <h2 class="font-heading">Welcome to CLARA!</h2>
            <p>To get CLARA working, you will have to import jobs by creating an integration to your applicant
                tracking system (ATS). Alternatively, you can upload jobs manually.</p>

            <div nz-row [nzGutter]="16" nzJustify="space-between" nzAlign="middle">
                <div nz-col [nzSpan]="10"><a routerLink="/jobs/upload-new-job"> <button nz-button nzType="default"
                            nzSize="large" nzShape="round" nzBlock>Upload New Job</button></a></div>
                <div nz-col [nzSpan]="14">
                    <a routerLink="/integrations"> <button nz-button nzType="primary" nzSize="large" nzShape="round"
                            nzBlock>Go to Integrations</button></a>
                </div>
            </div>
        </div>
        <app-hr-home *ngIf="user.role==='Hiring Manager'"></app-hr-home>
        <app-recruiter-home *ngIf="user.role==='Recruiter'"
            (emitNotificationModal)="openNotificationDrawer()"></app-recruiter-home>
        <app-candidate-home *ngIf="user.role==='Candidate'"></app-candidate-home>

        <nz-drawer [nzClosable]="false" [nzVisible]="visibleNotificationDrawer" nzPlacement="right"
            nzTitle="Notifications" (nzOnClose)="closeNotificationDrawer()" nzWidth="600px">
            <ng-container *nzDrawerContent>
                <nz-spin [nzSpinning]="dashboardMgr.isLoadingNotification">
                    <nz-tabset>
                        <nz-tab nzTitle="Unread">
                            <ul class="notification-list" *ngIf="dashboardMgr.notificationsList.length > 0">
                                <li *ngFor="let notification of dashboardMgr.notificationsList">
                                    <ng-container *ngIf="notification.status === 'unread'">
                                        <div class="notification-box" (click)="readNotification(notification.id)">
                                            <div class="notification-info">{{notification.message}}</div>
                                            <div class="notification-comment">{{notification.info_json?.comment}}</div>
                                            <nz-badge (click)="readNotification(notification.id)" class="ico-badge"
                                                nzColor="cyan"></nz-badge>
                                        </div>
                                    </ng-container>
                                </li>
                            </ul>
                            <div *ngIf="dashboardMgr.notificationsList.length === 0" class="no-data">
                                No notifications yet!
                            </div>
                        </nz-tab>
                        <nz-tab nzTitle="All">
                            <ul class="notification-list" *ngIf="dashboardMgr.notificationsList.length > 0">
                                <li *ngFor="let notification of dashboardMgr.notificationsList">
                                    <div class="notification-box">
                                        <div class="notification-info">{{notification.message}}</div>
                                        <div class="notification-comment">{{notification.info_json?.comment}}</div>
                                    </div>
                                </li>
                            </ul>
                        </nz-tab>
                    </nz-tabset>
                </nz-spin>

            </ng-container>
        </nz-drawer>
        <div class="jobs-modal" *ngIf="visibleJobModal">
            <nz-spin [nzSpinning]="jobsMgr.isLoadingModalJobs">
                <div class="jobs-modal-header">
                    <div class="d-flex align-middle">
                        <div class="d-flex align-middle">Lastest Jobs <span
                                class="jobs-counter">{{jobsMgr.latestJobCount}}</span></div>
                        <a routerLink="/jobs"> <button nz-button nzType="default" nzShape="round" nzSize="small"
                                class="btn-custom-icon btn-custom-sm ml-16"><svg width="20" height="20"
                                    viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="m13.337 7.845-7.173 7.173-1.178-1.179 7.172-7.172H5.837V5h9.166v9.167h-1.666V7.845z"
                                        fill="#1C1A20" fill-opacity=".7" />
                                </svg>
                                See all Jobs</button></a>
                    </div>
                    <span nz-icon class="ico-close" nzType="close" (click)="closeJobsModal()" nzTheme="outline"></span>
                </div>
                <div class="jobs-modal-body" *ngIf="jobsMgr.jobsActiveDataHome.length > 0">
                    <div class="gray-box">
                        <p>{{jobsMgr.latestJobCount}} New Jobs are available to process!</p>
                        <div>
                            <svg class="ico-info" nz-popover [nzPopoverContent]="contentTemplate"
                                nzPopoverPlacement="topLeft" width="17" height="16" viewBox="0 0 17 16" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M8.428 14.667a6.667 6.667 0 1 1 0-13.334 6.667 6.667 0 0 1 0 13.334zm0-1.334a5.334 5.334 0 1 0 0-10.667 5.334 5.334 0 0 0 0 10.667zm-.666-8.666h1.333V6H7.762V4.667zm0 2.666h1.333v4H7.762v-4z"
                                    fill="#1C1A20" fill-opacity=".7" />
                            </svg>
                            <ng-template #contentTemplate>
                                <div class="info-popover color-black">
                                    Jobs that are 7 days or <br> less are new.
                                </div>
                            </ng-template>
                        </div>
                    </div>
                    <div class="job-modal-listing">
                        <div class="listing-item" *ngFor="let data of jobsMgr.jobsActiveDataHome">
                            <div class="job-info">
                                <strong class="cursor-pointer" [title]="data.title">
                                    <span class="truncate-180">{{data.title}}</span> <svg
                                        *ngIf="isRecent(data.posting_date)" width="16" height="16" viewBox="0 0 16 16"
                                        fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M16 7.992a.56.56 0 0 0-.228-.45l.003-.005-1.934-1.126 1.113-1.943-.003-.002a.553.553 0 0 0-.447-.778v-.006H12.27V1.437h-.005a.549.549 0 0 0-.773-.449L11.49.983 9.522 2.128 8.433.226 8.43.23a.552.552 0 0 0-.892 0L7.532.226 6.425 2.16 4.518 1.05l-.002.005a.55.55 0 0 0-.773.45h-.005v2.231H1.536v.005a.553.553 0 0 0-.446.778l-.005.003 1.089 1.9-1.95 1.134.003.004a.56.56 0 0 0 0 .9l-.002.004L2.159 9.59l-1.113 1.943.003.002a.553.553 0 0 0 .446.778v.006h2.235v2.245h.004a.55.55 0 0 0 .773.449l.003.005 1.967-1.145 1.09 1.902.004-.003a.552.552 0 0 0 .892 0l.005.003 1.107-1.933 1.907 1.11.002-.005a.55.55 0 0 0 .773-.45h.005v-2.231h2.202v-.005a.553.553 0 0 0 .446-.778l.005-.003-1.089-1.9 1.95-1.134-.003-.004a.559.559 0 0 0 .227-.45zm-9.304 2.382-2.043-.91.902 1.574-.51.296-1.382-2.412.524-.305 1.977.87-.868-1.515.51-.296 1.381 2.412-.491.286zm.965-.561L6.28 7.4l1.694-.985.26.452-1.185.689.29.506 1.159-.674.259.451-1.16.675.316.55 1.184-.69.259.453-1.694.986zm3.923-2.283-1.37-1.412.533 1.899-.541.315-2.068-2.013.57-.332 1.42 1.498-.544-2.008.399-.231 1.45 1.48-.578-1.988.57-.332.7 2.81-.541.314z"
                                            fill="#5822D3" />
                                    </svg>
                                </strong>
                                <span class="small">{{data.posting_date | dateAgo}}</span>
                                <span class="small">ID {{data.remote_id ? data.remote_id : data['_id']}}</span>
                            </div>
                            <div class="job-action">
                                <button *ngIf="data.awaiting_count > 0" nz-button nzType="default" nzShape="round"
                                    (click)="processCandidates( data['_id'])">
                                    Process Candidates
                                </button>
                                <div class="info-label"
                                    *ngIf="data.awaiting_count === 0 && data.received_application_count === data.processed_application_count">
                                    <span nz-icon class="purple-icon" nzType="check" nzTheme="outline"></span>
                                    Candidates Processed
                                </div>
                                <div class="info-label"
                                    *ngIf="data.received_application_count > data.processed_application_count">
                                    <span nz-icon class="purple-icon" nzType="loading" nzTheme="outline"></span>
                                    Activating Candidate Processing
                                </div>
                                <svg class="ml-10" width="20" height="20" viewBox="0 0 20 20" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="m13.477 9.167-4.47-4.47 1.178-1.179L16.667 10l-6.482 6.482-1.178-1.179 4.47-4.47H3.333V9.167h10.144z"
                                        fill="#6A6A77" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-center" *ngIf="jobsMgr.jobsActiveDataHome.length === 0">No Jobs Available!
                </div>
                <div class="jobs-modal-footer">
                    <a nz-button nzType="link" (click)="loadMoreHomeJobs()"
                        [disabled]="jobsMgr.nextPageUrl === null">Load More</a>
                </div>
            </nz-spin>
        </div>
    </main>
</div>