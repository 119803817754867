<div class="fullscreen">
    <div class="results-wrap">
        <div class="loader" *ngIf="userMgr.verifingUser">
            <span></span>
            <span></span>
            <span></span>
        </div>

        <nz-result *ngIf="userMgr.isVerified" nzStatus="success" nzTitle="Email Verified!"
            nzSubTitle="Thank you, Your email has been verified. Please use the link below to login to your account.">
            <div nz-result-extra>
                <a routerLink="/login"><button nz-button nzType="primary">Login Now</button></a>
            </div>
        </nz-result>
        <nz-result *ngIf="userMgr.isTokenExpired" nzTitle="Email Verified Failed!" nzStatus="error"
            nzSubTitle="Your email could not be verified or already verified, Please contact your administrator clara@administrator.com if your are unable to login.">
            <div nz-result-extra>
                <a routerLink="/login"><button nz-button nzType="primary">Login Now</button></a>
            </div>
        </nz-result>
    </div>
</div>