<div class="page-banner">
    The current selection represents your default setting, which you can adjust by modifying these <br> weights for the
    new
    job.
    This is optional.
</div>
<form [formGroup]="validateForm" class="center-form">

    <div>
        <div class="chart-graybox">
            <apx-chart [series]="chartOptions.series!" [chart]="chartOptions.chart!" [labels]="chartOptions.labels"
                [theme]="chartOptions.theme!" [dataLabels]="chartOptions.dataLabels!"
                [plotOptions]="chartOptions.plotOptions!" [stroke]="chartOptions.stroke!"
                [legend]="chartOptions.legend!"></apx-chart>
        </div>
        <div *ngIf="!isAddingVairablesBox">
            <p>Adjust the weight for each of the following variables:</p>
            <div nz-row [nzGutter]="16" *ngIf="!isAddingVairablesBox">
                <div nz-col [nzSpan]="8" class="pt-8">
                    <div *ngFor="let setting of aiManager.aisSettingsActive" class="custom-radio">
                        <div>
                            <span class="group-title">{{getScoreName(setting.name) }}</span>

                        </div>
                    </div>
                </div>
                <div nz-col [nzSpan]="16">
                    <div class="field-label">
                        <span>NOT IMPORTANT</span>
                        <span>VERY IMPORTANT</span>
                    </div>
                    <div *ngFor="let setting of aiManager.aisSettingsActive" class="custom-radio">
                        <div>
                            <nz-radio-group (ngModelChange)="onRadioChange(setting.key, $event)"
                                formControlName="{{ setting.key }}" nzSize="small">
                                <label *ngFor="let option of getOptions()" nz-radio-button [nzValue]="option">
                                    {{ option }}
                                </label>
                            </nz-radio-group>
                        </div>
                    </div>
                    <div class="text-right mb-20">
                        <button nz-button nzSize="large" class="bg-gray font-normal" nzShape="round"
                            (click)="addingVariableBox()" [disabled]="aiManager.aisSettingsInactive.length === 0">
                            Add Matching Criteria
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <form class="shadow-box" *ngIf="isAddingVairablesBox" (ngSubmit)="submitNewSettingsForm()">
            <h4>Select Additional AI Matching Criteria</h4>
            <nz-checkbox-wrapper style="width: 100%;" (nzOnChange)="updateCheckedSettings($event)">
                <ul class="zero-variable">
                    <ng-container *ngFor="let setting of aiManager.aisSettingsInactive">
                        <!-- {{setting | json}} -->
                        <li>
                            <label nz-checkbox [nzValue]="setting">{{
                                getScoreName(setting.name) }}</label>
                        </li>
                    </ng-container>
                </ul>
            </nz-checkbox-wrapper>
            <div class="shadow-box-footer">
                <button nz-button (click)="addingVariableBox()" nzShape="round" class="font-norma">
                    Cancel
                </button>
                <button nz-button nzBlock nzSize="large" [nzType]="'primary'" class="ml-10" nzShape="round"
                    type="submit">
                    Add Changes
                </button>
            </div>
        </form>
    </div>

</form>