import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AssignJobService {
  constructor(private http: HttpClient) {}
  getUsers(role: string) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.get(
      environment.apiURL + `account/user/users/?role=${role}&page_size=100`,
      {
        headers: header,
      }
    );
  }
  getAssignJobs(jobObjectId: string) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.get(
      environment.apiURL +
        `deincore/partner/job/assignee/?job_obj_id=${jobObjectId}`,
      {
        headers: header,
      }
    );
  }
  assignJob(formData: any) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.post(
      environment.apiURL + 'deincore/partner/job/assignee/',
      formData,
      {
        headers: header,
      }
    );
  }
}
