import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpConfigInterceptor } from './interceptor/httpconfig.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DemoNgZorroAntdModule } from './ng-zorro-antd-module';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { NgApexchartsModule } from 'ng-apexcharts';
import { LoginComponent } from './pages/login/login.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { HeaderComponent } from './components/header/header.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { UsersComponent } from './pages/users/users.component';
import { JobsComponent } from './pages/jobs/jobs.component';
import { PartnersComponent } from './pages/partners/partners.component';
import { CreateCompanyComponent } from './pages/partners/create-company/create-company.component';
import { ApplicationsComponent } from './pages/jobs/applications/applications.component';
import { MyAccountComponent } from './pages/settings/my-account/my-account.component';
import { IntegrationsComponent } from './pages/settings/integrations/integrations.component';
import { CandidateProfileComponent } from './pages/jobs/applications/candidate-profile/candidate-profile.component';
import { AISettingsComponent } from './pages/settings/aisettings/aisettings.component';
import { CandidateSortingComponent } from './pages/settings/candidate-sorting/candidate-sorting.component';
import { DataSharingComponent } from './pages/settings/data-sharing/data-sharing.component';
import { ScreeningActionsComponent } from './pages/settings/screening-actions/screening-actions.component';
import { HRHomeComponent } from './pages/dashboard/hr-home/hr-home.component';
import { DeinHomeComponent } from './pages/dashboard/dein-home/dein-home.component';
import { CandidateHomeComponent } from './pages/dashboard/candidate-home/candidate-home.component';
import { JobDetailComponent } from './pages/jobs/applications/job-detail/job-detail.component';
import { AssignJobComponent } from './components/partials/assign-job/assign-job.component';
import { RegisterComponent } from './pages/register/register.component';
import { NotFoundComponent } from './pages/errors/not-found/not-found.component';
import { VerifyEmailComponent } from './pages/verify-email/verify-email.component';
import { GetStartedComponent } from './pages/partners/get-started/get-started.component';
import { AppIsGrantedDirective } from './directive/app-is-granted.directive';
import { PermissionService } from './Services/permission.service';
import { NotAuthorizedComponent } from './pages/errors/not-authorized/not-authorized.component';
import { NotificationsSettingsComponent } from './pages/settings/notifications-settings/notifications-settings.component';
import { CandidatesComponent } from './pages/users/candidates/candidates/candidates.component';
import { ProfileComponent } from './pages/users/candidates/profile/profile.component';
import { IntialsPipe } from './pips/intials.pipe';
import { ReplaceUnderscorePipe } from './pips/replace-underscore.pipe';
import { JobAISettingComponent } from './pages/jobs/applications/job-aisetting/job-aisetting.component';
import { DateAgoPipe } from './pips/pipes/date-ago.pipe';
import { CommentsComponent } from './components/partials/comments/comments.component';
import { CommentsApplicationComponent } from './components/partials/comments-application/comments-application.component';
import { CommentsCandidatesComponent } from './components/partials/comments-candidates/comments-candidates.component';
import { SortingCriteriaComponent } from './pages/jobs/applications/sorting-criteria/sorting-criteria.component';
import { AllJobsOverviewComponent } from './pages/dashboard/dein-home/all-jobs-overview/all-jobs-overview.component';
import { PartnerHomeComponent } from './pages/dashboard/partner-home/partner-home.component';
import { ConfirmIdentityComponent } from './pages/users/candidates/confirm-identity/confirm-identity.component';
import { RecruiterHomeComponent } from './pages/dashboard/recruiter-home/recruiter-home.component';
import { FollowUpQuestionsComponent } from './pages/users/candidates/follow-up-questions/follow-up-questions.component';
import { CandidatesListComponent } from './pages/candidates-list/candidates-list.component';
import { TermsAndConditionsComponent } from './pages/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { OptDataPolicyComponent } from './pages/opt-data-policy/opt-data-policy.component';
import { CreateCandidateAccountComponent } from './pages/create-candidate-account/create-candidate-account.component';
import { JobProcessingComponent } from './pages/settings/job-processing/job-processing.component';
import { WelcomeComponent } from './pages/welcome/welcome.component';
import { CandidateMyProfileComponent } from './pages/candidate-my-profile/candidate-my-profile.component';
import { JobFollowUPQuestionsComponent } from './pages/jobs/applications/job-follow-upquestions/job-follow-upquestions.component';
import { UnsubscribeEmailComponent } from './pages/unsubscribe-email/unsubscribe-email.component';
import { UploadNewJobComponent } from './pages/jobs/upload-new-job/upload-new-job.component';
import { DndDirective } from './directive/dnd.directive';
import { UploadFileComponent } from './pages/jobs/upload-new-job/upload-file/upload-file.component';
import { EditDescriptionComponent } from './pages/jobs/upload-new-job/edit-description/edit-description.component';
import { SJMfollowUpQuestionsComponent } from './pages/jobs/upload-new-job/sjmfollow-up-questions/sjmfollow-up-questions.component';
import { SJMAISettingsComponent } from './pages/jobs/upload-new-job/sjmaisettings/sjmaisettings.component';
import { UploadCandidatesComponent } from './pages/jobs/upload-candidates/upload-candidates.component';
import { UploadResumsComponent } from './pages/jobs/upload-candidates/upload-resums/upload-resums.component';
import { LoaderComponent } from './pages/loader/loader.component';
import { ImpersonationComponent } from './pages/impersonation/impersonation.component';
import { ApplicationMaterialsComponent } from './pages/jobs/upload-new-job/application-materials/application-materials.component';
import { ApplyJobComponent } from './pages/jobs/apply-job/apply-job.component';
import { MyApplicationsComponent } from './pages/users/candidates/my-applications/my-applications.component';
import { ApplicationSubmittedComponent } from './pages/users/candidates/application-submitted/application-submitted.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { SubscriptionComponent } from './pages/settings/subscription/subscription.component';
registerLocaleData(en);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    ForgotPasswordComponent,
    SideMenuComponent,
    HeaderComponent,
    ResetPasswordComponent,
    UsersComponent,
    JobsComponent,
    PartnersComponent,
    CreateCompanyComponent,
    ApplicationsComponent,
    MyAccountComponent,
    IntegrationsComponent,
    CandidateProfileComponent,
    AISettingsComponent,
    CandidateSortingComponent,
    DataSharingComponent,
    ScreeningActionsComponent,
    HRHomeComponent,
    DeinHomeComponent,
    CandidateHomeComponent,
    JobDetailComponent,
    AssignJobComponent,
    RegisterComponent,
    NotFoundComponent,
    VerifyEmailComponent,
    GetStartedComponent,
    AppIsGrantedDirective,
    NotAuthorizedComponent,
    NotificationsSettingsComponent,
    CandidatesComponent,
    ProfileComponent,
    IntialsPipe,
    ReplaceUnderscorePipe,
    JobAISettingComponent,
    DateAgoPipe,
    CommentsComponent,
    CommentsApplicationComponent,
    CommentsCandidatesComponent,
    SortingCriteriaComponent,
    AllJobsOverviewComponent,
    PartnerHomeComponent,
    ConfirmIdentityComponent,
    RecruiterHomeComponent,
    FollowUpQuestionsComponent,
    CandidatesListComponent,
    TermsAndConditionsComponent,
    PrivacyPolicyComponent,
    OptDataPolicyComponent,
    CreateCandidateAccountComponent,
    JobProcessingComponent,
    WelcomeComponent,
    CandidateMyProfileComponent,
    JobFollowUPQuestionsComponent,
    UnsubscribeEmailComponent,
    UploadNewJobComponent,
    DndDirective,
    UploadFileComponent,
    EditDescriptionComponent,
    SJMfollowUpQuestionsComponent,
    SJMAISettingsComponent,
    UploadCandidatesComponent,
    UploadResumsComponent,
    LoaderComponent,
    ImpersonationComponent,
    ApplicationMaterialsComponent,
    ApplyJobComponent,
    MyApplicationsComponent,
    ApplicationSubmittedComponent,
    SubscriptionComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    DemoNgZorroAntdModule,
    DragDropModule,
    NgApexchartsModule,
    PdfViewerModule,
  ],
  providers: [
    { provide: NZ_I18N, useValue: en_US },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true,
    },
    PermissionService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
