// app-is-granted.directive.ts
import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { PermissionService } from '../Services/permission.service';

interface Permission {
  content_type: string;
  desiredPermission: string;
}

@Directive({
  selector: '[appIsGranted]',
})
export class AppIsGrantedDirective {
  @Input() set appIsGranted(permission: Permission) {
    const hasPermission = this.permissionService.hasPermission(
      permission.content_type,
      permission.desiredPermission
    );

    if (hasPermission) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private permissionService: PermissionService
  ) {}
}
