import { Component } from '@angular/core';

@Component({
  selector: 'app-opt-data-policy',
  templateUrl: './opt-data-policy.component.html',
  styleUrl: './opt-data-policy.component.scss'
})
export class OptDataPolicyComponent {

}
