<div class="page">
    <main class="main" *ngIf="sjmMgr.jobInfo">

        <div class="d-flex align-middle color-black">
            <h1>{{sjmMgr.jobInfo.title}}</h1>
            <div class="ml-10" *ngIf="jobObjectId">JOB ID
                {{jobObjectId}}</div>
        </div>
        <div class="white-panel">
            <div class="border-box">
                <p class="color-theme font-weight-medium text-center">Your application was received!</p>
                <p class="color-theme">We will follow up shortly with some follow-up questions based on your application
                    materials.</p>
                <p class="text-center">Check the spam folder if you do not receive an email within 5 minutes.</p>
                <div class="border-box-alert text-center">
                    <b>Join CLARA today to create a profile and review your appliaction materials</b>
                </div>
            </div>
            <div class="job-detail color-black-container">
                <div class="left-panel">
                    <p><b>About the job</b></p>
                    <p [innerHTML]="sjmMgr.jobInfo.description"></p>

                </div>
                <div class="right-panel">
                    <h3>Details</h3>
                    <ul class="info-list">
                        <li>
                            <span>Position</span>
                            <span>{{sjmMgr.jobInfo.position}}</span>
                        </li>
                        <li>
                            <span>Location</span>
                            <span>{{sjmMgr.jobInfo.location}}</span>
                        </li>
                        <li>
                            <span>Role Duration</span>
                            <span>{{sjmMgr.jobInfo.role_duration}}</span>
                        </li>

                    </ul>
                    <h3>Job Posting</h3>
                    <ul class="info-list">
                        <li>
                            <span>Job Name</span>
                            <span>{{sjmMgr.jobInfo.title}}</span>
                        </li>
                        <li>
                            <span>Job ID</span>
                            <span>{{jobObjectId}}</span>
                        </li>
                        <li>
                            <span>Date Posted</span>
                            <span>{{sjmMgr.jobInfo.posting_date}}</span>
                        </li>

                    </ul>
                    <h3>Education</h3>
                    <p *ngIf="sjmMgr.jobInfo.extracted_details.education">
                        {{sjmMgr.jobInfo.extracted_details.education.degree}}
                    </p>

                </div>
            </div>
        </div>
    </main>
</div>