<div class="page">

    <!-- Side menu -->
    <app-side-menu></app-side-menu>
    <!-- main -->
    <main class="main">
        <nz-breadcrumb>
            <nz-breadcrumb-item>
                <a routerLink="/"><span nz-icon nzType="home"></span></a>
            </nz-breadcrumb-item>
            <nz-breadcrumb-item>Partners</nz-breadcrumb-item>
        </nz-breadcrumb>

        <div nz-row [nzGutter]="16">
            <div nz-col [nzSpan]="8">
                <div class="heading-flex">
                    <h1>Partners</h1>
                    <button nz-button nzType="primary" (click)="addModal()" nzShape="round"><span nz-icon nzType="plus"
                            nzTheme="outline"></span> Add New Partner</button>
                </div>
                <nz-spin [nzSpinning]="this.usersServiceManger.isLoadingUsers">
                    <nz-table [nzFrontPagination]="false" #data [nzData]="usersServiceManger.partnersData">
                        <thead>
                            <tr>
                                <th scope="col">Partner</th>
                                <th scope="col">Added</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of data.data">
                                <td class="capitalize-first">{{data.partner_name}}</td>
                                <td>{{data.created_datetime | date : "dd/MM/yyyy"}}</td>
                                <td><a nz-popconfirm nzPopconfirmTitle="Are you sure delete this company?"
                                        nzPopconfirmPlacement="bottom" (nzOnConfirm)="onDeletePartner(data.id)"
                                        nzPopconfirmPlacement="topLeft">Remove
                                        Partner</a>
                                </td>
                            </tr>
                        </tbody>
                    </nz-table>
                    <div class="pagination">
                        <nz-pagination *ngIf="usersServiceManger.totalPartners > 10" [nzPageIndex]="1"
                            (nzPageIndexChange)="handlePageIndexChange($event)"
                            [nzTotal]="usersServiceManger.totalPartners"></nz-pagination>
                    </div>
                </nz-spin>
            </div>
        </div>
        <nz-modal [(nzVisible)]="usersServiceManger.isPartnerModal" (nzOnCancel)="handleCancel()"
            nzTitle="Add New Partner" [nzFooter]="null" nzWidth="500px">
            <ng-container *nzModalContent>
                <div class="center-form">
                    <div class="form-header">
                        <p>Enter Partner Information</p>
                    </div>
                    <form nz-form [formGroup]="validateForm" class="login-form">
                        <label for="partner_name">Partner Name*</label>
                        <nz-form-item>
                            <nz-form-control nzErrorTip="Please input your Company Name!">
                                <input type="text" nzSize="large" nz-input formControlName="partner_name"
                                    id="partner_name" />
                            </nz-form-control>
                        </nz-form-item>

                        <div class="sub-title">
                            Invite an Account Owner
                        </div>
                        <label for="first_name">First Name*</label>
                        <nz-form-item>
                            <nz-form-control nzErrorTip="Please input your First Name!">
                                <input type="text" nzSize="large" nz-input formControlName="first_name"
                                    id="first_name" />
                            </nz-form-control>
                        </nz-form-item>

                        <label for="last_name">Last Name*</label>
                        <nz-form-item>
                            <nz-form-control nzErrorTip="Please input your Last Name!">
                                <input type="text" nzSize="large" nz-input formControlName="last_name" id="last_name" />
                            </nz-form-control>
                        </nz-form-item>

                        <label for="email">Email*</label>
                        <nz-form-item>
                            <nz-form-control nzErrorTip="Please input your valid Email!">
                                <input type="email" nzSize="large" nz-input formControlName="email" id="email" />
                            </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                            <label nz-checkbox formControlName="stripe_customer"
                                (ngModelChange)="customerSubscriptionChange($event)">
                                <span>Stripe Subscription</span>
                            </label>
                        </nz-form-item>

                        <label for="email">Subscription tier*</label>
                        <nz-form-item>
                            <nz-form-control nzErrorTip="Please select Clara tier!">
                                <nz-select nzSize="large" nzPlaceHolder="Select tier" class="d-block"
                                    formControlName="plan" [nzDisabled]="!validateForm.get('stripe_customer')?.value">
                                    <nz-option nzValue="Startup" nzLabel="Startup"></nz-option>
                                    <nz-option nzValue="Startup - Free Trial"
                                        nzLabel="Startup - Free Trial"></nz-option>
                                    <nz-option nzValue="Essential" nzLabel="Essential"></nz-option>
                                    <nz-option nzValue="Essential - Free Trial"
                                        nzLabel="Essential - Free Trial"></nz-option>
                                    <nz-option nzValue="Business" nzLabel="Business"></nz-option>
                                    <nz-option nzValue="Business - Free Trial"
                                        nzLabel="Business - Free Trial"></nz-option>
                                    <nz-option nzValue="Enterprise" nzLabel="Enterprise"></nz-option>
                                    <nz-option nzValue="Enterprise - Free Trial"
                                        nzLabel="Enterprise - Free Trial"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>


                        <div class="custom-footer">
                            <button (click)="submitForm()" nz-button nzBlock nzSize="large" nzType="primary"
                                class="mb-10" nzShape="round">
                                <span *ngIf="usersServiceManger.isLoading" nz-icon nzType="loading"
                                    nzTheme="outline"></span>Submit</button>
                            <button nz-button nzType="link" (mousedown)="handleCancel()" nzBlock
                                nzShape="round">Cancel</button>
                        </div>

                    </form>
                </div>
            </ng-container>

        </nz-modal>
    </main>
</div>