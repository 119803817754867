<div class="all-jobs" *ngIf="dashboardMgr.jobApplicationOverveiw.length > 0">
    <div class="heading">
        <p>Active Jobs <span class="jobs-counter">{{dashboardMgr.jobApplicationOverveiw.length}}</span></p>
    </div>
    <div class="all-jobs-flex" *ngFor="let data of dashboardMgr.jobApplicationOverveiw">
        <a class="link" [routerLink]="['/jobs/candidates', data.job_obj_id]" [queryParams]="{ atsName: data.ats }"></a>

        <div class="job-item">
            <div class="job-counts">
                <span class="value-sm text-left">{{data.job_title}}</span>
                <span *ngIf="data.remote_id" class="job-id text-left">ID {{data.remote_id}}</span>
            </div>
        </div>
        <div class="job-item">
            <div class="job-counts">
                <div class="d-flex justify-content-center align-items-baseline">
                    <span class="value-sm">{{data.applications_analytics.all.current_count}}</span>
                    <span class="percentage">{{data.applications_analytics.all.percentage}}%</span>
                </div>
                <span class="job-badge"
                    [ngClass]="{'down-trend': (data.applications_analytics.all.current_count - data.applications_analytics.all.last_count) < 0}">
                    <span class="job-icon"
                        *ngIf="data.applications_analytics.all.current_count - data.applications_analytics.all.last_count !== 0">
                        <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M1.24375 8.5L0.5 7.75625L6.40625 1.85H1.2V0.800003H8.2V7.8H7.15V2.59375L1.24375 8.5Z" />
                        </svg>

                    </span>
                    <span>{{data.applications_analytics.all.current_count -
                        data.applications_analytics.all.last_count}}</span>
                </span>
                <span class="job-title">All Applications</span>
            </div>
        </div>
        <div class="job-item">
            <div class="job-counts">
                <div class="d-flex justify-content-center align-items-baseline">
                    <span class="value-sm">{{data.applications_analytics.not_qualified.current_count}}</span>
                    <span class="percentage">{{data.applications_analytics.not_qualified.percentage}}%</span>
                </div>

                <span class="job-badge"
                    [ngClass]="{'down-trend': (data.applications_analytics.not_qualified.current_count - data.applications_analytics.not_qualified.last_count) < 0}">
                    <span class="job-icon"
                        *ngIf="data.applications_analytics.not_qualified.current_count - data.applications_analytics.not_qualified.last_count !== 0">
                        <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M1.24375 8.5L0.5 7.75625L6.40625 1.85H1.2V0.800003H8.2V7.8H7.15V2.59375L1.24375 8.5Z" />
                        </svg>

                    </span>
                    <span>{{data.applications_analytics.not_qualified.current_count -
                        data.applications_analytics.not_qualified.last_count}}</span>
                </span>
                <span class="job-title">Not Qualified</span>
            </div>
        </div>
        <div class="job-item">
            <div class="job-counts">
                <div class="d-flex justify-content-center align-items-baseline">
                    <span class="value-sm">{{data.applications_analytics.pre_qualified.current_count}}</span>
                    <span class="percentage">{{data.applications_analytics.pre_qualified.percentage}}%</span>
                </div>
                <span class="job-badge"
                    [ngClass]="{'down-trend': (data.applications_analytics.pre_qualified.current_count - data.applications_analytics.pre_qualified.last_count) < 0}">
                    <span class="job-icon"
                        *ngIf="data.applications_analytics.pre_qualified.current_count - data.applications_analytics.pre_qualified.last_count !== 0">
                        <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M1.24375 8.5L0.5 7.75625L6.40625 1.85H1.2V0.800003H8.2V7.8H7.15V2.59375L1.24375 8.5Z" />
                        </svg>

                    </span>
                    <span>{{data.applications_analytics.pre_qualified.current_count -
                        data.applications_analytics.pre_qualified.last_count}}</span>
                </span>
                <span class="job-title">Pre Qualified</span>
            </div>
        </div>
        <div class="job-item">
            <div class="job-counts">
                <div class="d-flex justify-content-center align-items-baseline">
                    <span class="value-sm">{{data.applications_analytics.qualified.current_count}}</span>
                    <span class="percentage">{{data.applications_analytics.qualified.percentage}}%</span>
                </div>
                <span class="job-badge"
                    [ngClass]="{'down-trend': (data.applications_analytics.qualified.current_count - data.applications_analytics.qualified.last_count) < 0}">

                    <span class="job-icon"
                        *ngIf="data.applications_analytics.qualified.current_count - data.applications_analytics.qualified.last_count !== 0">
                        <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M1.24375 8.5L0.5 7.75625L6.40625 1.85H1.2V0.800003H8.2V7.8H7.15V2.59375L1.24375 8.5Z" />
                        </svg>

                    </span>
                    <span>{{data.applications_analytics.qualified.current_count -
                        data.applications_analytics.qualified.last_count}}</span>
                </span>
                <span class="job-title">Qualified</span>
            </div>
        </div>
        <div class="job-item">
            <div class="job-counts">
                <div class="d-flex justify-content-center align-items-baseline">
                    <span class="value-sm">{{data.applications_analytics.final_decision.current_count}}</span>
                    <span class="percentage">
                        &lt;{{data.applications_analytics.final_decision.percentage}}%</span>
                </div>
                <span class="job-badge"
                    [ngClass]="{'down-trend': (data.applications_analytics.final_decision.current_count - data.applications_analytics.final_decision.last_count) < 0}">
                    <span class="job-icon"
                        *ngIf="data.applications_analytics.final_decision.current_count - data.applications_analytics.final_decision.last_count !== 0">
                        <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M1.24375 8.5L0.5 7.75625L6.40625 1.85H1.2V0.800003H8.2V7.8H7.15V2.59375L1.24375 8.5Z" />
                        </svg>

                    </span>
                    <span>{{data.applications_analytics.final_decision.current_count -
                        data.applications_analytics.final_decision.last_count}}</span>
                </span>
                <span class="job-title">Final Decision</span>
            </div>
        </div>
    </div>
</div>