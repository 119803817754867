import { Component } from '@angular/core';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrl: './welcome.component.scss',
})
export class WelcomeComponent {
  user: any;
  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user') || '{}');
  }
}
