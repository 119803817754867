import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TabCommunicationService } from './tab-communication.service';

import { Observable, of } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  isSubmenu: boolean = false;
  isImpersonation: boolean = false;
  constructor(
    private router: Router,
    private http: HttpClient,
    private tabCommunicationService: TabCommunicationService
  ) {}
  login(email: string, password: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),

      withCredentials: true,
    };

    return this.http.post(
      environment.apiURL + 'account/user/session/',
      { email: email, password: password },
      httpOptions
    );
  }
  isLoggedIn(): boolean {
    const isToken = this.getCookie('csrftoken');
    return !!isToken;
  }
  logout() {
    this.http
      .delete(environment.apiURL + 'account/user/session/')
      .subscribe(() => {
        this.removeCookie('csrftoken');
        this.removeCookie('user');
        localStorage.clear();
        this.router.navigate(['/login']);
      });

    this.tabCommunicationService.setUserChange();
    this.isSubmenu = false;
    this.isImpersonation = false;
  }
  private getCookie(name: string): string | null {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop()?.split(';').shift() || null;
    return null;
  }
  private removeCookie(name: string): void {
    const pastDate = new Date(0);
    document.cookie = `${name}=; expires=${pastDate.toUTCString()}; path=/;`;
  }
}
