<div class="fullscreen bg-gradient">
    <div class="login-wrap">
        <h1 class="font-heading">Welcome to Clara</h1>
        <p class="text-center">There’s 3 steps to this setup:</p>
        <div class="steps-box">
            <div class="step-dot" (click)="goToStep(1)" [class.active]="currentStep === 1"></div>
            <div class="step-dot" (click)="goToStep(2)" [class.active]="currentStep === 2"></div>
            <div class="step-dot" (click)="goToStep(3)" [class.active]="currentStep === 3"></div>
        </div>

        <nz-alert class="pb-20" *ngIf="isError" nzType="error" nzMessage="Invalid data!" nzShowIcon></nz-alert>

        <form nz-form [formGroup]="validateForm">
            <ng-container *ngIf="currentStep === 1">
                <h2 class="font-heading">First things first, let’s get started:</h2>
                <div nz-row [nzGutter]="16">
                    <div nz-col [nzSpan]="12">
                        <label for="first_name">First Name*</label>
                        <nz-form-item>
                            <nz-form-control nzErrorTip="Please input your first name!">
                                <input nzSize="large" nz-input formControlName="first_name" placeholder="First Name"
                                    id="first_name" />
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzSpan]="12">
                        <label for="last_name">Last Name*</label>
                        <nz-form-item>
                            <nz-form-control nzErrorTip="Please input your first name!">
                                <input nzSize="large" nz-input formControlName="last_name" placeholder="Last Name"
                                    id="last_name" />
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzSpan]="12">
                        <label for="email">Email*</label>
                        <nz-form-item>
                            <nz-form-control nzErrorTip="Please input your Email!">
                                <input nzSize="large" nz-input formControlName="email" placeholder="Email" id="email" />
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzSpan]="12">
                        <label for="password">New Password*</label>
                        <nz-form-item>
                            <nz-form-control
                                nzErrorTip="Please input your New Password at least 8 characters and 1 special character!">
                                <nz-input-group [nzSuffix]="suffixTemplate" nzSize="large">
                                    <input [type]="passwordVisible ? 'text' : 'password'" nzSize="large" nz-input
                                        formControlName="password" placeholder="Password" id="password" />
                                </nz-input-group>
                                <ng-template #suffixTemplate>
                                    <span nz-icon [nzType]="passwordVisible ? 'eye' : 'eye-invisible'"
                                        (click)="passwordVisible = !passwordVisible"></span>
                                </ng-template>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>

                <nz-form-item>
                    <div class="register-info">
                        <div class="register-info-item">
                            <span nz-icon nzType="check" nzTheme="outline" class="pr-10"></span>
                            <span>Must be at least 8 characters</span>
                        </div>
                        <div class="register-info-item">
                            <span nz-icon nzType="check" nzTheme="outline" class="pr-10"></span>
                            <span>Must contain at least 1 special character</span>
                        </div>
                    </div>
                </nz-form-item>
                <nz-form-item>
                    <label nz-checkbox formControlName="accept_terms">
                        <span>By clicking Create Account, You agree to the <a class="link"
                                routerLink="/terms-and-conditions">Terms and
                                Conditions</a> and <a class="link" routerLink="/privacy-policy">Privacy Policy</a> of
                            Clara.</span>
                    </label>
                </nz-form-item>
                <nz-form-item>
                    <label nz-checkbox formControlName="age_confirmation">
                        <span>I am 18 years or older.</span>
                    </label>
                </nz-form-item>

                <nz-form-item>
                    <div class="text-center">
                        <p>Opt to use personal information in accordance with our <a class="link"
                                routerLink="/opt-data-policy">opt-data-policy</a> for
                            a
                            candidate summary.</p>
                        <nz-radio-group formControlName="opt">
                            <label nz-radio-button nzValue="Opt In">Opt In</label>
                            <label nz-radio-button nzValue="Opt Out">Opt Out</label>
                        </nz-radio-group>
                    </div>
                </nz-form-item>
            </ng-container>
            <ng-container *ngIf="currentStep === 2">
                <h2 class="font-heading">Tell us more about yourself.</h2>
                <p>The more we know about our members, the better we can dazzle our Partners when they're scouting for
                    new talent!</p>
                <label for="professional_network_url">Add your professional network URL (optional)</label>
                <nz-form-item>
                    <nz-form-control>
                        <input nzSize="large" nz-input id="professional_network_url"
                            formControlName="professional_network_url" />
                    </nz-form-control>
                </nz-form-item>
                <label for="other_url">Add any other important URLs (e.g. a personal website) (optional)</label>
                <nz-form-item>
                    <nz-form-control>
                        <input nzSize="large" nz-input id="other_url" formControlName="other_url" />
                    </nz-form-control>
                </nz-form-item>
            </ng-container>
            <ng-container *ngIf="currentStep === 3">
                <h2 class="font-heading">Next, consider providing the following optional <span class="ico-sm" nz-popover
                        [nzPopoverContent]="contentTemplate" nzPopoverPlacement="topLeft" nz-icon nzType="info-circle"
                        nzTheme="outline"></span> information.
                </h2>
                <p>CLARA and DEI Network (our parent company) prioritize recognizing and appreciating talent. Our goal
                    is to enhance
                    opportunities for members through top-tier partnerships and deep insights. We leverage data
                    ethically to gain valuable
                    insights and foster growth and development. Your privacy is important to us - we won't sell your
                    data. We will utilize
                    it internally to improve our products for the CLARA community. Share as much or as little as you're
                    comfortable with.
                    Welcome to CLARA!
                </p>
                <ng-template #contentTemplate>
                    <div class="info-popover">
                        We gather demographic information to better understand our user base. This information is
                        confidential. Privacy and
                        security are top priorities. To learn more check out our <a class="link"
                            routerLink="/privacy-policy"> Privacy Policy</a>.
                    </div>
                </ng-template>
                <div nz-row [nzGutter]="16">
                    <div nz-col [nzSpan]="12">
                        <label for="age">Age</label>
                        <nz-form-item>
                            <nz-form-control>
                                <input nzSize="large" nz-input nzPlaceHolder="E.g 45" formControlName="age" id="age" />
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzSpan]="12">
                        <label for="education">Education</label>
                        <nz-form-item>
                            <nz-form-control>
                                <nz-select [nzSuffixIcon]="customSuffixIcon" nzShowSearch nzAllowClear
                                    nzPlaceHolder="Select an option" id="education" nzSize="large"
                                    formControlName="education">
                                    <nz-option nzLabel="No Education" nzValue="No Education"></nz-option>
                                    <nz-option nzLabel="High School Diploma/GED"
                                        nzValue="High School Diploma/GED"></nz-option>
                                    <nz-option nzLabel="Some College/Associate Degree"
                                        nzValue="Some College/Associate Degree"></nz-option>
                                    <nz-option nzLabel="Bachelor's Degree" nzValue="Bachelor's Degree"></nz-option>
                                    <nz-option nzLabel="Master's Degree" nzValue="Master's Degree"></nz-option>
                                    <nz-option nzLabel="Doctoral Degree" nzValue="Doctoral Degree"></nz-option>
                                    <nz-option nzLabel="Professional Degree (e.g., MD, JD)"
                                        nzValue="Professional Degree (e.g., MD, JD)"></nz-option>

                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzSpan]="12">
                        <label for="gender">Gender</label>
                        <nz-form-item>
                            <nz-form-control>
                                <nz-select [nzSuffixIcon]="customSuffixIcon" nzShowSearch nzAllowClear
                                    nzPlaceHolder="Select an option" id="gender" nzSize="large"
                                    formControlName="gender">
                                    <nz-option nzLabel="Male" nzValue="Male"></nz-option>
                                    <nz-option nzLabel="Female" nzValue="Female"></nz-option>
                                    <nz-option nzLabel="Non-binary" nzValue="Non-binary"></nz-option>
                                    <nz-option nzLabel="Prefer not to say" nzValue="Prefer not to say"></nz-option>
                                    <nz-option nzLabel="Other (please specify)"
                                        nzValue="Other (please specify)"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzSpan]="12">
                        <label for="income_status">Income Status</label>
                        <nz-form-item>
                            <nz-form-control>
                                <nz-select [nzSuffixIcon]="customSuffixIcon" nzShowSearch nzAllowClear
                                    nzPlaceHolder="Select an option" id="income_status" nzSize="large"
                                    formControlName="income_status">
                                    <nz-option nzLabel="Under $25,000" nzValue="Under $25,000"></nz-option>
                                    <nz-option nzLabel="$25,000 - $50,000" nzValue="$25,000 - $50,000"></nz-option>
                                    <nz-option nzLabel="$50,001 - $75,000" nzValue="$50,001 - $75,000"></nz-option>
                                    <nz-option nzLabel="$75,001 - $100,000" nzValue="$75,001 - $100,000"></nz-option>
                                    <nz-option nzLabel="Over $100,000" nzValue="Over $100,000"></nz-option>
                                    <nz-option nzLabel="Prefer not to say" nzValue="Prefer not to say"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzSpan]="12">
                        <label for="employment_status">Employment Status</label>
                        <nz-form-item>
                            <nz-form-control>
                                <nz-select [nzSuffixIcon]="customSuffixIcon" nzShowSearch nzAllowClear
                                    nzPlaceHolder="Select an option" id="employment_status" nzSize="large"
                                    formControlName="employment_status">
                                    <nz-option nzLabel="Employed full-time" nzValue="Employed full-time"></nz-option>
                                    <nz-option nzLabel="Employed part-time" nzValue="Employed part-time"></nz-option>
                                    <nz-option nzLabel="Self-employed" nzValue="Self-employed"></nz-option>
                                    <nz-option nzLabel="Unemployed" nzValue="Unemployed"></nz-option>
                                    <nz-option nzLabel="Student" nzValue="Student"></nz-option>
                                    <nz-option nzLabel="Retired" nzValue="Retired"></nz-option>
                                    <nz-option nzLabel="Homemaker" nzValue="Homemaker"></nz-option>
                                    <nz-option nzLabel="Other (please specify)"
                                        nzValue="Other (please specify)"></nz-option>

                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzSpan]="12">
                        <label for="race">Race</label>
                        <nz-form-item>
                            <nz-form-control>

                                <nz-select [nzSuffixIcon]="customSuffixIcon" nzMode="multiple"
                                    nzPlaceHolder="Select an option" id="race" nzSize="large" formControlName="race">
                                    <nz-option *ngFor="let item of raceOptions" [nzLabel]="item"
                                        [nzValue]="item"></nz-option>
                                </nz-select>

                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzSpan]="24">
                        <label for="ethnicity">Ethnicity</label><br>
                        <span>I identify my ethnicity as:</span>
                        <nz-form-item>
                            <nz-form-control>
                                <input nzSize="large" nz-input id="ethnicity" nzPlaceHolder="E.g.: Hmong and Italian"
                                    formControlName="ethnicity" />
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
            </ng-container>
            <ng-template #customSuffixIcon>
                <span nz-icon nzType="caret-down" nzTheme="outline"></span>
            </ng-template>
            <div class="custom-footer">
                <button (click)="submitForm()" *ngIf="currentStep === 3" nz-button nzBlock nzSize="large"
                    [nzType]="'text'" nzShape="round" class="mb-10"><span *ngIf="this.userMgr.isAddingUser" nz-icon
                        nzType="loading" nzTheme="outline"></span>
                    Skip and Create Account</button>

                <button (click)="nextStep()" nz-button nzBlock nzSize="large" [nzType]="'primary'" nzShape="round"
                    [disabled]="!validateForm.get('accept_terms')?.value || !validateForm.get('age_confirmation')?.value || !validateForm.valid"><span
                        *ngIf="this.userMgr.isAddingUser" nz-icon nzType="loading" nzTheme="outline"></span>
                    {{currentStep === 3 ? 'Create Account' : 'Next: add your professional URL'}}</button>
                <div class="footer-text">Already have an account? <a routerLink="/login"> Go to
                        login</a></div>
            </div>
        </form>
    </div>
</div>