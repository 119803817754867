<header class="main-header">
    <a class="btn-impersonation active" (click)="exitImpersonation()">
        <span><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="izll4lw3ma" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24"
                    height="24">
                    <path fill="#D9D9D9" d="M0 0h24v24H0z" />
                </mask>
                <g mask="url(#izll4lw3ma)">
                    <path
                        d="M1.798 19.308v-2.223c0-.516.133-.976.4-1.38a2.72 2.72 0 0 1 1.065-.944c.95-.465 1.906-.823 2.867-1.075.96-.252 2.017-.378 3.168-.378 1.151 0 2.207.126 3.168.378.961.252 1.917.61 2.867 1.075.445.225.8.54 1.066.944.266.404.399.864.399 1.38v2.223h-15zm17 0v-2.346c0-.657-.16-1.282-.482-1.877a4.546 4.546 0 0 0-1.368-1.531c.67.1 1.307.255 1.91.464.602.21 1.177.458 1.725.744.516.275.915.6 1.197.974.281.374.422.783.422 1.226v2.346h-3.404zm-9.5-7.616c-.962 0-1.786-.342-2.472-1.028-.685-.685-1.028-1.51-1.028-2.472s.343-1.786 1.028-2.471c.686-.686 1.51-1.029 2.472-1.029.963 0 1.787.343 2.472 1.029.685.685 1.028 1.509 1.028 2.471 0 .963-.343 1.787-1.028 2.472-.685.686-1.51 1.028-2.472 1.028zm8.635-3.5c0 .963-.343 1.787-1.029 2.472-.685.686-1.509 1.028-2.471 1.028-.113 0-.257-.012-.431-.038a3.793 3.793 0 0 1-.43-.085c.394-.474.697-1 .908-1.578a5.19 5.19 0 0 0-.006-3.593 5.78 5.78 0 0 0-.903-1.583 1.87 1.87 0 0 1 .43-.1 4.04 4.04 0 0 1 .432-.023c.962 0 1.786.343 2.471 1.029.686.685 1.029 1.509 1.029 2.471zM3.298 17.808h12v-.723a1.01 1.01 0 0 0-.157-.558c-.104-.163-.27-.305-.497-.427a11.797 11.797 0 0 0-2.542-.964 11.5 11.5 0 0 0-2.804-.328 11.5 11.5 0 0 0-2.804.328c-.872.218-1.719.54-2.542.964-.227.122-.393.264-.497.427a1.01 1.01 0 0 0-.157.558v.723zm6-7.616c.55 0 1.02-.195 1.413-.587.391-.392.587-.863.587-1.413s-.196-1.02-.587-1.412a1.926 1.926 0 0 0-1.413-.588c-.55 0-1.02.196-1.412.588a1.926 1.926 0 0 0-.588 1.412c0 .55.196 1.021.588 1.413.391.392.862.587 1.412.587z"
                        fill="#1C1A20" fill-opacity=".7" />
                </g>
            </svg>
        </span>

        <span class="close-icon">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="m12 10.586 4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636l4.95 4.95z"
                    fill="#5822D3" />
            </svg>

        </span>
    </a>
    <div class="form-impersonation">
        <div class="form-item">
            <nz-select class="width-300 black-select-placeholder" nzSize="large" [nzSuffixIcon]="customSuffixIcon"
                [(ngModel)]="selectedPartner">
                <nz-option [nzValue]="selectedPartner" [nzLabel]="selectedPartner"></nz-option>
            </nz-select>
        </div>
        <div class="form-item">
            <nz-select class="width-300 black-select-placeholder" nzSize="large" [nzSuffixIcon]="customSuffixIcon"
                [(ngModel)]="selectedUser">
                <nz-option [nzValue]="selectedUser" [nzLabel]="selectedUser"></nz-option>
            </nz-select>
        </div>
        <button *ngIf="imprMgr.impersonating" nz-button nzType="default" nzSize="large" nzShape="round"
            (click)="exitImpersonation()">Exit
            Impersonation</button>
    </div>
    <ng-template #customSuffixIcon>
        <span nz-icon nzType="caret-down" nzTheme="outline"></span>
    </ng-template>
</header>