import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardManagerService } from 'src/app/Managers/dashboard-manager.service';

@Component({
  selector: 'app-candidate-home',
  templateUrl: './candidate-home.component.html',
  styleUrl: './candidate-home.component.scss',
})
export class CandidateHomeComponent {
  constructor(
    public dashboardMgr: DashboardManagerService,
    private router: Router
  ) {}
  gotoFollowUpQuestions(id: string): void {
    this.router.navigate(['/follow-up-questions'], {
      queryParams: {
        application_id: id,
        age: 'above_18',
      },
    });
  }
  ngOnInit() {
    this.dashboardMgr.getCandidateDashboard();
  }
}
