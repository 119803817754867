<div class="page">

    <!-- Side menu -->
    <app-side-menu></app-side-menu>
    <!-- main -->
    <main class="main">
        <nz-breadcrumb>
            <nz-breadcrumb-item>
                <a routerLink="/"><span nz-icon nzType="home"></span></a>
            </nz-breadcrumb-item>
            <nz-breadcrumb-item><a class="breadcrumb-menu-item"
                    (click)="toggleSubmenu()">Settings</a></nz-breadcrumb-item>
            <nz-breadcrumb-item>Job Processing</nz-breadcrumb-item>
        </nz-breadcrumb>

        <div nz-row [nzGutter]="16">
            <div nz-col>
                <div class="heading-flex">
                    <h1>Job Processing</h1>
                </div>
                <nz-spin [nzSpinning]="integrationsMgr.isLoading">
                    <div class="white-box">
                        <div class="content-box">
                            <p>When you opt for automatic processing of Candidates, CLARA will handle all job
                                applications imported into the system
                                automatically. If you decide to disable this feature, you'll have the option to manually
                                choose which jobs to process
                                candidates for.</p>
                        </div>
                        <div class="processing-settings">
                            <div>
                                <p>Plan details:</p>
                                <p>Automatic Processing of Candidates</p>
                            </div>
                            <nz-switch [(ngModel)]="integrationsMgr.autoJobProcessing"
                                (ngModelChange)="changeJobProcessingSettings(integrationsMgr.autoJobProcessing)"></nz-switch>
                        </div>
                        <div class="light-purple-box" *ngIf="!integrationsMgr.autoJobProcessing">
                            You have opted out of automatic processing of Candidates. Go to the <a class="color-purple"
                                routerLink="/jobs">Jobs</a> page to manually choose which jobs to process
                            candidates for.
                        </div>
                    </div>

                </nz-spin>

            </div>

        </div>


    </main>
</div>