import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CandidatesManagerService } from 'src/app/Managers/candidates-manager.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { PermissionService } from 'src/app/Services/permission.service';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss',
})
export class ProfileComponent {
  candidateId = 0;
  user: any;
  userRole: string = '';
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public candidateMgr: CandidatesManagerService,
    private notification: NzNotificationService,
    public permissionService: PermissionService
  ) {}
  goToJobPage(
    id: string,
    status: string,
    atsName: string,
    candidateId: string
  ) {
    if (status === 'open') {
      id &&
        this.router.navigate(['jobs/candidates/', id], {
          queryParams: { atsName, candidateId },
        });
    } else {
      this.notification.create(
        'info',
        'Job Status',
        'The job is currently closed'
      );
    }
  }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user') || '{}');
    this.userRole = this.user.role?.trim();
    this.route.params.subscribe((params) => {
      const id = params['id'];
      this.candidateId = params['id'];
      this.candidateMgr.getCandidateProfile(id);
    });
  }
}
