<div class="page">

    <!-- Side menu -->
    <app-side-menu></app-side-menu>
    <!-- main -->
    <main class="main">
        <nz-breadcrumb>
            <nz-breadcrumb-item>
                <a routerLink="/"><span nz-icon nzType="home"></span></a>
            </nz-breadcrumb-item>
            <nz-breadcrumb-item>Candidates</nz-breadcrumb-item>
        </nz-breadcrumb>
        <div class="heading-flex">
            <h1 class="font-heading">Candidates</h1>

        </div>
        <app-candidates></app-candidates>
    </main>
</div>