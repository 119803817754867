import { Component, Input } from '@angular/core';
import { CommentsManagerService } from 'src/app/Managers/comments-manager.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-comments-application',
  templateUrl: './comments-application.component.html',
  styleUrl: './comments-application.component.scss',
})
export class CommentsApplicationComponent {
  [x: string]: any;
  @Input()
  applicationId!: string;
  newComment: string = '';
  form!: FormGroup;
  constructor(
    public commentMgr: CommentsManagerService,
    private fb: FormBuilder
  ) {}

  onEnterKey(event: any): void {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      this.addComment();
    }
  }
  addComment(): void {
    if (this.form.valid) {
      let data = {};
      data = {
        comment: this.form.get('comment')?.value,
      };
      this.commentMgr.addCommentApplications(data, this.applicationId);
      this.form.reset();
    } else {
      Object.values(this.form.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }
  getFormControl(name: string) {
    return this.form.controls[name];
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      comment: ['', [Validators.maxLength(1000)]],
    });
    this.commentMgr.applicationId = this.applicationId;
    this.commentMgr.getCommentsApplications(this.applicationId);
  }
}
