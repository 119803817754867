import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, interval, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  private webSocket: WebSocket | undefined;
  private messageSubject = new BehaviorSubject<any>(null);
  private progressMessageSubject = new BehaviorSubject<any>(null);
  private jobStatusMessageSubject = new BehaviorSubject<any>(null);
  private connectionStatusSubject = new BehaviorSubject<boolean>(false);

  public messages$: Observable<any> = this.messageSubject.asObservable();
  public progressMessages$: Observable<any> =
    this.progressMessageSubject.asObservable();
  public jobStatusMessages$: Observable<any> =
    this.jobStatusMessageSubject.asObservable();
  public connectionStatus$: Observable<boolean> =
    this.connectionStatusSubject.asObservable();

  private pingSubscription: Subscription | undefined;

  constructor() {}

  public openWebSocket(userId: string): void {
    this.resetSubjects(); // Reset subjects when opening WebSocket

    const url = `${environment.webSocketUrl}${userId}`;
    this.webSocket = new WebSocket(url);

    this.webSocket.onopen = () => {
      console.log('WebSocket connection opened');
      this.connectionStatusSubject.next(true);
      //this.startPing();
    };

    this.webSocket.onerror = (error) => {
      console.error('WebSocket error:', error);
      this.connectionStatusSubject.next(false);
      this.stopPing();
    };

    this.webSocket.onmessage = (messageEvent) => {
      this.handleMessage(messageEvent.data);
    };

    this.webSocket.onclose = () => {
      console.log('WebSocket connection closed');
      this.connectionStatusSubject.next(false);
      this.stopPing();
    };
  }

  public closeWebSocket(): void {
    if (this.webSocket) {
      this.webSocket.close();
    }
    this.connectionStatusSubject.next(false);
    this.stopPing();
  }

  public sendMessage(message: string): void {
    if (this.webSocket && this.webSocket.readyState === WebSocket.OPEN) {
      this.webSocket.send(message);
    } else {
      console.warn('WebSocket is not open. Message not sent:', message);
    }
  }

  private startPing(): void {
    this.pingSubscription = interval(90000).subscribe(() => {
      this.sendMessage('ping');
    });
  }

  private stopPing(): void {
    if (this.pingSubscription) {
      this.pingSubscription.unsubscribe();
      this.pingSubscription = undefined;
    }
  }

  private handleMessage(data: string): void {
    try {
      const message = JSON.parse(data);
      if (message.integration_id && message.partner_id) {
        this.messageSubject.next(message);
      }
      this.progressMessageSubject.next(message);
      this.jobStatusMessageSubject.next(message);
    } catch (error) {
      console.error('Error parsing WebSocket message:', error);
    }
  }

  private resetSubjects(): void {
    this.messageSubject.next(null);
    this.progressMessageSubject.next(null);
    this.jobStatusMessageSubject.next(null);
    this.connectionStatusSubject.next(false);
  }
}
