import { Injectable } from '@angular/core';
import { JobsService } from '../Services/jobs.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { jobs, applicationStatus } from '../models/models';

@Injectable({
  providedIn: 'root',
})
export class JobsManagerService {
  public isVisibleImportJob: boolean = false;
  public totalActive: number = 0;
  public totalActiveHome: number = 0;
  public latestJobCount: number = 0;
  public totalClosed: number = 0;
  public activePage = 1;
  public closedPage = 1;
  public homeJobPage = 1;
  public activeStatus: string = '';
  public statusActive: string = 'open';
  public statusClosed: string = 'closed';
  public jobStatus: string = 'open';
  nextPageUrl: string | null = null;
  public partnerIDActive: string = '';
  public partnerIDClosed: string = '';
  public searchActive: string = '';
  public searchClosed: string = '';
  public isLoading: boolean = false;
  public isLoadingModalJobs: boolean = false;
  public isLoadingClosed: boolean = false;
  public isLoadingImportJobs: boolean = false;
  public isLoadingApplicationStatus: boolean = false;
  public isLoadingImportingJobs: boolean = false;
  public selectJobModal: boolean = false;
  public jobsActiveData: jobs[] = [];
  public jobsActiveDataHome: jobs[] = [];
  public jobsClosedData: jobs[] = [];
  public importJobsData: jobs[] = [];
  public applicationStatus: applicationStatus[] = [];
  private jobIdsSetIntegration: Set<number> = new Set<number>();
  private jobIdsSetDirect: Set<number> = new Set<number>();

  public isEnabledFeature: boolean = false;
  constructor(
    public jobsSevice: JobsService,
    private notification: NzNotificationService
  ) {}
  public getActiveJobs(
    page: number,
    partnerIDActive: string,
    searchActive: string,
    statusActive: string
  ): Promise<void> {
    return new Promise<void>((resolve) => {
      this.isLoading = true;
      this.jobsSevice
        .getActiveJobs(page, partnerIDActive, searchActive, statusActive)
        .subscribe((c: any) => {
          this.jobsActiveData = c.results;
          this.totalActive = Object(c)['count'];
          this.isLoading = false;
          resolve();
        });
    });
  }
  // public getActiveJobsHome(page: number): Promise<void> {
  //   return new Promise<void>((resolve) => {
  //     this.isLoadingModalJobs = true;
  //     this.jobsSevice.getActiveJobsHome(page).subscribe((c: any) => {
  //       const newData = c.results;

  //       // Merge the data and filter out duplicates based on a unique identifier, e.g., `id`
  //       const mergedData = [...this.jobsActiveDataHome, ...newData];
  //       this.jobsActiveDataHome = mergedData.filter(
  //         (job, index, self) =>
  //           index === self.findIndex((t) => t._id === job._id)
  //       );

  //       this.totalActiveHome = Object(c)['count'];
  //       this.latestJobCount = Object(c)['latest_jobs_count'];
  //       this.nextPageUrl = Object(c)['next'];
  //       this.isLoadingModalJobs = false;
  //       resolve();
  //     });
  //   });
  // }
  public getActiveJobsHome(page: number): Promise<void> {
    return new Promise<void>((resolve) => {
      this.isLoadingModalJobs = true;
      this.jobsSevice.getActiveJobsHome(page).subscribe((c: any) => {
        const newData = c.results;

        // Update or insert new jobs without clearing the array
        newData.forEach((newJob: any) => {
          const index = this.jobsActiveDataHome.findIndex(
            (job) => job._id === newJob._id
          );
          if (index !== -1) {
            // If the job already exists, update it
            this.jobsActiveDataHome[index] = newJob;
          } else {
            // If the job doesn't exist, add it to the array
            this.jobsActiveDataHome.push(newJob);
          }
        });

        this.totalActiveHome = Object(c)['count'];
        this.latestJobCount = Object(c)['latest_jobs_count'];
        this.nextPageUrl = Object(c)['next'];
        this.isLoadingModalJobs = false;
        resolve();
      });
    });
  }

  public getClosedJobs(
    page: number,
    partnerIDClosed: string,
    searchClosed: string,
    statusClosed: string
  ) {
    this.isLoadingClosed = true;
    this.jobsSevice
      .getActiveJobs(page, partnerIDClosed, searchClosed, statusClosed)
      .subscribe((c: any) => {
        this.jobsClosedData = c.results;
        this.totalClosed = Object(c)['count'];
        this.isLoadingClosed = false;
      });
  }
  public getImportJobs(status: string) {
    this.isLoadingImportJobs = true;
    this.jobsSevice.getImportJobs(status).subscribe((c: any) => {
      this.importJobsData = c.response;
      this.isLoadingImportJobs = false;
    });
  }
  public getImportJobsClosed(status: string) {
    this.isLoadingImportJobs = true;
    this.jobsSevice.getImportJobs(status).subscribe((c: any) => {
      const newJobs = c.response.filter(
        (job: any) => !this.jobIdsSetDirect.has(job.remote_id)
      );
      this.importJobsData = [...this.importJobsData, ...newJobs];
      newJobs.forEach((job: any) => this.jobIdsSetDirect.add(job.remote_id));
      this.isLoadingImportJobs = false;
    });
  }
  public getImportJobsATS(id: number, status: string) {
    this.isLoadingImportJobs = true;
    this.jobsSevice.getImportJobsATS(id, status).subscribe((c: any) => {
      this.importJobsData = c.response;
      this.isLoadingImportJobs = false;
    });
  }
  public getImportJobsATSClosed(id: number, status: string) {
    this.isLoadingImportJobs = true;
    this.jobsSevice.getImportJobsATS(id, status).subscribe((c: any) => {
      const newJobs = c.response.filter(
        (job: any) => !this.jobIdsSetIntegration.has(job.remote_id)
      );
      this.importJobsData = [...this.importJobsData, ...newJobs];
      newJobs.forEach((job: any) =>
        this.jobIdsSetIntegration.add(job.remote_id)
      );
      this.isLoadingImportJobs = false;
    });
  }

  public importJobs(jobs: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.isLoadingImportingJobs = true;
      this.jobsSevice.importJobs(jobs).subscribe({
        next: async (res: any) => {
          this.isVisibleImportJob = false;
          this.notification.create(
            'success',
            'New Job Added',
            'Jobs has been added and we are processing it. You will be notified when it is ready to view.'
          );
          //this.selectJobModal = false;
          this.isLoadingImportingJobs = false;
          resolve();
        },
        error: (error: any) => {
          this.notification.create('error', 'Error', error.error.error);
          this.isLoadingImportingJobs = false;
          reject(error);
          //this.selectJobModal = false;
        },
      });
    });
  }
  public getApplicatonStatus(id: number) {
    this.isLoadingApplicationStatus = true;
    this.jobsSevice.getApplicatonStatus(id).subscribe((c: any) => {
      this.applicationStatus = c.response;
      this.isLoadingApplicationStatus = false;
    });
  }
  public getFollowUpFeature() {
    this.isLoadingApplicationStatus = true;
    this.jobsSevice.getFollowUpFeature().subscribe((c: any) => {
      console.log(c.response);
      this.isEnabledFeature = c.response.is_enabled;
      this.isLoadingApplicationStatus = false;
    });
  }
  public updatedFollowUpFeature(data: any) {
    this.isLoadingApplicationStatus = true;
    this.jobsSevice.updatedFollowUpFeature(data).subscribe((c: any) => {
      console.log(c.response);
      this.isEnabledFeature = c.response.is_enabled;
      this.isLoadingApplicationStatus = false;
    });
  }
  public submitApplicatonStatus(id: number, formData: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.jobsSevice.submitApplicatonStatus(id, formData).subscribe({
        next: async (res: any) => {
          this.notification.create('success', 'Application Status Updated', '');
          this.getApplicatonStatus(id);
          resolve();
        },
        error: (error: any) => {
          this.notification.create('error', 'Error', error.error.response);
          reject(error);
        },
      });
    });
  }
  public candidatesProcessing(id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.jobsSevice.candidatesProcessing(id).subscribe({
        next: async (res: any) => {
          this.notification.create(
            'success',
            'Candidate Processing Started',
            ''
          );
          resolve();
        },
        error: (error: any) => {
          this.notification.create('error', 'Error', error.error.response);
          reject(error);
        },
      });
    });
  }
}
