import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateAgo',
})
export class DateAgoPipe implements PipeTransform {
  transform(value: any, ...args: unknown[]): unknown {
    if (!value) {
      return 'a long time ago';
    }

    // Parse the date string including timezone offset
    const date = new Date(value);

    if (isNaN(date.getTime())) {
      return 'Invalid date';
    }

    let time = (Date.now() - date.getTime()) / 1000; // Time difference in seconds

    if (time < 10) {
      return 'just now';
    } else if (time < 60) {
      return 'a moment ago';
    }

    const divider = [60, 60, 24, 30, 12];
    const units = [' second', ' minute', ' hour', ' day', ' month', ' year'];

    let i;
    for (i = 0; Math.floor(time / divider[i]) > 0 && i < divider.length; i++) {
      time /= divider[i];
    }
    const plural = Math.floor(time) > 1 ? 's' : '';

    return Math.floor(time) + units[i] + plural + ' ago';
  }
}
