import { Component, Input } from '@angular/core';
import { CommentsManagerService } from 'src/app/Managers/comments-manager.service';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrl: './comments.component.scss',
})
export class CommentsComponent {
  @Input()
  jobObjectId!: string;
  newComment: string = '';
  constructor(public commentMgr: CommentsManagerService) {}
  addComment() {
    if (this.newComment.trim() !== '') {
      let data = {
        comment: this.newComment,
      };
      this.commentMgr.addComment(data, this.jobObjectId);
      this.newComment = '';
    }
  }

  ngOnInit(): void {
    this.commentMgr.jobObjectId = this.jobObjectId;
    this.commentMgr.getComments(this.jobObjectId);
  }
}
