<div class="job-title">
    <div nz-row [nzGutter]="16">
        <div nz-col [nzSpan]="8">
            <label>Enter Job Title</label>
            <input nz-input nzSize="large" placeholder="Job Title" (input)="onJobTitleChange($event)" />
        </div>
        <div nz-col [nzSpan]="8">
            <label>Enter Job Location</label>
            <input nz-input nzSize="large" placeholder="Enter Job Location" (input)="onJobLocationChange($event)" />
        </div>
    </div>

</div>
<div class="upload-container" appDnd (fileDropped)="onFileDropped($event)" *ngIf="!selectedFile">
    <input type="file" #fileDropRef id="fileDropRef" accept="application/pdf" (change)="onFileSelected($event)" />
    <div class="button-icon">
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.5 22.167h21V24.5h-21v-2.333zM15.167 6.799v13.034h-2.334V6.8L5.75 13.883l-1.65-1.65 9.9-9.9 9.9 9.9-1.65 1.65-7.083-7.081v-.003z"
                fill="#1C1A20" fill-opacity=".7" />
        </svg>
    </div>
    <h3>Click or drag a file to upload</h3>
    <h4>Formats supported: PDF</h4>
</div>
<div class="preview-file" *ngIf="selectedFile">
    <div class="d-flex align-middle">
        <span class="checkmark-green" nz-icon nzType="check"></span>
        <span class="file-name">{{ selectedFile.name }}</span>
    </div>
    <span class="cancel-file" nz-icon nzType="close" nzTheme="outline" (click)="removeFile()"></span>
</div>