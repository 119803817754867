import { Component } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { UsersManagerService } from 'src/app/Managers/users-manager.service';
import { AuthenticationService } from 'src/app/Services/authentication.service';
import { PermissionService } from 'src/app/Services/permission.service';
@Component({
  selector: 'app-my-company',
  templateUrl: './my-account.component.html',
  styleUrl: './my-account.component.scss',
})
export class MyAccountComponent {
  user: any;
  isVisibleRemoveAccountModal: boolean = false;

  currentPasswordVisible: boolean = false;
  newPasswordVisible: boolean = false;
  formEditProfile!: FormGroup;
  formEditPassword!: FormGroup;
  constructor(
    private fb: UntypedFormBuilder,
    public userMgr: UsersManagerService,
    private auth: AuthenticationService,
    private permissionService: PermissionService
  ) {}

  editProfileModal(profileData: any): void {
    this.formEditProfile.patchValue({
      first_name: profileData.user_profile.first_name || '',
      last_name: profileData.user_profile.last_name || '',
      phone_number: profileData.user_profile.phone_number || '',
      email: profileData.email || '',
    });
    this.userMgr.isProfileModal = true;
  }
  handleProfileCancel(event: Event): void {
    this.userMgr.isProfileModal = false;
    event.preventDefault();
    this.formEditProfile.reset();
  }
  changePasswordModal(): void {
    this.userMgr.isChangePasswordModal = true;
  }
  handleChangePasswordCancel(event: Event): void {
    this.userMgr.isChangePasswordModal = false;
    event.preventDefault();
    this.formEditPassword.reset();
  }

  removeAccountModal(): void {
    this.isVisibleRemoveAccountModal = true;
  }
  removeAccountCancel(): void {
    this.isVisibleRemoveAccountModal = false;
  }

  editProfile(): void {
    if (this.formEditProfile.valid) {
      let data = {
        ...this.formEditProfile.value,
        role: this.user.role,
        is_active: this.user.is_active,
      };

      this.userMgr.editProfile(data, this.user.id);
      this.formEditProfile.reset();
    } else {
      Object.values(this.formEditProfile.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  changePassword(): void {
    if (this.formEditPassword.valid) {
      let data = {
        id: this.user.id,
        ...this.formEditPassword.value,
      };

      this.userMgr.changePassword(data);
      this.formEditPassword.reset();
    } else {
      Object.values(this.formEditPassword.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }
  removeMyAccount(): void {
    this.user = JSON.parse(localStorage.getItem('user') || '{}');
    this.userMgr.removeMyAccount(this.user.id);
  }
  logout(): void {
    this.permissionService.clearPermissionsOnLogout();
    this.auth.logout();
  }
  toggleSubmenu() {
    const bodyTag = document.body;
    bodyTag.classList.toggle('is-submenu');
    this.auth.isSubmenu = !this.auth.isSubmenu;
  }
  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('user') || '{}');
    this.userMgr.myProfile(this.user.id);
    this.formEditProfile = this.fb.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      phone_number: ['', [Validators.pattern(/^\+(?:[0-9] ?){6,14}[0-9]$/)]],
      email: ['', Validators.required],
    });
    this.formEditPassword = this.fb.group({
      current_password: ['', Validators.required],
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.pattern(/^(?=.*[!@#$%^&*])/),
        ],
      ],
    });
  }
}
