<div class="add-comment">
    <textarea nz-input placeholder="Add New Comment" [(ngModel)]="newComment" (keyup.enter)="addComment()"></textarea>
</div>
<div class="comments">
    <nz-spin [nzSpinning]="this.commentMgr.isLoading">
        <ul>
            <li *ngFor="let item of commentMgr.comments">
                <div class="comment-header">
                    <div class="status new"></div>
                    <div class="user">{{item.posted_by.first_name}} {{item.posted_by.last_name}}</div>
                    <div class="date">{{item.created_date | dateAgo}}</div>
                </div>
                <div class="comment-body">
                    {{item.comment}}
                </div>
            </li>
        </ul>
    </nz-spin>
</div>