import { Component } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { CandidateSortingManagerService } from 'src/app/Managers/candidate-sorting-manager.service';
import { AuthenticationService } from 'src/app/Services/authentication.service';

@Component({
  selector: 'app-candidate-sorting',
  templateUrl: './candidate-sorting.component.html',
  styleUrl: './candidate-sorting.component.scss',
})
export class CandidateSortingComponent {
  switchValue = true;
  score: number = 0;

  constructor(
    private notification: NzNotificationService,
    public sortingMgr: CandidateSortingManagerService,
    public auth: AuthenticationService
  ) {}

  automateSorging(): void {
    this.switchValue = !this.switchValue;
    if (!this.switchValue) {
      this.notification.create(
        'success',
        'Candidate Sorting Inactivated',
        'Automatic candidate sorting has been inactivated.'
      );
    } else {
      this.notification.create(
        'success',
        'Candidate Sorting Activated',
        'Automatic candidate sorting has been activated.'
      );
    }
  }
  onChangeScore(value: any): void {
    let minScorePreQualified = Math.min(value + 1, 100);
    let data = {
      automatic: true,
      pre_qualified: {
        min_score: minScorePreQualified,
        max_score: 100,
        enabled: true,
      },
      not_qualified: {
        min_score: 0,
        max_score: value + 0.9,
        enabled: true,
      },
    };
    let copiedData = JSON.parse(JSON.stringify(data));
    this.sortingMgr.updateSortingData = copiedData;
  }

  submitSorting(): void {
    this.sortingMgr.updateCandidatesSorting(this.sortingMgr.updateSortingData);
  }

  editProfileModal(): void {
    this.sortingMgr.isVisibleProfileModal = true;
  }
  handleProfileCancel(): void {
    this.sortingMgr.isVisibleProfileModal = false;
  }
  toggleSubmenu() {
    const bodyTag = document.body;
    bodyTag.classList.toggle('is-submenu');
    this.auth.isSubmenu = !this.auth.isSubmenu;
  }
  ngOnInit(): void {
    this.sortingMgr.getCandidatesSorting();
  }
}
