<div class="page">
    <main class="main" *ngIf="sjmMgr.jobInfo">

        <div class="d-flex align-middle color-black">
            <h1>{{sjmMgr.jobInfo.title}}</h1>
            <div class="ml-10" *ngIf="jobObjId">JOB ID
                {{jobObjId}}</div>
        </div>
        <div class="white-panel">
            <div class="border-box">
                <p class="color-theme font-weight-medium text-center">Start your application here</p>
                <div class="border-box-alert ">
                    <div class="d-flex">
                        <div class="box-icon mr-16">
                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="m15.01 3.5 11.114 19.25a1.167 1.167 0 0 1-1.01 1.75H2.886a1.167 1.167 0 0 1-1.01-1.75L12.99 3.5a1.167 1.167 0 0 1 2.02 0zM4.907 22.167h18.186L14 6.417l-9.093 15.75zm7.926-3.5h2.334V21h-2.334v-2.333zm0-8.167h2.334v5.833h-2.334V10.5z"
                                    fill="#5822D3" />
                            </svg>
                        </div>
                        <div class="d-flex">
                            <b>You are required to provide the following:</b>
                            <ul>
                                <li>Resume</li>
                                <ng-container *ngIf="sjmMgr.jobInfo?.job_materials_and_links?.materials">
                                    <ng-container *ngFor="let item of sjmMgr.jobInfo.job_materials_and_links.materials">
                                        <li *ngIf="item.is_display">
                                            {{ item.material_name | titlecase }}
                                        </li>
                                    </ng-container>
                                </ng-container>

                            </ul>

                        </div>

                    </div>
                </div>
                <div class="text-center">
                    <a [routerLink]="['/my-applications', jobObjId]"><button nz-button nzType="primary" nzShape="round"
                            nzSize="large" class="font-size-medium mr-16">Start my application
                            process</button></a>
                </div>
            </div>
            <div class="job-detail color-black-container">
                <div class="left-panel">
                    <p><b>About the job</b></p>
                    <p [innerHTML]="sjmMgr.jobInfo.description"></p>

                </div>
                <div class="right-panel">
                    <h3>Details</h3>
                    <ul class="info-list">
                        <li>
                            <span>Position</span>
                            <span>{{sjmMgr.jobInfo.position}}</span>
                        </li>
                        <li>
                            <span>Location</span>
                            <span>{{sjmMgr.jobInfo.location}}</span>
                        </li>
                        <li>
                            <span>Role Duration</span>
                            <span>{{sjmMgr.jobInfo.role_duration}}</span>
                        </li>

                    </ul>
                    <h3>Job Posting</h3>
                    <ul class="info-list">
                        <li>
                            <span>Job Name</span>
                            <span>{{sjmMgr.jobInfo.title}}</span>
                        </li>
                        <li>
                            <span>Job ID</span>
                            <span>{{jobObjId}}</span>
                        </li>
                        <li>
                            <span>Date Posted</span>
                            <span>{{sjmMgr.jobInfo.posting_date}}</span>
                        </li>

                    </ul>
                    <h3>Education</h3>
                    <p *ngIf="sjmMgr.jobInfo.extracted_details.education">
                        {{sjmMgr.jobInfo.extracted_details.education.degree}}
                    </p>

                </div>
            </div>
        </div>
    </main>
</div>