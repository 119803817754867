import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UsersManagerService } from 'src/app/Managers/users-manager.service';
import {
  FormGroup,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrl: './register.component.scss',
})
export class RegisterComponent {
  isLoading: boolean = false;
  isVisibleModal = false;
  passwordVisible: boolean = false;
  isError: boolean = false;
  currentStep = 0;
  validateForm!: UntypedFormGroup;

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    public usersServiceManger: UsersManagerService
  ) {}
  submitForm(): void {
    if (this.validateForm.valid) {
      this.usersServiceManger.registerUser(this.validateForm.value);
    } else {
      Object.values(this.validateForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.pattern(/^(?=.*[!@#$%^&*])/),
        ],
      ],
    });
  }
}
