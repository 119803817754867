// permission.service.ts
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  private userPermissions: Record<string, string[]> = {};

  constructor() {
    // Initialize permissions by fetching them from localStorage
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    if (user) {
      this.extractPermissions(user);
    }
  }

  private extractPermissions(user: any): void {
    if (user && user.permission_set) {
      user.permission_set.forEach(
        (permission: {
          content_type: string | number;
          permissions: string[];
        }) => {
          this.userPermissions[permission.content_type] =
            permission.permissions;
        }
      );
    }
  }

  hasPermission(contentType: string, desiredPermission: string): boolean {
    const allowedPermissions = this.userPermissions[contentType] || [];
    return allowedPermissions.includes(desiredPermission);
  }
  updatePermissions(user: any): void {
    this.userPermissions = {};
    this.extractPermissions(user);
  }
  clearPermissionsOnLogout(): void {
    this.userPermissions = {};
  }
}
