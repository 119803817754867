import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TabCommunicationService {
  private storageEventSubject = new Subject<void>();

  constructor() {
    // Listen for storage events
    window.addEventListener('storage', (event) => {
      if (event.key === 'userChange') {
        // When a user change occurs, notify subscribers
        this.storageEventSubject.next();
      }
    });
  }

  setUserChange(): void {
    // Set a value in localStorage to notify other tabs
    localStorage.setItem('userChange', Date.now().toString());
  }

  onUserChange(): Observable<void> {
    // Return an observable to listen for user change events
    return this.storageEventSubject.asObservable();
  }
}
