import { Component, Input } from '@angular/core';
import { CommentsManagerService } from 'src/app/Managers/comments-manager.service';

@Component({
  selector: 'app-comments-candidates',
  templateUrl: './comments-candidates.component.html',
  styleUrl: './comments-candidates.component.scss',
})
export class CommentsCandidatesComponent {
  @Input()
  jobObjectId!: string;
  newComment: string = '';
  constructor(public commentMgr: CommentsManagerService) {}
  addComment() {
    if (this.newComment.trim() !== '') {
      let data = {
        comment: this.newComment,
      };
      this.commentMgr.addCommentCandidates(data, this.jobObjectId);
      this.newComment = '';
    }
  }

  ngOnInit(): void {
    this.commentMgr.jobObjectId = this.jobObjectId;
    this.commentMgr.getCommentsCandidates(this.jobObjectId);
  }
}
