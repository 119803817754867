import { Component } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { TabCommunicationService } from './Services/tab-communication.service';
import { ImpersonationManagerService } from 'src/app/Managers/impersonation-manager.service';
import { PermissionService } from 'src/app/Services/permission.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  showMessage = false;
  user: any;
  constructor(
    private modal: NzModalService,
    private tabCommunicationService: TabCommunicationService,
    public imprMgr: ImpersonationManagerService,
    public permissionService: PermissionService
  ) {}

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('user') || '{}');
    const userRole = this.user.role?.trim();
    // Listen for user change events
    this.tabCommunicationService.onUserChange().subscribe(() => {
      this.modal.info({
        nzTitle: 'Your session has expired or you have switched accounts!',
        nzContent: '<p>Please reload the page</p>',
        nzOnOk: () => {
          window.location.reload(); // Reload the page
        },
      });
    });
    if (
      userRole === 'Admin' ||
      userRole === 'Partner Admin' ||
      userRole === 'Account Owner'
    ) {
      this.imprMgr.currentImpersonation();
    }
  }
}
