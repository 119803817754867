import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(private http: HttpClient) {}
  getUsers(page: number, isActive: string, role: string, search: string) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.get(
      environment.apiURL +
        `account/user/users/?page=${page}&is_active=${isActive}&role=${role}&search=${search}`,
      {
        headers: header,
      }
    );
  }
  addUser(formData: any) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.post(environment.apiURL + 'account/user/', formData, {
      headers: header,
    });
  }
  registerUser(formData: any) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.post(environment.apiURL + 'account/user/', formData, {
      headers: header,
    });
  }
  verifyingUser(token: string) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.get(
      environment.apiURL + `account/user/email/?token=${token}`,
      {
        headers: header,
      }
    );
  }

  UnsubscribeFromNewsletter(token: any) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.post(
      environment.apiURL + 'account/user/unsubscribe-email/',
      token,
      {
        headers: header,
      }
    );
  }
  forgotUser(formData: any) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.post(
      environment.apiURL + 'account/user/forgot-password-email/',
      formData,
      {
        headers: header,
      }
    );
  }
  myProfile(id: number) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.get(environment.apiURL + `account/user/users/${id}/`, {
      headers: header,
    });
  }
  getMyCandidateProfile() {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.get(environment.apiURL + `deincore/candidate/profile/`, {
      headers: header,
    });
  }
  editProfile(formData: any, id: number) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.put(
      environment.apiURL + `account/user/users/${id}/`,
      formData,
      {
        headers: header,
      }
    );
  }
  changePassword(formData: any) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.put(
      environment.apiURL + `account/user/password/`,
      formData,
      {
        headers: header,
      }
    );
  }
  changePasswordParter(formData: any) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.post(
      environment.apiURL + `account/user/password/`,
      formData,
      {
        headers: header,
      }
    );
  }
  registerCandidate(formData: any) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.post(
      environment.apiURL + `account/candidate/password/`,
      formData,
      {
        headers: header,
      }
    );
  }

  removeMyAccount(id: number) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.delete(environment.apiURL + `account/user/users/${id}/`, {
      headers: header,
    });
  }
  removeUser(id: number) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.delete(environment.apiURL + `account/user/users/${id}/`, {
      headers: header,
    });
  }
  sendInvite(formData: any) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.post(
      environment.apiURL + `account/user/invitation-email/`,
      formData,
      {
        headers: header,
      }
    );
  }
  resetPassword(formData: any) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.post(
      environment.apiURL + `account/user/password/`,
      formData,
      {
        headers: header,
      }
    );
  }
  getPartners(page: number) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.get(
      environment.apiURL + `account/partners/?page=${page}`,
      {
        headers: header,
      }
    );
  }
  addNewPartner(formData: any) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.post(environment.apiURL + `account/partner/`, formData, {
      headers: header,
    });
  }
  deletePartner(id: number) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.delete(environment.apiURL + `account/partners/${id}`, {
      headers: header,
    });
  }
  currentSubscription() {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.get(
      environment.apiURL + `deincore/stripe/current-subscription`,
      {
        headers: header,
      }
    );
  }
  checkStripeCustomer() {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.get(environment.apiURL + `settings/stripe-customer`, {
      headers: header,
    });
  }
}
