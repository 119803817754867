<div class="page">
    <app-loader *ngIf="applicationMgr.gettingAllApplication"></app-loader>
    <!-- Side menu -->
    <app-side-menu></app-side-menu>
    <!-- main -->
    <main class="main" *ngIf="applicationMgr.jobDetailData" #mainContainer>
        <div class="page-header-info">
            <div nz-row [nzGutter]="16">
                <div nz-col [nzSpan]="20">
                    <nz-breadcrumb>
                        <nz-breadcrumb-item>
                            <a routerLink="/"><span nz-icon nzType="home"></span></a>
                        </nz-breadcrumb-item>
                        <nz-breadcrumb-item><a routerLink="/jobs">Jobs</a></nz-breadcrumb-item>
                        <nz-breadcrumb-item>{{applicationMgr.jobDetailData.title}}</nz-breadcrumb-item>
                    </nz-breadcrumb>
                </div>
            </div>
            <div class="sub-tagline">
                <div>
                    <div class="d-flex align-items-baseline">
                        <h1>{{applicationMgr.jobDetailData.title}} </h1>
                        <div class="ml-10 font-light">Job ID {{jobObjectId}}</div>
                    </div>
                </div>


                <div class="d-flex align-middle">
                    <ng-container *appIsGranted="{ content_type: 'partnersettings', desiredPermission: 'view' }">
                        <button *ngIf="ats === 'clara'" nz-button nzType="primary" nzShape="round"
                            (click)="gotoCandidatesUpload(jobObjectId)" class="font-size-medium mr-8"><span nz-icon
                                nzType="upload" nzTheme="outline"></span> Upload Candidates</button>
                    </ng-container>
                    <span *ngIf="ats === 'clara'" nz-typography nzCopyable [nzCopyText]="job_apply_link"
                        [nzCopyTooltips]="['Copy Apply Link', 'Apply Link Copied']" [nzCopyIcons]="[copy, copy]"></span>
                    <ng-template #copy>
                        <button class="btn-icon mr-8"><span nz-icon nzType="link" nzTheme="outline"></span></button>
                    </ng-template>
                    <button class="btn-icon" *ngIf="user.role !== 'Hiring Manager' &&
                        (applicationMgr.not_qualified.length > 0 || 
                         applicationMgr.pre_qualified.length > 0 || 
                         applicationMgr.qualified.length > 0)" nz-dropdown nzTrigger="click"
                        [nzDropdownMenu]="menu"><span nz-icon nzType="more" nzTheme="outline"></span></button>
                    <nz-dropdown-menu #menu="nzDropdownMenu">
                        <ul nz-menu>
                            <li (click)="jobSortingCriteriaModal()" nz-menu-item>Edit the Sorting Range for This Job
                            </li>
                            <li *ngIf="!adjustAIWeights" (click)="jobAISettingModal()" nz-menu-item>Edit AI Fit Scoring
                                for
                                This Job</li>
                            <li (click)="assignJobModal()"
                                *appIsGranted="{ content_type: 'partnersettings', desiredPermission: 'view' }"
                                nz-menu-item>
                                Assign Job</li>
                            <li nz-menu-item *ngIf="ats === 'clara'">
                                <span nz-typography nzCopyable [nzCopyText]="job_apply_link"
                                    [nzCopyTooltips]="['Copy Apply Link', 'Apply Link Copied']"
                                    [nzCopyIcons]="[copyText, copyText]"></span>
                                <ng-template #copyText>
                                    <div class="copy-link">Copy Apply Link</div>
                                </ng-template>
                            </li>
                        </ul>
                    </nz-dropdown-menu>
                </div>
            </div>
        </div>
        <nz-tabset>
            <nz-tab nzTitle="Applications" nzForceRender>
                <nz-spin [nzSpinning]="aiManager.isUpdatingAiSettings">
                    <div class="blue-box" *ngIf="adjustAIWeights">
                        <span>AI Settings Preview</span>
                        <div class="buttons">
                            <button class="btn-custom btn-text" (click)="discardChanges(1)">Discard Changes</button>
                            <button class="btn-custom btn-light-blue" (click)="adjustChanges()">Adjust AI
                                Settings</button>
                            <button class="btn-custom btn-white" (click)="saveChanges(3)">Save AI Setting
                                Changes</button>

                        </div>
                    </div>
                    <div class="processing-box"
                        *ngIf="ats === 'clara' && received_application_count > processed_application_count && showProgressBar">
                        <span>Processing</span>
                        <div class="progress-bar">
                            <div class="progress-fill" [ngStyle]="{'width': processingPercentage  + '%'}"></div>
                        </div>
                        <div class="progress-value-count">{{processingPercentage }}%</div>
                    </div>
                </nz-spin>

                <div class="filter-form" *ngIf="!adjustAIWeights && user.role !== 'Hiring Manager'">
                    <div nz-row [nzGutter]="16" nzAlign="middle" nzJustify="space-between">
                        <div nz-col>
                            <div nz-row [nzGutter]="16" nzAlign="middle" *ngIf="!adjustAIWeights">
                                <div nz-col>
                                    <button (click)="jobAISettingModal()" class="btn-sm-white" [disabled]="applicationMgr.not_qualified.length === 0 && 
                         applicationMgr.pre_qualified.length === 0 && 
                         applicationMgr.qualified.length === 0">AI</button>
                                </div>
                                <div nz-col>
                                    <div class="custom-dropdown">
                                        <button class="btn-sm-white" (click)="toggleAdvacedFilterDropdown()"
                                            [ngClass]="{'active-advanced': isOpenAdvaceFilter}">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z"
                                                    fill-opacity=".36" />
                                            </svg>
                                        </button>
                                        <div class="custom-dropdown-menu" *ngIf="isOpenAdvaceFilter">
                                            <ul class="custom-menu-list">
                                                <li class="custom-menu-item">
                                                    <div class="custom-menu-title" (click)="toggleSubMenu('questions')"
                                                        [ngClass]="{'active':isOpenQuestionsSubMenu}">
                                                        <div class="d-flex align-middle">
                                                            <svg class="ico-menu" width="24" height="24"
                                                                viewBox="0 0 24 24" fill="none"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M6 2a7 7 0 0 1 6.197 3.741A6.49 6.49 0 0 1 17.5 3H21v2.5a6.5 6.5 0 0 1-6.5 6.5H13v1h5v7a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2v-7h5v-2H9a7 7 0 0 1-7-7V2h4zm10 13H8v5h8v-5zm3-10h-1.5A4.5 4.5 0 0 0 13 9.5v.5h1.5A4.5 4.5 0 0 0 19 5.5V5zM6 4H4a5 5 0 0 0 5 5h2a5 5 0 0 0-5-5z"
                                                                    fill="#1C1A20" fill-opacity=".7" />
                                                            </svg>

                                                            <div>Follow up Questions</div>
                                                        </div>
                                                        <span nz-icon nzType="right" nzTheme="outline"></span>
                                                    </div>
                                                    <ul class="custom-sub-menu" *ngIf="isOpenQuestionsSubMenu">
                                                        <li>
                                                            <div class="custom-sub-menu-header">
                                                                <div>Follow up Questions</div>
                                                                <span nz-icon nzType="close" nzTheme="outline"
                                                                    (click)="closeSubMenu('questions')"></span>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div class="custom-sub-menu-item">
                                                                <label nz-checkbox
                                                                    [(ngModel)]="isAnsweredFollowupQuestions"
                                                                    (ngModelChange)="onChangeFollowupQuestions($event)">Answered
                                                                    Follow up Questions</label>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li class="custom-menu-item">
                                                    <div class="custom-menu-title" (click)="toggleSubMenu('location')"
                                                        [ngClass]="{'active':isOpenLocationSubMenu}">
                                                        <div class="d-flex align-middle">
                                                            <svg class="ico-menu" width="24" height="24"
                                                                viewBox="0 0 24 24" fill="none"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="m12 20.9 4.95-4.95a7 7 0 1 0-9.9 0L12 20.9zm0 2.828-6.364-6.364a9 9 0 1 1 12.728 0L12 23.728zM12 13a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 2a4 4 0 1 1 0-8 4 4 0 0 1 0 8z"
                                                                    fill="#1C1A20" fill-opacity=".7" />
                                                            </svg>
                                                            <div>Location</div>
                                                        </div>
                                                        <span nz-icon nzType="right" nzTheme="outline"></span>
                                                    </div>
                                                    <ul class="custom-sub-menu" *ngIf="isOpenLocationSubMenu">
                                                        <li>
                                                            <div class="custom-sub-menu-header">
                                                                <div>Location</div>
                                                                <span nz-icon nzType="close" nzTheme="outline"
                                                                    (click)="closeSubMenu('location')"></span>
                                                            </div>
                                                        </li>

                                                        <li class="custom-sub-menu-list">
                                                            <div class="custom-sub-menu-item"
                                                                *ngFor="let location of applicationMgr.candidateLocationsData">
                                                                <label nz-checkbox
                                                                    [(ngModel)]="locationChecked[location]"
                                                                    (ngModelChange)="onChangeLocation($event, location)">
                                                                    {{
                                                                    location }}
                                                                </label>
                                                            </div>
                                                        </li>
                                                        <li class="text-center"
                                                            *ngIf="applicationMgr.candidateLocationsData.length === 0">
                                                            <span>No location found!</span>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li class="custom-menu-item">
                                                    <div class="custom-menu-title"
                                                        (click)="toggleSubMenu('requirements')"
                                                        [ngClass]="{'active':isOpenRequirmentsSubMenu}">
                                                        <div class="d-flex align-middle">
                                                            <svg class="ico-menu" width="24" height="24"
                                                                viewBox="0 0 24 24" fill="none"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M8 4h13v2H8V4zM5 3v3h1v1H3V6h1V4H3V3h2zM3 14v-2.5h2V11H3v-1h3v2.5H4v.5h2v1H3zm2 5.5H3v-1h2V18H3v-1h3v4H3v-1h2v-.5zM8 11h13v2H8v-2zm0 7h13v2H8v-2z"
                                                                    fill="#1C1A20" fill-opacity=".7" />
                                                            </svg>

                                                            <div>Requirements</div>
                                                        </div>
                                                        <span nz-icon nzType="right" nzTheme="outline"></span>
                                                    </div>
                                                    <ul class="custom-sub-menu" *ngIf="isOpenRequirmentsSubMenu">
                                                        <li>
                                                            <div class="custom-sub-menu-header">
                                                                <div>Requirements</div>
                                                                <span nz-icon nzType="close" nzTheme="outline"
                                                                    (click)="closeSubMenu('requirements')"></span>
                                                            </div>
                                                        </li>

                                                        <li class="custom-sub-menu-list">
                                                            <div class="custom-sub-menu-item"
                                                                *ngFor="let r of applicationMgr.requirementsData">
                                                                <label nz-checkbox
                                                                    [(ngModel)]="requirmentsChecked[r.description]"
                                                                    (ngModelChange)="onChangeRequirements($event, r)">
                                                                    {{ r.description }}
                                                                </label>
                                                            </div>
                                                        </li>
                                                        <li class="text-center"
                                                            *ngIf="applicationMgr.requirementsData.length === 0">
                                                            <span>No Requirements found!</span>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                                <div nz-col>
                                    <div class="btn-group md">
                                        <button [ngClass]="{'active': isShareFilter}" (click)="toggleShareFilter()"><svg
                                                width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M14 14.252v2.09A6 6 0 0 0 6 22l-2-.001a8 8 0 0 1 10-7.748v.001zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm6.586 6-1.829-1.828 1.415-1.415L22.414 18l-4.242 4.243-1.415-1.415L18.586 19H15v-2h3.586z"
                                                    fill="#1C1A20" fill-opacity=".36" />
                                            </svg>
                                        </button>
                                        <button [ngClass]="{'active': isThumbsUpFilter}"
                                            (click)="toggleThumbsUpFilter()"><svg width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M14.6 8H21a2 2 0 0 1 2 2v2.104a2 2 0 0 1-.15.762l-3.095 7.515a1 1 0 0 1-.925.619H2a1 1 0 0 1-1-1V10a1 1 0 0 1 1-1h3.482a1 1 0 0 0 .817-.423L11.752.85a.5.5 0 0 1 .632-.159l1.814.907a2.5 2.5 0 0 1 1.305 2.853L14.6 8zM7 10.588V19h11.16L21 12.104V10h-6.4a2 2 0 0 1-1.938-2.493l.903-3.548a.5.5 0 0 0-.261-.571l-.661-.33-4.71 6.672c-.25.354-.57.644-.933.858zM5 11H3v8h2v-8z"
                                                    fill="#1C1A20" fill-opacity=".36" />
                                            </svg>
                                        </button>
                                        <button [ngClass]="{'active': isThumbsDownFilter}"
                                            (click)="toggleThumbsDownFilter()"><svg width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M9.4 16H3a2 2 0 0 1-2-2v-2.104a2 2 0 0 1 .15-.762L4.246 3.62A1 1 0 0 1 5.17 3H22a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1h-3.482a1 1 0 0 0-.817.423l-5.453 7.726a.5.5 0 0 1-.632.159L9.802 22.4a2.5 2.5 0 0 1-1.305-2.853L9.4 16zm7.6-2.588V5H5.84L3 11.896V14h6.4a2 2 0 0 1 1.938 2.493l-.903 3.548a.5.5 0 0 0 .261.571l.661.33 4.71-6.672c.25-.354.57-.644.933-.858zM19 13h2V5h-2v8z"
                                                    fill="#1C1A20" fill-opacity=".36" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                                <div nz-col>
                                    <div class="btn-group md">
                                        <button [ngClass]="{'active': isAdvancedFilter}"
                                            (click)="toggleAdvancedFilter()">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M5 16v6H3V3h9.382a1 1 0 0 1 .894.553L14 5h6a1 1 0 0 1 1 1v11a1 1 0 0 1-1 1h-6.382a1 1 0 0 1-.894-.553L12 16H5zM5 5v9h8.236l1 2H19V7h-6.236l-1-2H5z"
                                                    fill="#1C1A20" fill-opacity=".36" />
                                            </svg>
                                            Advanced

                                        </button>
                                        <button [ngClass]="{'active': isRejectFilter}"
                                            (click)="toggleRejectFilter()"><svg width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16.001A8 8 0 0 0 12 20zm0-9.414 2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
                                                    fill="#1C1A20" fill-opacity=".36" />
                                            </svg>
                                            Rejected
                                        </button>
                                    </div>
                                </div>

                                <div nz-col>
                                    <div class="filter-score">
                                        <nz-select nzSize="small" [nzSuffixIcon]="customSuffixIcon"
                                            nzPlaceHolder="From score" [(ngModel)]="applicationMgr.range[0]"
                                            (ngModelChange)="onChangeRange($event, 0)">
                                            <nz-option *ngFor="let score of scoreOptions" [nzValue]="score"
                                                [nzLabel]="score.toString()"></nz-option>
                                        </nz-select>
                                        <nz-select nzSize="small" [nzSuffixIcon]="customSuffixIcon"
                                            nzPlaceHolder="To score" [(ngModel)]="applicationMgr.range[1]"
                                            (ngModelChange)="onChangeRange($event, 1)">
                                            <nz-option *ngFor="let score of scoreOptions" [nzValue]="score"
                                                [nzLabel]="score.toString()"></nz-option>
                                        </nz-select>
                                        <ng-template #customSuffixIcon>
                                            <span nz-icon nzType="caret-down" nzTheme="outline"></span>
                                        </ng-template>
                                    </div>
                                </div>
                                <div nz-col *ngIf="user.role !== 'Hiring Manager'">
                                    <button class="btn-sm-white" nzTooltipTitle="Sorting Range"
                                        [nzTooltipPlacement]="['topLeft', 'leftTop']" nz-tooltip
                                        (click)="jobSortingCriteriaModal()" [disabled]="applicationMgr.not_qualified.length === 0 && 
                         applicationMgr.pre_qualified.length === 0 && 
                         applicationMgr.qualified.length === 0">
                                        <span nz-icon nzType="vertical-align-bottom" nzTheme="outline"></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div nz-col>
                            <form nz-form [formGroup]="searchForm" (ngSubmit)="submitSearch()">
                                <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
                                    <input type="text" nz-input placeholder="Search" class="width-250"
                                        formControlName="search" (input)="onSearchIntials()" />
                                </nz-input-group>
                                <ng-template #suffixIconButton>
                                    <button nz-button nzType="default" class="bg-grey" nzSearch>
                                        <span *ngIf="!applicationMgr.isSearching" nz-icon nzType="search"></span>
                                        <span *ngIf="applicationMgr.isSearching" nz-icon nzType="loading"
                                            nzTheme="outline"></span>
                                    </button>
                                </ng-template>
                            </form>
                        </div>

                    </div>
                    <div class="submited-filters">
                        <nz-tag nzMode="closeable" *ngIf="isAnsweredFollowupQuestions"
                            (nzOnClose)="onCloseFollowupQuestions()">Answered Follow up Questions</nz-tag>
                        <nz-tag nzMode="closeable" *ngIf="locationsFormated != ''"
                            (nzOnClose)="onCloseLocations()">Locations</nz-tag>
                        <nz-tag nzMode="closeable" *ngIf="selectedRequirments.length > 0"
                            (nzOnClose)="onCloseRequirements()">Requirements</nz-tag>
                    </div>
                </div>

                <div class="kanban">
                    <div class="board scrollable" cdkDropListGroup *ngIf="user.role !== 'Hiring Manager'">
                        <ng-container *ngIf="segmentList.length > 0">
                            <ng-container *ngFor="let status of segmentList; let index = index">
                                <div class="status-container"
                                    *appIsGranted="{ content_type: 'applicationsegment', desiredPermission: getStatusPermissions(status) }"
                                    [ngClass]="{ 'collapsed': isCollapsed[status],'active': isChecked[index],'is-profile-active':candidateMgr.applicationProfile && candidateMgr.applicationProfile.screening_segment ===  status}">
                                    <div class="status-title">
                                        <div class="d-flex align-items-center">
                                            <h4>

                                                <label nz-checkbox [nzChecked]="isChecked[index]"
                                                    [nzIndeterminate]="isIndeterminate[index]"
                                                    (nzCheckedChange)="onAllChecked(status, $event)">
                                                    {{ getStatusTitle(status) }}
                                                </label>
                                                <span class="sort-icon" (click)="sortSegment(status)">
                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M13.333 2.667v8h2L12.667 14 10 10.667h2v-8h1.333zM8 12v1.333H2V12h6zm1.333-4.667v1.334H2V7.333h7.333zm0-4.666V4H2V2.667h7.333z"
                                                            fill="#1C1A20" fill-opacity=".7" />
                                                    </svg>

                                                </span>
                                            </h4>
                                            <div class="status-count">
                                                {{getSegmentLength(applicationMgr.getCandidateBytype(status))}}
                                            </div>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <nz-button-group
                                                *ngIf="getSegmentLength(applicationMgr.getCandidateBytype(status)) !=0">
                                                <button nz-button class="bg-white" nz-dropdown nzTrigger="click"
                                                    [nzDropdownMenu]="menuAction"
                                                    nzPlacement="bottomRight">Actions</button>
                                                <button nz-button class="bg-white" nz-dropdown nzTrigger="click"
                                                    [nzDropdownMenu]="menuAction" nzPlacement="bottomRight"><span
                                                        nz-icon nzType="more" nzTheme="outline"></span></button>
                                            </nz-button-group>
                                            <button class="btn-icon ml-8" (click)="toggleCollapse(status)">
                                                <span *ngIf="!isCollapsed[status]" nz-icon nzType="caret-down"
                                                    nzTheme="outline"></span>
                                                <span *ngIf="isCollapsed[status]" nz-icon nzType="caret-up"
                                                    nzTheme="outline"></span>
                                            </button>
                                            <nz-dropdown-menu #menuAction="nzDropdownMenu">
                                                <ul nz-menu>
                                                    <li nz-menu-item (click)="submitShareBulkApplications()">Share with
                                                        Hiring Manager</li>
                                                    <li nz-menu-item (click)="submitRejectBulkApplicationsModal()">
                                                        Reject
                                                        Candidates</li>
                                                    <li nz-menu-item (click)="submitAdvancedBulkApplicationsModal()">
                                                        Advance
                                                        Candidates</li>
                                                </ul>
                                            </nz-dropdown-menu>
                                        </div>
                                    </div>

                                    <div *ngIf="!isCollapsed[status]" class="status-list" cdkDropList
                                        [cdkDropListData]="applicationMgr.getCandidateBytype(status)"
                                        (cdkDropListDropped)="drop($event,status)">
                                        <div class="status-box"
                                            *ngFor="let item of applicationMgr.getCandidateBytype(status)" cdkDrag
                                            (cdkDragEntered)="onDragEntered($event,status)"
                                            (click)="showProfileModal(item._id)"
                                            [ngClass]="{'active': setOfCheckedId.has(item._id),'is-application-active':candidateMgr.applicationProfile && candidateMgr.applicationProfile._id ===  item._id }">
                                            <div class="status-custom-placeholder" *cdkDragPlaceholder></div>
                                            <div class="status-text">
                                                <div class="intial">

                                                    <label nz-checkbox [nzChecked]="setOfCheckedId.has(item._id)"
                                                        (nzCheckedChange)="onItemChecked(status, item._id, $event)">
                                                        <span [nzTooltipTitle]="'ID ' + item._id"
                                                            [nzTooltipPlacement]="['topLeft', 'leftTop']" nz-tooltip> {{
                                                            item.candidate_initials }}</span>
                                                    </label>

                                                </div>
                                                <div class="progress" [ngClass]="getStatusColor(status)">
                                                    <div class="progress-value" [ngClass]="getStatusColor(status)"
                                                        [ngStyle]="{'width': item.score + '%'}"></div>
                                                    <div class="progress-label"><span
                                                            class="font-lg">{{item.score}}</span><br><span
                                                            class="font-score">Clara
                                                            Score</span></div>
                                                </div>
                                                <div class="card-action-icons">
                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg" *ngIf="item.is_thumbs_up">
                                                        <path
                                                            d="M9.734 5.333H14a1.333 1.333 0 0 1 1.334 1.334v1.402c0 .175-.034.347-.1.508l-2.064 5.01a.667.667 0 0 1-.616.413H1.334a.667.667 0 0 1-.667-.667V6.667A.667.667 0 0 1 1.334 6h2.321a.667.667 0 0 0 .545-.282L7.835.567A.333.333 0 0 1 8.256.46l1.21.604a1.666 1.666 0 0 1 .87 1.902l-.602 2.366zM4.667 7.06v5.608h7.44L14 8.069V6.667H9.734a1.333 1.333 0 0 1-1.292-1.662l.602-2.366a.333.333 0 0 0-.174-.38l-.441-.22-3.14 4.448a2.01 2.01 0 0 1-.622.572zm-1.333.274H2v5.334h1.334V7.333z"
                                                            fill="#AF99ED" />
                                                    </svg>
                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg" *ngIf="item.is_thumbs_down">
                                                        <path
                                                            d="M6.267 10.667H2A1.333 1.333 0 0 1 .667 9.333V7.931c0-.175.034-.347.1-.508l2.064-5.01A.667.667 0 0 1 3.447 2h11.22a.667.667 0 0 1 .667.667v6.666a.667.667 0 0 1-.667.667h-2.321a.667.667 0 0 0-.545.282l-3.635 5.15a.333.333 0 0 1-.422.107l-1.209-.606a1.667 1.667 0 0 1-.87-1.902l.602-2.364zm5.067-1.726V3.333h-7.44L2 7.931v1.402h4.267a1.333 1.333 0 0 1 1.292 1.662l-.602 2.366a.333.333 0 0 0 .174.38l.44.22 3.14-4.448c.167-.236.38-.429.623-.572zm1.333-.274H14V3.333h-1.333v5.334z"
                                                            fill="#D83749" />
                                                    </svg>
                                                </div>
                                            </div>
                                            <div class="status-box-footer">
                                                <div class="btn-group sm" (click)="$event.stopPropagation()">
                                                    <button (click)="shareModal(item._id)"
                                                        [class.active]="item.is_share">
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M14 14.252v2.09A6 6 0 0 0 6 22l-2-.001a8 8 0 0 1 10-7.748v.001zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm6.586 6-1.829-1.828 1.415-1.415L22.414 18l-4.242 4.243-1.415-1.415L18.586 19H15v-2h3.586z"
                                                                fill="#1C1A20" fill-opacity=".36" />
                                                        </svg>
                                                        Share
                                                    </button>

                                                    <button (click)="rejectModal(item._id)"
                                                        [class.active]="item.is_reject">
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16.001A8 8 0 0 0 12 20zm0-9.414 2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
                                                                fill="#1C1A20" fill-opacity=".36" />
                                                        </svg>
                                                        Reject
                                                    </button>
                                                    <button nz-dropdown nzTrigger="click"
                                                        [nzDropdownMenu]="advanceOptions"
                                                        [class.active]="item.is_advance" class="btn-long"
                                                        [nzTooltipTitle]="item.current_stage ? '' : 'Please assign the application with an initial job stage within the ATS.'"
                                                        [nzTooltipPlacement]="['topLeft', 'leftTop']" nz-tooltip
                                                        [disabled]="!item.current_stage">

                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M5 16v6H3V3h9.382a1 1 0 0 1 .894.553L14 5h6a1 1 0 0 1 1 1v11a1 1 0 0 1-1 1h-6.382a1 1 0 0 1-.894-.553L12 16H5zM5 5v9h8.236l1 2H19V7h-6.236l-1-2H5z"
                                                                fill="#1C1A20" fill-opacity=".36" />
                                                        </svg>
                                                        <span class="truncate">{{ item.current_stage?.job_stage_name ||
                                                            'Advance' }}</span>
                                                        <span nz-icon nzType="caret-down" nzTheme="outline"></span>
                                                    </button>

                                                    <nz-dropdown-menu #advanceOptions="nzDropdownMenu">
                                                        <ul nz-menu class="advanced-dropdown"
                                                            *ngIf="applicationMgr.screeningStepsOptions.length > 0">
                                                            <li nz-menu-item nzDisabled>Screening Steps / Stages</li>
                                                            <li *ngFor="let step of applicationMgr.screeningStepsOptions"
                                                                nz-menu-item
                                                                (click)="advancedModal(item._id, step.job_stage_id)"
                                                                [ngClass]="{
                                                          'disabled-click': isStageDisabled(step.job_stage_name, item.current_stage?.job_stage_name),
                                                          'selected-screening-step': step.job_stage_name === item.current_stage?.job_stage_name
                                                        }">
                                                                {{step.job_stage_name}}
                                                            </li>
                                                        </ul>
                                                    </nz-dropdown-menu>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>

                        </ng-container>
                    </div>
                    <div class="scrollable hiring-panel" *ngIf="user.role === 'Hiring Manager'">
                        <div class="status-container">
                            <div class="status-title">
                                <div class="d-flex align-items-center">
                                    <h4>
                                        Shared by Recruiters
                                    </h4>
                                    <div class="status-count">
                                        {{applicationMgr.hiringMangerSelections.length}}
                                    </div>
                                </div>
                            </div>
                            <div class="status-list">
                                <div class="status-box" *ngFor="let item of applicationMgr.hiringMangerSelections"
                                    (click)="showProfileModal(item._id)">
                                    <div class="status-custom-placeholder" *cdkDragPlaceholder></div>
                                    <div class="status-text">
                                        <div class="intial" [nzTooltipTitle]="'ID ' + item._id"
                                            [nzTooltipPlacement]="['topLeft', 'leftTop']" nz-tooltip>
                                            {{ item.candidate_initials }}
                                        </div>
                                        <div class="progress theme">
                                            <div class="progress-value theme" [ngStyle]="{'width': item.score + '%'}">
                                            </div>
                                            <div class="progress-label"><span
                                                    class="font-lg">{{item.score}}</span><br><span
                                                    class="font-score">Clara
                                                    Score</span></div>
                                        </div>
                                    </div>
                                    <div class="status-box-footer">
                                        <div class="btn-group sm d-block" (click)="$event.stopPropagation()">
                                            <button (click)="thumbsModal(item._id,'thumbs up',true)"
                                                [class.active]="item.is_thumbs_up">
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M12.167 6.667H17.5a1.667 1.667 0 0 1 1.667 1.666v1.753c0 .218-.043.434-.125.636l-2.58 6.262a.834.834 0 0 1-.77.516H1.667a.834.834 0 0 1-.834-.834V8.333a.833.833 0 0 1 .834-.833h2.901a.833.833 0 0 0 .681-.353L9.793.708a.417.417 0 0 1 .527-.132l1.512.756a2.083 2.083 0 0 1 1.087 2.377l-.752 2.958zM5.833 8.822v7.01h9.3l2.367-5.747V8.333h-5.333a1.667 1.667 0 0 1-1.615-2.077l.752-2.957a.417.417 0 0 0-.217-.476l-.551-.275-3.925 5.56a2.514 2.514 0 0 1-.778.715zm-1.666.343H2.5v6.667h1.667V9.166z"
                                                        fill="#1C1A20" fill-opacity=".36" />
                                                </svg>

                                                Thumbs Up
                                            </button>
                                            <button (click)="thumbsModal(item._id,'thumbs down',false)"
                                                [class.active]="item.is_thumbs_down">
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M7.833 13.333H2.5a1.667 1.667 0 0 1-1.667-1.666V9.913c0-.217.042-.433.125-.635l2.58-6.261a.833.833 0 0 1 .77-.517h14.025a.833.833 0 0 1 .834.833v8.334a.833.833 0 0 1-.834.833h-2.901a.835.835 0 0 0-.681.352l-4.544 6.439a.417.417 0 0 1-.527.132l-1.512-.756a2.084 2.084 0 0 1-1.087-2.378l.752-2.956zm6.334-2.156v-7.01h-9.3L2.5 9.913v1.754h5.333a1.666 1.666 0 0 1 1.615 2.077l-.752 2.957a.417.417 0 0 0 .217.476l.551.275 3.925-5.56c.208-.295.475-.537.778-.715zm1.666-.344H17.5V4.167h-1.667v6.666z"
                                                        fill="#1C1A20" fill-opacity=".36" />
                                                </svg>

                                                Thumbs Down
                                            </button>

                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="total-bar">
                                <h4>Total</h4>
                                <div class="status-count">
                                    {{applicationMgr.hiringMangerSelections.length}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="scrollable w-full d-flex" #scrollTarget>

                        <app-candidate-profile *ngIf="isProfile" [atsName]="this.ats" class="d-block"
                            [candidateId]="jobObjectId"></app-candidate-profile>
                        <ng-container *appIsGranted="{ content_type: 'partnersettings', desiredPermission: 'view' }">
                            <div class="no-applications"
                                *ngIf="(showUpload && processStateDetail !== 'EXTRACTION_COMPLETED' && processStateDetail !== 'ats_updating' && processStateDetail !== 'done') || 
                                        ((applicationMgr.not_qualified.length === 0 && applicationMgr.pre_qualified.length === 0 && applicationMgr.qualified.length === 0) && showUpload && ats === 'clara')">

                                <div>
                                    <div class="button-icon" *ngIf="processStateDetail === 'READY_TO_EXTRACTION'">
                                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M14 25.667C7.556 25.667 2.333 20.444 2.333 14 2.333 7.557 7.556 2.334 14 2.334c6.443 0 11.666 5.223 11.666 11.666 0 6.444-5.223 11.667-11.666 11.667zm0-2.334a9.333 9.333 0 1 0 0-18.665 9.333 9.333 0 0 0 0 18.666zM15.166 14h4.667v2.334h-7V8.167h2.333V14z"
                                                fill="#1C1A20" fill-opacity=".7" />
                                        </svg>
                                    </div>
                                    <div *ngIf="processStateDetail === 'READY_TO_EXTRACTION'">
                                        <div class="loading-text">
                                            <div class="warning-text">Job processing</div>
                                            <div><strong>This job is being uploaded and processed</strong></div>
                                            <p>In a few seconds you’ll be able to upload candidates.</p>
                                        </div>
                                        <div class="buttons">
                                            <a routerLink="/jobs"><button nz-button nzType="default" nzShape="round"
                                                    class="font-size-medium mr-8">Go back to jobs</button></a>
                                            <a routerLink="/jobs/upload-new-job"><button nz-button nzType="primary"
                                                    nzShape="round" class="font-size-medium">Upload another
                                                    job</button></a>
                                        </div>
                                    </div>
                                    <div class="link-button"
                                        *ngIf="(processStateDetail === 'EXTRACTION_COMPLETED') || (applicationMgr.currentCounts && applicationMgr.currentCounts.process_status === 'EXTRACTION_COMPLETED')">

                                        <div class="button-icon">
                                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M3.5 22.167h21V24.5h-21v-2.333zM15.167 6.799v13.034h-2.334V6.8L5.75 13.883l-1.65-1.65 9.9-9.9 9.9 9.9-1.65 1.65-7.083-7.081v-.003z"
                                                    fill="#1C1A20" fill-opacity=".7" />
                                            </svg>


                                        </div>
                                        <p class="color-theme font-weight-medium">Upload Candidates OR share Apply
                                            Link
                                        </p>
                                        <p>No candidates have been uploaded for this Manually Managed Job. <br>
                                            Upload candidates to screen then with the CLARA AI, or, share the Apply
                                            Link<br>
                                            where Candidates can submit their
                                            applications</p>
                                        <div class="buttons">
                                            <a [routerLink]="['/jobs/upload-candidates', jobObjectId]"><button nz-button
                                                    nzType="primary" nzShape="round"
                                                    class="font-size-medium mr-16">Upload Candidates</button></a>
                                            <div nz-typography nzCopyable [nzCopyText]="job_apply_link"
                                                [nzCopyTooltips]="['Copy Apply Link', 'Apply Link Copied']"
                                                [nzCopyIcons]="[copedIcon, copedIcon]"></div>
                                            <ng-template #copedIcon>
                                                <button nz-button nzType="primary" nzShape="round"
                                                    class="font-size-medium">Copy Apply
                                                    Link</button>
                                            </ng-template>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </nz-tab>
            <nz-tab nzTitle="Job Details">
                <div class="scrollable w-full">
                    <app-job-detail [jobDetailData]="applicationMgr.jobDetailData"></app-job-detail>
                </div>
            </nz-tab>
        </nz-tabset>
        <nz-modal [(nzVisible)]="applicationMgr.isVisibleShareModal" (nzOnCancel)="removeShareCancel()"
            [nzFooter]="null" nzWidth="572px">
            <ng-container *nzModalContent>
                <div class="center-form">
                    <div class="form-header">
                        <h2 class="font-heading">Share with the Hiring Manager</h2>
                        <div class="text-box">This action will share Candidate(s) with the Hiring Manager. Add a note to
                            provide additional
                            context (optional).</div>
                    </div>
                    <form nz-form [formGroup]="shareHiringForm" nzNoColon nzLayout="vertical">
                        <ng-container>
                            <nz-form-item>
                                <nz-form-control nzErrorTip="Please Add comment here">
                                    <textarea rows="4" formControlName="note" nz-input
                                        placeholder="Add comment here"></textarea>
                                </nz-form-control>
                            </nz-form-item>
                        </ng-container>

                        <div class="custom-footer">
                            <button (click)="submitShareApplications()" class="btn-theme-clara d-block mb-10"><span
                                    *ngIf="applicationMgr.isSharing" nz-icon nzType="loading" nzTheme="outline"></span>
                                Share with Hiring Manager</button>
                            <button nz-button nzType="text" nzSize="large" nzBlock (click)="removeShareCancel()"
                                nzShape="round">Cancel</button>
                        </div>
                    </form>
                </div>
            </ng-container>
        </nz-modal>
        <nz-modal [(nzVisible)]="applicationMgr.isVisibleThumbsModal" (nzOnCancel)="removeThumbsCancel()"
            [nzFooter]="null" nzWidth="572px">
            <ng-container *nzModalContent>
                <div class="center-form">
                    <div class="form-header">
                        <h2 class="font-heading">{{thumbTitle | titlecase}}</h2>
                        <div class="text-box">Great! You’ve chosen 1 candidate to {{thumbTitle}}. You can provide
                            comments to your recruiter below (optional).</div>
                    </div>
                    <form nz-form [formGroup]="shareHiringForm" nzNoColon nzLayout="vertical">
                        <ng-container>
                            <nz-form-item>
                                <nz-form-control nzErrorTip="Please Add comment here">
                                    <textarea rows="4" formControlName="note" nz-input
                                        placeholder="Additional Comments"></textarea>
                                </nz-form-control>
                            </nz-form-item>
                        </ng-container>

                        <div class="custom-footer">
                            <button (click)="submitThumbAction()" class="btn-theme-clara d-block mb-10"><span
                                    *ngIf="applicationMgr.isSharing" nz-icon nzType="loading" nzTheme="outline"></span>
                                Submit</button>
                            <button nz-button nzType="text" nzSize="large" nzBlock (click)="removeThumbsCancel()"
                                nzShape="round">Cancel</button>
                        </div>
                    </form>
                </div>
            </ng-container>
        </nz-modal>
        <nz-modal [(nzVisible)]="applicationMgr.isVisibleAdvancedModal" (nzOnCancel)="removeAdvancedCancel()"
            [nzFooter]="null" nzWidth="572px">
            <ng-container *nzModalContent>
                <div class="center-form">
                    <div class="form-header">
                        <h2 class="font-heading">Advance Candidate(s)</h2>
                        <div class="text-box"><b>Great! You’ve chosen {{applicationIds.length}} candidate(s) to advance.
                                <span *ngIf="ats != 'clara'"> This status will be
                                    reflected in your ATS</span>.</b> <br>
                            To change your setting go to Settings / Screening Actions</div>
                    </div>
                    <div class="custom-footer">
                        <button (click)="submitAdvancedApplications()" class="btn-theme-clara d-block mb-10"><span
                                *ngIf="applicationMgr.isSharing" nz-icon nzType="loading" nzTheme="outline"></span>
                            Submit</button>
                        <button nz-button nzType="text" nzSize="large" nzBlock (click)="removeAdvancedCancel()"
                            nzShape="round">Cancel</button>
                    </div>
                </div>
            </ng-container>
        </nz-modal>
        <nz-modal [(nzVisible)]="applicationMgr.isVisibleAdvancedBulkModal" (nzOnCancel)="removeAdvancedBulkCancel()"
            [nzFooter]="null" nzWidth="572px">
            <ng-container *nzModalContent>
                <div class="center-form">
                    <div class="form-header">
                        <h2 class="font-heading">Advance Candidate(s)</h2>
                        <div class="text-box"><b>Great! You’ve chosen {{applicationIds.length}} candidate(s) to advance.
                                <span *ngIf="ats != 'clara'">This status will be
                                    reflected in your ATS</span>.</b> <br>
                            To change your setting go to Settings / Screening Actions</div>
                        <div class="form-item-mapping">
                            <nz-select class="width-full black-select-placeholder" [nzSuffixIcon]="customSuffixIcon"
                                nzAllowClear nzPlaceHolder="Select Mapping" nzSize="large" [(ngModel)]="advancedStepId"
                                (ngModelChange)="onStepChange($event)">
                                <nz-option nzPlaceHolder="Select Mapping"
                                    *ngFor="let step of applicationMgr.screeningStepsOptions"
                                    [nzLabel]="step.job_stage_name" [nzValue]="step.job_stage_id"></nz-option>
                            </nz-select>
                        </div>
                        <ng-template #customSuffixIcon>
                            <span nz-icon nzType="caret-down" nzTheme="outline"></span>
                        </ng-template>
                        <div class="form-item-mapping" *ngIf="applicationMgr.screeningStepsOptions.length === 0">
                            <nz-alert nzType="info"
                                nzMessage="Please assign the application with an initial job stage within the ATS."
                                nzShowIcon></nz-alert>
                        </div>
                    </div>
                    <div class="custom-footer">
                        <button (click)="submitAdvancedBulkApplications()" class="btn-theme-clara d-block mb-10"
                            [disabled]="applicationMgr.screeningStepsOptions.length === 0"><span
                                *ngIf="applicationMgr.isSharing" nz-icon nzType="loading" nzTheme="outline"></span>
                            Submit</button>
                        <button nz-button nzType="text" nzSize="large" nzBlock (click)="removeAdvancedBulkCancel()"
                            nzShape="round">Cancel</button>
                    </div>
                </div>
            </ng-container>
        </nz-modal>
        <nz-modal [(nzVisible)]="applicationMgr.isVisibleRejectBulkModal" (nzOnCancel)="removeRejectBulkCancel()"
            [nzFooter]="null" nzWidth="572px">
            <ng-container *nzModalContent>
                <div class="center-form">
                    <div class="form-header">
                        <h2 class="font-heading">Reject Candidate(s)</h2>
                        <div class="text-box"><b>Great! You’ve chosen {{applicationIds.length}} candidate(s) to reject.
                                <span *ngIf="ats != 'clara'"> This status will be
                                    reflected in your ATS</span>.</b> <br>
                            To change your setting go to Settings / Screening Actions</div>

                        <ng-template #customSuffixIcon>
                            <span nz-icon nzType="caret-down" nzTheme="outline"></span>
                        </ng-template>
                        <div class="form-item-mapping" *ngIf="applicationMgr.screeningStepsOptions.length === 0">
                            <nz-alert nzType="info"
                                nzMessage="Please assign the application with an initial job stage within the ATS."
                                nzShowIcon></nz-alert>
                        </div>
                    </div>
                    <div class="custom-footer">
                        <button (click)="submitRejectBulkApplications()" class="mb-10" nz-button nzType="primary"
                            nzDanger nzShape="round" nzBlock nzSize="large"
                            [disabled]="applicationMgr.screeningStepsOptions.length === 0"><span
                                *ngIf="applicationMgr.isSharing" nz-icon nzType="loading" nzTheme="outline"></span>
                            Submit</button>
                        <button nz-button nzType="text" nzSize="large" nzBlock (click)="removeRejectBulkCancel()"
                            nzShape="round">Cancel</button>
                    </div>
                </div>
            </ng-container>
        </nz-modal>
        <nz-modal [(nzVisible)]="applicationMgr.isVisibleRejectModal" (nzOnCancel)="removeRejectCancel()"
            [nzFooter]="null" nzWidth="572px">
            <ng-container *nzModalContent>
                <div class="center-form">
                    <div class="form-header">
                        <h2 class="font-heading">Reject Candidate(s)</h2>
                        <div class="text-box"><b>You’ve chosen {{applicationIds.length}} candidate(s) to reject.
                                <span *ngIf="ats != 'clara'"> This status will be
                                    reflected in your ATS</span>.</b> <br>
                            To change your setting go to Settings / Screening Actions</div>
                    </div>
                    <div class="custom-footer">
                        <button (click)="submitRejectApplications()" class="mb-10" nz-button nzType="primary" nzDanger
                            nzShape="round" nzBlock nzSize="large"><span *ngIf="applicationMgr.isSharing" nz-icon
                                nzType="loading" nzTheme="outline"></span>
                            Submit</button>
                        <button nz-button nzType="text" nzSize="large" nzBlock (click)="removeRejectCancel()"
                            nzShape="round">Cancel</button>
                    </div>
                </div>
            </ng-container>
        </nz-modal>


        <app-job-aisetting [jobObjectId]="jobObjectId" [jobName]="applicationMgr.jobDetailData.title"
            (submitAIEvent)="onChildSubmitAiSettings()"></app-job-aisetting>
        <app-assign-job [jobObjectId]="jobObjectId" (submitAssignJobEvent)="onChildSubmitAssignJob()"></app-assign-job>
        <app-sorting-criteria [jobObjectId]="jobObjectId" [jobName]="applicationMgr.jobDetailData.title"
            (submitSortingEvent)="onChildSubmitSorting()"></app-sorting-criteria>
    </main>
</div>