import { Component } from '@angular/core';
import { ScreeningSettingsManagerService } from 'src/app/Managers/screening-settings-manager.service';
import { AuthenticationService } from 'src/app/Services/authentication.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
@Component({
  selector: 'app-screening-actions',
  templateUrl: './screening-actions.component.html',
  styleUrl: './screening-actions.component.scss',
})
export class ScreeningActionsComponent {
  atsName: string = '';
  actionType: boolean = false;

  constructor(
    public screeningMgr: ScreeningSettingsManagerService,
    public auth: AuthenticationService,
    private notification: NzNotificationService
  ) {}
  toggleSubmenu() {
    const bodyTag = document.body;
    bodyTag.classList.toggle('is-submenu');
    this.auth.isSubmenu = !this.auth.isSubmenu;
  }
  removeScreeningModal(ats: string): void {
    this.atsName = ats;
    this.screeningMgr.isVisiableScreeningAction = true;
  }
  removeScreeningCancel(): void {
    this.screeningMgr.isVisiableScreeningAction = false;
  }
  changeScreeningAction(value: boolean, key: string): void {
    this.atsName = key;
    this.actionType = value;
    this.screeningMgr.isVisiableScreeningAction = true;
  }

  handleScreeningModalCancel(): void {
    this.screeningMgr.isVisibleScreeningModal = false;
  }
  objectKeys(obj: any): string[] {
    return Object.keys(obj);
  }

  submitScreeningAction(actionType: boolean): void {
    let formData = {
      map_actions_ats_to_clara: actionType,
    };
    this.screeningMgr.submitScreeningAction(this.atsName, formData);
  }

  ngOnInit(): void {
    this.screeningMgr.getScreeningActions();
  }
}
