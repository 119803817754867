import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private http: HttpClient) {}

  getPartners() {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.get(
      environment.apiURL + `account/partners/?page_size=10000`,
      {
        headers: header,
      }
    );
  }

  getAnalytics(timeframe: string, id: number) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.get(
      environment.apiURL +
        `deincore/dashboard/analytics?partner_id=${id}&timeframe=${timeframe}`,
      {
        headers: header,
      }
    );
  }
  getAnalyticsPartner(timeframe: string) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.get(
      environment.apiURL +
        `deincore/dashboard/analytics?timeframe=${timeframe}`,
      {
        headers: header,
      }
    );
  }
  getAnalyticsHR(timeframe: string) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.get(
      environment.apiURL +
        `deincore/dashboard/analytics?timeframe=${timeframe}`,
      {
        headers: header,
      }
    );
  }
  getCandidateDashboard() {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.get(environment.apiURL + `deincore/dashboard/candidate/`, {
      headers: header,
    });
  }
  getNotifications() {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.get(environment.apiURL + `account/notifications/`, {
      headers: header,
    });
  }
  readNotification(id: number) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    const data = { status: 'read' };
    return this.http.put(
      environment.apiURL + `/account/notifications/${id}/status/`,
      data,
      {
        headers: header,
      }
    );
  }
}
