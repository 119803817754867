import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';

import { AuthenticationService } from 'src/app/Services/authentication.service';

export const loginStatusGuard = (route: ActivatedRouteSnapshot) => {
  const authService = inject(AuthenticationService);
  const router = inject(Router);

  if (authService.isLoggedIn()) {
    return router.parseUrl('/') as UrlTree; // Redirect to some other route if user is logged in
  }

  return true; // Allow access to the Login Route if user is not logged in
};
