<div class="fullscreen bg-gradient">
    <div class="shape1"></div>
    <div class="shape2"></div>
    <div class="login-wrap">
        <h1 class="font-heading">Choose your new password</h1>
        <div class="note-box">Enter your new password below, then proceed to log in!</div>
        <form [formGroup]="formResetPassword" nz-form nzNoColon nzLabelAlign="left" nzLayout="vertical"
            (ngSubmit)="resetPassword()">
            <label for="new_password">New Password</label>
            <nz-form-item>
                <nz-form-control nzErrorTip="Please input your New Password!">
                    <nz-input-group [nzSuffix]="suffixPassword" nzSize="large">
                        <input [type]="passwordVisible ? 'text' : 'password'" nzSize="large" nz-input id="new_password"
                            formControlName="new_password" />
                    </nz-input-group>
                    <ng-template #suffixPassword>
                        <span nz-icon [nzType]="passwordVisible ? 'eye' : 'eye-invisible'"
                            (click)="passwordVisible = !passwordVisible"></span>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>

            <div class="register-info">
                <div class="register-info-item">
                    <span nz-icon nzType="check" nzTheme="outline" class="pr-10"></span>
                    <span>Password must be at least 8 characters</span>
                </div>
                <div class="register-info-item">
                    <span nz-icon nzType="check" nzTheme="outline" class="pr-10"></span>
                    <span>Password must contain at least 1 special character</span>
                </div>
            </div>
            <button nz-button nzBlock nzSize="large" [nzType]="'primary'" nzShape="round"><span
                    *ngIf="this.usersServiceManger.isAddingUser" nz-icon nzType="loading"
                    nzTheme="outline"></span>Login</button>
            <div class="login-footer">
                <div><a routerLink="/login">Log In</a></div>
            </div>
        </form>
    </div>
</div>