import { Component } from '@angular/core';
import { NotificationsSettingsManagerService } from 'src/app/Managers/notifications-settings-manager.service';
import { AuthenticationService } from 'src/app/Services/authentication.service';

@Component({
  selector: 'app-notifications-settings',
  templateUrl: './notifications-settings.component.html',
  styleUrl: './notifications-settings.component.scss',
})
export class NotificationsSettingsComponent {
  partnerId: string = '';
  constructor(
    public notificatinMgr: NotificationsSettingsManagerService,
    public auth: AuthenticationService
  ) {}

  changeNotificationsSettings(item: any): void {
    const formData: any = {};
    this.notificatinMgr.notificationsSettingData.forEach((setting) => {
      formData[setting.key] =
        setting.key === item.key ? item.value : setting.value;
    });
    this.notificatinMgr.updatedNotificationsSettings(formData);
  }
  toggleSubmenu() {
    const bodyTag = document.body;
    bodyTag.classList.toggle('is-submenu');
    this.auth.isSubmenu = !this.auth.isSubmenu;
  }
  ngOnInit(): void {
    this.notificatinMgr.getNotificationsSettings();
  }
}
