import { Component } from '@angular/core';
import { AuthenticationService } from 'src/app/Services/authentication.service';
import { ImpersonationManagerService } from 'src/app/Managers/impersonation-manager.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  user: any;
  selectedPartner: string = '';
  selectedUser: string = '';
  constructor(
    public auth: AuthenticationService,
    public imprMgr: ImpersonationManagerService
  ) {}
  logout(): void {
    this.auth.logout();
  }
  expandSideNav(): void {}
  exitImpersonation(): void {
    this.imprMgr.exitImpersonation();
  }
  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('user') || '{}');
    const userRole = this.user.role?.trim();
    this.selectedPartner = this.user.partner.partner_name;
    this.selectedUser = this.user.email;
    if (
      userRole === 'Admin' ||
      userRole === 'Partner Admin' ||
      userRole === 'Account Owner'
    ) {
      this.imprMgr.currentImpersonation();
    }
  }
}
