<div class="page">
    <!-- Side menu -->
    <app-side-menu></app-side-menu>
    <!-- main -->
    <main>
        <nz-spin [nzSpinning]="imprMgr.isLoadingPage">
            <div class="page-header-bar">

                <form [formGroup]="form" nz-form [nzLayout]="'inline'" (ngSubmit)="submitForm()">
                    <nz-form-item>
                        <nz-form-control>
                            <nz-select formControlName="partner_name" class="width-300 black-select-placeholder"
                                nzPlaceHolder="Select a Partner Account" nzSize="large"
                                [nzSuffixIcon]="customSuffixIcon" (nzScrollToBottom)="loadMorePartners()"
                                [nzDropdownRender]="renderTemplate" (ngModelChange)="partnerChange($event)">
                                <nz-option *ngFor="let p of imprMgr.partnersData" [nzValue]="p.id"
                                    [nzLabel]="p.partner_name"></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item *ngIf="imprMgr.partnersUsers">
                        <nz-form-control>
                            <nz-select [nzDisabled]="isUsernameDisabled()" formControlName="username"
                                class="width-300 black-select-placeholder" nzPlaceHolder="UserName" nzSize="large"
                                [nzSuffixIcon]="customSuffixIcon">
                                <nz-option *ngFor="let user of imprMgr.partnersUsers" [nzValue]="user.id"
                                    [nzLabel]="user.email"></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-control>
                            <input class="width-500" nz-input placeholder="Reason" formControlName="reason"
                                nzSize="large" />
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-control>
                            <button nz-button nzType="primary" [disabled]="!form.valid" nzSize="large"
                                nzShape="round">Start Impersonation</button>
                        </nz-form-control>
                    </nz-form-item>
                </form>

            </div>
            <div class="info-box">
                <div class="btn-icon">
                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="m15.01 3.5 11.114 19.25a1.167 1.167 0 0 1-1.01 1.75H2.886a1.167 1.167 0 0 1-1.01-1.75L12.99 3.5a1.167 1.167 0 0 1 2.02 0zM4.907 22.167h18.186L14 6.417l-9.093 15.75zm7.926-3.5h2.334V21h-2.334v-2.333zm0-8.167h2.334v5.833h-2.334V10.5z"
                            fill="#1C1A20" fill-opacity=".7" />
                    </svg>

                </div>
                <h4>Impersonation Mode</h4>
                <p>Select a Partner Account and a Role to enter impersonation mode. As an Admin, all changes will be
                    logged and associated
                    with this account.</p>
            </div>
            <ng-template #customSuffixIcon>
                <span nz-icon nzType="caret-down" nzTheme="outline"></span>
            </ng-template>
            <ng-template #renderTemplate>
                <nz-spin *ngIf="imprMgr.isLoadingMorePartner"></nz-spin>
            </ng-template>
        </nz-spin>
    </main>
</div>