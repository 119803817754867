import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SjmManagerService } from 'src/app/Managers/sjm-manager.service';

@Component({
  selector: 'app-application-submitted',
  templateUrl: './application-submitted.component.html',
  styleUrl: './application-submitted.component.scss',
})
export class ApplicationSubmittedComponent {
  jobObjectId: string = '';
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public sjmMgr: SjmManagerService
  ) {}

  async ngOnInit() {
    this.route.params.subscribe(async (params) => {
      this.jobObjectId = params['jobObjectId'];
      await this.sjmMgr.getJobInformation(this.jobObjectId);
    });
  }
}
